import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getShortcut: builder.query({ query: () => `Shortcut`, keepUnusedDataFor: 0 }),
        createShortcut: builder.mutation({ query: (body) => ({ url: `Shortcut`, method: 'POST', body }) }),
        updateShortcut: builder.mutation({ query: ({ id, body }) => ({ url: `Shortcut?id=${id}`, method: 'PATCH', body }) }),
        deleteShortcut: builder.mutation({ query: (id) => ({ url: `Shortcut?id=${id}`, method: 'DELETE' }) }),
    })
})

export const {
    useGetShortcutQuery,
    useCreateShortcutMutation,
    useUpdateShortcutMutation,
    useDeleteShortcutMutation
} = extendedApiSlice
