import React, { useState } from 'react'
import Api from '../../constants/Api';
import ImageInput from '../form/ImageInput'
import ImageInputCropper from '../form/ImageInputCropper'
import { useCreateSliderMutation, useDeleteSliderMutation, useGetSliderQuery, useUpdateSliderMutation } from '../../services/sliderSlice'
import Loading from '../ui/Loading'
import ApiErrorModal from '../modals/ApiErrorModal'
import Button from '../form/Button'
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { FaTrash } from "react-icons/fa6";

const SliderEditModal = ({ type, position }) => {

    const modalId = 'SliderEditModal'
    const imgClass = 'w-100 border rounded-4 shadow'
    const [file, setFile] = useState(null)
    const sliderRes = useGetSliderQuery()
    const [createSlider, createSliderRes] = useCreateSliderMutation()
    const [deleteSlider, deleteSliderRes] = useDeleteSliderMutation()
    const [updateSlider, updateSliderRes] = useUpdateSliderMutation()

    const addSlide = () => {
        if (file) {
            let body = new FormData();
            body.append("image", file);
            body.append("type", type);

            createSlider(body)
                .then((res) => {
                    if (!res.error) {
                        sliderRes.refetch()
                        setFile(null)
                    }
                })
        }
    }

    const deleteSlide = (id) => {
        deleteSlider(id).then((res) => {
            if (!res.error) {
                sliderRes.refetch()
            }
        })
    }

    const updateOrder = (slide1, slide2) => {
        updateSlider({ id: slide1.id, body: { order: slide2.order } })
            .then((res1) => {
                if (!res1.error) {
                    updateSlider({ id: slide2.id, body: { order: slide1.order } })
                        .then((res2) => {
                            if (!res1.error) sliderRes.refetch()
                        })
                }
            })
    }

    if (sliderRes.isLoading) return <Loading />
    if (sliderRes.isError) return <ApiErrorModal response={sliderRes} />

    const images = sliderRes.data.filter(i => i.type === type)
    const src = (img) => Api.SLIDER_URL + img.id + img.ext

    return (
        <>

            {
                position ?
                <div className="btn btn-danger btn-sm mt-3 me-5 px-3 rounded-pill" data-bs-toggle="modal" data-bs-target={"#" + modalId}>Edit Slides</div>
                    :
                    <div className='position-absolute z-3 end-0'>
                        <div className="btn btn-danger btn-sm mt-3 me-5 px-3 rounded-pill" data-bs-toggle="modal" data-bs-target={"#" + modalId}>Edit Slides</div>
                    </div>
            }



            <div className="modal fade" id={modalId} tabindex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            {/* <div></div> */}
                            <div className=" bg-white text-white  px-3 rounded-pill" style={{ width: 85, height: 10 }}></div>
                            <div className="modal-title px-4 py-2 bg-primary text-white rounded-bottom-4 text-center" id={modalId + "Label"} style={{ marginTop: -37 }}>Edit Slides</div>
                            <div className="btn btn-danger px-3 rounded-pill" data-bs-dismiss="modal">Close</div>
                            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" >Close</button> */}
                        </div>
                        <div className="modal-body d-flex">
                            <div className="w-50 border-end">
                                <div className="container">
                                    <div className="row row-cols-1 g-2">
                                        {
                                            images.map((img, index) =>
                                                <div key={img.id} className="col">
                                                    <div className="alert d-flex align-items-center justify-content-between alert-primary p-2">
                                                        <img className='rounded' src={src(img)} style={{ width: 200 }} alt="" />
                                                        <button type='button' className='btn btn-sm rounded-circle btn-danger ms-3' onClick={() => deleteSlide(img.id)}><FaTrash /></button>
                                                        <div className='d-flex flex-column align-items-center'>
                                                            {index !== 0 &&
                                                                <Button
                                                                    className='btn rounded-circle mb-2  btn-sm btn-primary ms-3'
                                                                    res={updateSliderRes}
                                                                    onClick={() => updateOrder(images[index], images[index - 1])}
                                                                >
                                                                    {/* <IoMdArrowDropupCircle /> */}
                                                                    <TiArrowSortedUp />
                                                                </Button>
                                                            }
                                                            {index < images.length - 1 &&
                                                                <Button
                                                                    className='btn rounded-circle btn-sm btn-primary ms-3'
                                                                    res={updateSliderRes}
                                                                    onClick={() => updateOrder(images[index], images[index + 1])}
                                                                >
                                                                    {/* <IoMdArrowDropdownCircle /> */}

                                                                    <TiArrowSortedDown />
                                                                </Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='w-50 ps-3'>

                                <ImageInputCropper
                                    name={"image"}
                                    labelName={'Browse file to upload'}
                                    labelClass={'text-center py-5 rounded-3 text-primary'}
                                    labelStyle={{ borderStyle: 'dashed', borderColor: '#D0DBF8' }}
                                    imgClass={imgClass}
                                    aspectRatio={3 / 1}
                                    file={file}
                                    setFile={setFile}
                                />
                                {
                                    file &&
                                    <div className='text-end mt-3'>
                                        <Button
                                            className='btn btn-primary'
                                            res={createSliderRes}
                                            loadingLabel={"Adding"}
                                            onClick={addSlide}
                                        >
                                            Add Slide
                                        </Button>
                                    </div>
                                }
                            </div>
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SliderEditModal