import React from 'react'
import Input from '../../components/form/Input'
import { useCreateBookMutation } from '../../services/bookSlice';
import { useState } from 'react';
import SuccessModal from '../../components/modals/SuccessModal';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import Button from '../../components/form/Button';
import { useGetCategoriesQuery } from '../../services/categorySlice';
import Loading from '../../components/ui/Loading';
import Cropper from '../../components/Cropper';

const CreateBook = () => {

    // const [selectedTab, setSelectedTab] = useState("Chapter 1");
    // const activeTabClassName = "flex-grow-1 bg-light p-4 pe-5 text-center border-end border-5 border-danger fw-bold";
    // const inactiveTabClassName = "flex-grow-1 bg-primary p-4 pe-5 text-center text-white";
    const [image, setImage] = useState(null);

    const [createBook, createBookRes] = useCreateBookMutation();
    const categoryRes = useGetCategoriesQuery()

    const onSubmit = (event) => {
        event.preventDefault();
        const form = event.target

        let body = new FormData();
        body.append('name', form['name'].value)
        body.append('authorName', form['authorName'].value)
        body.append('language', form['language'].value)
        body.append('rating', 0)
        body.append('color', form['color'].value)
        body.append('categoryId', form['Category'].value)
        body.append('images', image)    

        if (form.checkValidity()) {

            createBook(body)
                .then((res) => {
                    if (!res.error) {
                        form.reset()
                        form.classList.remove('was-validated')
                    }
                })

        }
        else form.classList.add('was-validated')
    }

    // const handleEditorContentChange = (content) => {
    //     setChapterContent(content);
    //     console.log(chapterContent);
    // };
    // console.log(JSON.stringify(categoryRes.data));

    if (categoryRes.isLoading) return <Loading />
    if (categoryRes.isError) return <ApiErrorModal response={categoryRes} />

    return (
        <div className='p-5 py-3'>

            {createBookRes.isSuccess && <SuccessModal label={"Created!"} message={"Book has been added."} to={{ link: "/courseBooks", text: "Go to Course Books" }} closeTo={"/courseBooks"} />}
            {createBookRes.isError && <ApiErrorModal response={createBookRes} />}

            <h2 className='text-danger fw-bold'>Create Book</h2>
            <div className='p-4 bg-white rounded-5 shadow my-3'>

                <form className="" onSubmit={onSubmit} noValidate>
                    <div className='d-flex'>
                        <label htmlFor="color" className="form-label fw-bold">Background Colour</label>
                        <input type="color" className="form-control form-control-color mx-auto" name='color' id="color" title="Choose your color" />
                    </div>
                    <Input name={'name'} label={'Book Name'} containerClass={'my-5'} required />
                    <Input name={'authorName'} label={'Author Name'} containerClass={'my-5'} required />
                    <Input name={'language'} label={'Language'} containerClass={'my-5'} required />
                    <select name={'Category'} className="form-select" aria-label="Default select example">
                        {categoryRes.data.map(item =>
                            <option value={item.id}>{item.name}</option>
                        )}
                    </select>
                    <Cropper id={4} image={image} setImage={setImage} aspectRatio={2 / 3} label={'Add cover'} containerClass={'mt-5'} />
                    {/* <Input name={'rating'} label={'Rating'} containerClass={'my-5'} required /> */}

                    <Button
                        className='btn btn-primary text-white rounded-pill text-nowrap px-5 my-3'
                        res={createBookRes}
                        loadingLabel={"Creating"}
                    >
                        Create
                    </Button>
                </form>

            </div>

        </div>
    )
}

export default CreateBook