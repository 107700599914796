import React from 'react'
import bookImg from '../../assets/images/book_poster.png'

// import vidyaAdhyan from '../../assets/images/vidyaAdhyan.png'
// import vidyaArogya from '../../assets/images/vidyaArogya.png'
// import vidyaKaushal from '../../assets/images/vidyaKaushal.png'



import starImg from '../../assets/images/star.png'
import Sections from '../../constants/Sections'
import { Link } from 'react-router-dom'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import { useGetAboutQuery } from '../../services/aboutSlice'
import Slider from '../../components/ui/Slider'

const Vidhya = () => {

    const aboutRes = useGetAboutQuery()

    if (aboutRes.isLoading) return <Loading />
    if (aboutRes.isError) return <ApiErrorModal />

    const filteredData = aboutRes.data.filter(item => item.type === "Vidhya")

    return (
        <div className='py-5 d-flex h-100'>

            <div className='px-5 w-75'>

                <Slider type="ICGS-Vidhya" />
                {/* <h4 className='fw-bold text-danger mb-3'>Explore Books</h4> */}
                <h2 className='fw-bold text-black my-4'>Explore books for <span className='text-danger'>Vidhya</span></h2>



                {
                    Sections.SECTIONS_DATA.filter(i => i.name === "Vidhya").map(item =>
                        <div className='row row-cols-3 g-4 mb-5'>
                            {item.books.map(item2 =>
                                <div key={item2.id} className="col ">
                                    <Link className='d-flex h-100 w-100 text-decoration-none rounded-4 overflow-hidden w-100 shadow border border-3 border-white' style={{ backgroundColor: item2.bgColor }} to={'' + item2.name}>
                                        <div className='d-flex p-3 rounded-4 overflow-hidden' >
                                            <img src={item2.url} alt="" className='me-3 rounded-3' width={150} height={230} style={{ objectFit: 'cover' }} />
                                            <div className='d-flex flex-column justify-content-center overflow-hidden'>
                                                <h6 className='fw-bold mb-2' style={{}}>{item2.name}</h6>
                                                <p className='text-muted lh-sm mb-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>English</p>
                                                <p className='text-muted lh-sm mb-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Vivek Swami Ji</p>
                                                <p className='text-muted lh-sm mb-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Dharmic course</p>
                                            </div>
                                            {/* <img src={starImg} alt="" className='w-50' /> */}
                                        </div>
                                    </Link>


                                    <div >


                                    </div>
                                </div>


                            )}
                        </div>
                    )
                }
            </div>

            <div className='bg-white rounded-start-5 shadow overflow-hidden w-25' >
                <div className='d-flex align-items-center justify-content-between p-4' style={{ backgroundColor: "#F5F5F5" }}>
                    <h5 className='text-danger fs-4 fw-bold me-3'>About Vidhya</h5>
                </div>
                {filteredData.length > 0 &&
                    <div className='p-3' style={{ wordWrap: 'break-word' }}
                        dangerouslySetInnerHTML={{ __html: filteredData[0].content }}></div>
                }
            </div>

        </div>
    )
}

export default Vidhya