import React, { useEffect, useState } from 'react'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css';

const PictureCropper = ({ image, setImage, aspectRatio, id, label, containerClass }) => {

    const [selectedImage, setSelectedImage] = useState(null)
    const inputId = id ? ("imageCropper" + id) : ("imageCropper" + new Date().getTime() / Math.random())
    let cropper

    const initiateCropper = () => {
        document.getElementById('fullscreenComponentModalButton').click()
        const img = document.getElementById("imgInput")
        cropper = new Cropper(img, { aspectRatio: aspectRatio ? aspectRatio : 1, viewMode: 1 })
    }

    const onCrop = () => {
        cropper
            .getCroppedCanvas()
            .toBlob((blob) => {
                const ext = '.' + blob.type.substring(blob.type.indexOf('/') + 1)
                const file = new File([blob], 'file' + ext, { type: blob.type });
                setImage(file)
                setSelectedImage(null)
            })
        cropper.destroy()
    }

    useEffect(() => { if (selectedImage) initiateCropper() }, [selectedImage])

    if (selectedImage) {
        return (
            <>
                <button type="button" id="fullscreenComponentModalButton" className="d-none" data-bs-toggle="modal" data-bs-target="#fullscreenComponentModal">
                    Launch demo modal
                </button>

                <div className="modal fade text-dark d-block" id="fullscreenComponentModal" tabIndex="-1" aria-labelledby="fullscreenComponentModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content">

                            <div className="modal-body d-flex align-items-center justify-content-center h-100 w-100 p-0">
                                <img src={URL.createObjectURL(selectedImage)} className='' id='imgInput' alt='crop' />
                            </div>

                            <div className="modal-footer bg-dark">
                                <button type='button' className='btn btn-primary' onClick={onCrop} data-bs-dismiss="modal" >Crop</button>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }


    if (image) return (<img className='w-25 my-3' src={URL.createObjectURL(image)} alt='crop' />)

    return (
        <div className={containerClass}>
            <label className='p-1 cursor-pointer d-flex align-items-center' htmlFor={inputId}>
                <div className='btn btn-sm btn-outline-primary rounded-pill px-3'>{label}</div>
            </label>
            <input type="file" className="d-none" id={inputId} onChange={(event) => setSelectedImage(event.target.files[0])} accept="image/*" />
        </div>
    )
}

export default React.memo(PictureCropper)
