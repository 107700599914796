import React from "react";
import img1 from "../../assets/images/p1.png";
import img2 from "../../assets/images/p2.png";
import img3 from "../../assets/images/p3.png";
import img4 from "../../assets/images/p4.png";
import firstImg from "../../assets/images/first.png";
import secondImg from "../../assets/images/second.png";
import thirdImg from "../../assets/images/third.png";
import { Link } from "react-router-dom";
import Slider from "../../components/ui/Slider";
import { useGetSliderQuery } from "../../services/sliderSlice";
import Loading from "../../components/ui/Loading";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import Api from "../../constants/Api";
import SliderEditModal from "../../components/ui/SliderEditModal";
import { useGetTutorialsQuery } from "../../services/tutorialSlice";
import {
  useCreateTechSupportMutation,
  useGetTechSupportQuery,
  useUpdateTechSupportMutation,
} from "../../services/adminSlice";
import ViewEditTechSupport from "../../components/modals/ViewEditTechSupport";
import ViewBranchModal from "../../components/modals/ViewBranchModal";
import { useGetBranchesQuery } from "../../services/branchSlice";
import ViewAllNoticeModal from "../../components/modals/ViewAllNoticeModal";

const SandhyaSabha = () => {
  const sliderRes = useGetSliderQuery();
  const type = "SandhyaSabha";

  const currentDate = new Date();

  const tutorialData = useGetTutorialsQuery();
  const [createTech, createTechRes] = useCreateTechSupportMutation();
  const [updateTech, updateTechRes] = useUpdateTechSupportMutation();
  const getTech = useGetTechSupportQuery();
  const branchRes = useGetBranchesQuery();

  if (sliderRes.isLoading || tutorialData.isLoading || getTech.isLoading)
    return <Loading />;
  if (sliderRes.isError) return <ApiErrorModal response={sliderRes} />;

  const releasedTutorials = tutorialData.data.filter((item) => {
    const releaseDate = new Date(item.releaseDate);
    return item.publishInUserApp && releaseDate <= currentDate;
  });

  // ++++++++++++++++++++++++++++

  const calculateTutorials = (branchId, users) => {
    const taughtTutorials = releasedTutorials?.filter((tutorial) => {
      return users.some((user) =>
        tutorial?.markAsReads.some((read) => read.userId === user.id)
      );
    });

    const untaughtTutorials = tutorialData.data?.filter((tutorial) => {
      return !users.some((user) =>
        tutorial.markAsReads.some((read) => read.userId === user.id)
      );
    });

    return {
      taughtTutorials: taughtTutorials?.length,
      untaughtTutorials: untaughtTutorials?.length,
    };
  };

  // Create an array of branch objects with performance metrics
  const branchPerformances = branchRes?.data?.map((data) => {
    const tutorials = calculateTutorials(data.id, data.users);
    return {
      branchId: data.id,
      branchName: data.branchName,
      mainBranch: data.mainBranch,
      status: data.status,
      totalTutors: data.users?.length,
      taughtTutorials: tutorials.taughtTutorials,
      untaughtTutorials: tutorials.untaughtTutorials,
    };
  });

  const sortedBranches = branchPerformances?.sort(
    (a, b) => b.taughtTutorials - a.taughtTutorials
  );

  // ++++++++++++++++

  const images = sliderRes.data
    .filter((i) => i.type === type)
    .map((i) => Api.SLIDER_URL + i.id + i.ext);

  return (
    <div className="py-4 h-100 d-flex">
      <div className=" px-5 w-75">
        <div className="p-4  bg-white rounded-5 shadow mb-5">
          <div className="d-flex  justify-content-between align-items-center ">
            <h4 className="fw-700 mb-4">ICGS Pinwheel Tutorials</h4>
            <SliderEditModal images={images} type={type} position />
          </div>

          <div className="d-flex justify-content-around">
            <div className="">
              <h5 className="fw-700 mb-3" style={{ color: "#0065AD" }}>
                Vidhya
              </h5>
              <div
                className="d-flex flex-column"
                style={{ borderLeft: "1px solid gray" }}
              >
                <Link
                  className="text-muted text-decoration-none mb-3 ps-2"
                  to={"/icgsPinwheel/vidhya/Adhyayan"}
                >
                  Adhyayan{" "}
                  {`(${
                    tutorialData.data.filter(
                      (item) =>
                        item.section == "Vidhya" && item.books == "Adhyayan"
                    ).length
                  })`}
                </Link>
                <Link
                  className="text-muted mb-3 text-decoration-none ps-2"
                  to={"/icgsPinwheel/vidhya/Arogya"}
                >
                  Arogya{" "}
                  {`(${
                    tutorialData.data.filter(
                      (item) =>
                        item.section == "Vidhya" && item.books == "Arogya"
                    ).length
                  })`}
                </Link>
                <Link
                  className="text-muted mb-3 text-decoration-none ps-2"
                  to={"/icgsPinwheel/vidhya/Kaushal"}
                >
                  Kaushal{" "}
                  {`(${
                    tutorialData.data.filter(
                      (item) =>
                        item.section == "Vidhya" && item.books == "Kaushal"
                    ).length
                  })`}
                </Link>
              </div>
            </div>

            <div className="">
              <h5 className="fw-700 mb-3" style={{ color: "#E5AD00" }}>Sadvidhya</h5>
              <div
                className="d-flex flex-column"
                style={{ borderLeft: "1px solid gray" }}
              >
                <Link
                  className="text-muted text-decoration-none mb-3 ps-2"
                  to={"/icgsPinwheel/sadvidhya/Sanskar"}
                >
                  Sanskar{" "}
                  {`(${
                    tutorialData.data.filter(
                      (item) =>
                        item.section == "Sadvidhya" && item.books == "Sanskar"
                    ).length
                  })`}
                </Link>
                <Link
                  className="text-muted mb-3 text-decoration-none ps-2 "
                  to={"/icgsPinwheel/vidhya/Sanskruti"}
                >
                  Sanskruti{" "}
                  {`(${
                    tutorialData.data.filter(
                      (item) =>
                        item.section == "Sadvidhya" && item.books == "Sanskruti"
                    ).length
                  })`}
                </Link>
                <Link
                  className="text-muted mb-3 text-decoration-none ps-2"
                  to={"/icgsPinwheel/sadvidhya/Vyaktitva%20Vikas"}
                >
                  Vyaktitva Vikas{" "}
                  {`(${
                    tutorialData.data.filter(
                      (item) =>
                        item.section == "Sadvidhya" &&
                        item.books == "Vyaktitva Vikas"
                    ).length
                  })`}
                </Link>
              </div>
            </div>

            <div className="">
              <h5 className="fw-700 mb-3" style={{ color: "#F532D0" }}>
                Brahmvidhya
              </h5>
              <div
                className="d-flex flex-column"
                style={{ borderLeft: "1px solid gray" }}
              >
                <Link
                  className="text-muted text-decoration-none mb-3 ps-2"
                  to={"/icgsPinwheel/brahmvidhya/Satsang"}
                >
                  Satsang{" "}
                  {`(${
                    tutorialData.data.filter(
                      (item) =>
                        item.section == "Brahmvidhya" && item.books == "Satsang"
                    ).length
                  })`}
                </Link>
                <Link
                  className="text-muted mb-3 text-decoration-none ps-2 "
                  to={"/icgsPinwheel/brahmvidhya/Seva"}
                >
                  Seva{" "}
                  {`(${
                    tutorialData.data.filter(
                      (item) =>
                        item.section == "Brahmvidhya" && item.books == "Seva"
                    ).length
                  })`}
                </Link>
                <Link
                  className="text-muted mb-3 text-decoration-none ps-2"
                  to={"/icgsPinwheel/brahmvidhya/Samarpan"}
                >
                  Samarpan{" "}
                  {`(${
                    tutorialData.data.filter(
                      (item) =>
                        item.section == "Brahmvidhya" &&
                        item.books == "Samarpan"
                    ).length
                  })`}
                </Link>
              </div>
            </div>

            <div>
              <h5 className="fw-700 mb-3" style={{ color: "#77AE43" }}>
                Charitram
              </h5>
              <div
                className="d-flex flex-column"
                style={{ borderLeft: "1px solid gray" }}
              >
                <Link
                  className="text-muted text-decoration-none mb-3 ps-2"
                  to={"/icgsPinwheel/charitram/Swaminarayan%20Charitam"}
                >
                  Swaminarayan Charitam{" "}
                  {`(${
                    tutorialData.data.filter(
                      (item) =>
                        item.section == "Charitram" &&
                        item.books == "Swaminarayan Charitam"
                    ).length
                  })`}
                </Link>
                <Link
                  className="text-muted mb-3 text-decoration-none ps-2"
                  to={"/icgsPinwheel/charitram/Sant%20Charitam"}
                >
                  Sant Charitam{" "}
                  {`(${
                    tutorialData.data.filter(
                      (item) =>
                        item.section == "Charitram" &&
                        item.books == "Sant Charitam"
                    ).length
                  })`}
                </Link>
                <Link
                  className="text-muted mb-3 text-decoration-none ps-2"
                  to={"/icgsPinwheel/charitram/Bhakta%20Charitam"}
                >
                  Bhakta Charitam{" "}
                  {`(${
                    tutorialData.data.filter(
                      (item) =>
                        item.section == "Charitram" &&
                        item.books == "Bhakta Charitam"
                    ).length
                  })`}
                </Link>
                <Link
                  className="text-muted mb-3 text-decoration-none ps-2"
                  to={"/icgsPinwheel/charitram/Sucharitam"}
                >
                  Sucharitam{" "}
                  {`(${
                    tutorialData.data.filter(
                      (item) =>
                        item.section == "Charitram" &&
                        item.books == "Sucharitam"
                    ).length
                  })`}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="me-auto  ms-1  mb-5 fs-2 fw-bold">
          <span className="text-danger">Manage</span> Sandhya Sabha
        </div>

        <div className="d-flex justify-content-around mb-5 ">
          <div className="d-flex flex-column align-items-center position-relative mx-2 ">
            <img
              src={img1}
              alt=""
              className="rounded-5  "
              style={{ borderColor: "#2973C1", border: "5px solid #2973C1" }}
              width={230}
            />
            <Link
              className="btn  rounded-4 position-absolute bottom-0 px-4 py-3 w-100 shadow"
              style={{ margin: -15, backgroundColor: "#C7E2FF" }}
              to={"/tutorials"}
            >
              Manage Tutorials
            </Link>
          </div>

          <div className="d-flex flex-column align-items-center position-relative mx-2">
            <img
              src={img2}
              alt=""
              className="rounded-5 "
              style={{ borderColor: "#f6c452", border: "5px solid #f6c452" }}
              width={230}
            />
            <Link
              className="btn  rounded-4 position-absolute bottom-0 px-4 py-3 w-100 shadow"
              style={{ margin: -15, backgroundColor: "#FFF1C1" }}
              to={"/tutorials/thisWeek"}
            >
              Tutorials this week
            </Link>
          </div>

          <div className="d-flex flex-column align-items-center position-relative mx-2">
            <img
              src={img3}
              alt=""
              className="rounded-5  "
              style={{ borderColor: "#F532CD", border: "5px solid #F532CD" }}
              width={230}
            />
            <Link
              className="btn  rounded-4 position-absolute bottom-0 px-4 py-3 w-100 shadow"
              style={{ margin: -15, backgroundColor: "#F4B5E7" }}
              to={"/tutorials/current"}
            >
              Current Tutorials
            </Link>
          </div>

          <div className="d-flex flex-column align-items-center position-relative mx-2">
            <img
              src={img4}
              alt=""
              className="rounded-5"
              style={{ borderColor: "#147629", border: "5px solid #147629" }}
              width={230}
            />
            <Link
              className="btn  rounded-4 position-absolute bottom-0 px-2 py-3 w-100 shadow"
              style={{ margin: -15, backgroundColor: "#D1FFE4" }}
              to={"/assignments/submissions"}
            >
              Assignment Submissions
            </Link>
          </div>
        </div>
      </div>

      <div className="p-3 text-center d-flex flex-column bg-white rounded-start-5 h-100 shadow w-25 ">
        <div
          className="fw-bold mb-3"
          style={{ color: "#1A1F9D" }}
          dangerouslySetInnerHTML={{ __html: getTech.data[0].sandhyaTitle }}
        ></div>
        {getTech?.data?.length > 0 ? (
          //updatepoppuo
          <ViewEditTechSupport
            refetch={getTech.refetch}
            id={1}
            isCreate={false}
            createTech={createTech}
            updateTech={updateTech}
            getTech={getTech}
            tutorialData={tutorialData}
          />
        ) : (
          <ViewEditTechSupport
            refetch={getTech.refetch}
            id={1}
            isCreate={true}
            createTech={createTech}
            updateTech={updateTech}
            getTech={getTech}
            tutorialData={tutorialData}
          />
        )}

        <div className="px-1 py-3 mt-3 container ">
          <div className="row row-cols-2 justify-content-center g-3">
            <div className="col">
              <div className="bg-white text-center shadow p-3 fs-13  h-100 ">
                <div className="fw-bold fs-5">{tutorialData.data.length}</div>
                <div className="fs-6">Tutorials Made</div>
              </div>
              <div
                className=""
                style={{ marginTop: -9, height: 9, backgroundColor: "#78AD46" }}
              ></div>
            </div>

            <div className="col">
              <div className="bg-white text-center shadow p-3 fs-13  h-100 ">
                <div className="fw-bold fs-5">{releasedTutorials.length}</div>
                <div className="fs-6">Tutorials Released</div>
              </div>
              <div
                className="bg-warning"
                style={{ marginTop: -9, height: 9 }}
              ></div>
            </div>

            <div className="col">
              <div className="bg-white text-center shadow p-3 fs-13  h-100 ">
                <div className="fw-bold fs-5 ">
                  {" "}
                  {
                    tutorialData.data.filter(
                      (item) => item.publishInUserApp == false
                    ).length
                  }
                </div>
                <div className="fs-6">Tutorials left to release</div>
              </div>
              <div
                className=""
                style={{ marginTop: -9, height: 9, backgroundColor: "#EE38CB" }}
              ></div>
            </div>
            <div className="col">
              <div className="bg-white text-center shadow p-3 fs-13  h-100 ">
                <div className="fw-bold fs-5">15</div>
                <div className="fs-6">Tutorials untaught</div>
              </div>
              <div
                className="bg-danger"
                style={{ marginTop: -9, height: 9 }}
              ></div>
            </div>
          </div>
        </div>

        <div className="w-100  flex-grow-1 overflow-hidden hide-scroll d-flex flex-column">
          <div
            className="text-white d-flex justify-content-between p-3"
            style={{ backgroundColor: "#1A1F9D" }}
          >
            <div className="fs-13">Gurukul Branch</div>
            <div className="fs-13">Tutorials Completed</div>
          </div>

          <div className="border rounded-bottom-5 overflow-auto flex-grow-1">
            {/* <div className='d-flex justify-content-between p-3'>
              <div>Rajkot Gurukul</div>
              <div className='d-flex align-items-center'>
                <div className='me-4'>80</div>
                <img src={firstImg} alt="" />
              </div>
            </div>

            <div className='d-flex justify-content-between p-3'>
              <div>Rajkot Gurukul</div>
              <div className='d-flex align-items-center'>
                <div className='me-4'>80</div>
                <img src={secondImg} alt="" />
              </div>
            </div>

            <div className='d-flex justify-content-between p-3'>
              <div>Rajkot Gurukul</div>
              <div className='d-flex align-items-center'>
                <div className='me-4'>80</div>
                <img src={thirdImg} alt="" />
              </div>
            </div> */}

            {sortedBranches?.slice(0, 3).map((branch, index) => ( 
              <div className="d-flex justify-content-between p-3">
                <div>{branch.branchName}</div>
                <div className="d-flex align-items-center">
                  <div className="me-4">{branch.taughtTutorials}</div>
                  {index === 0 && <img src={firstImg} alt="" />}
                  {index === 1 && <img src={secondImg} alt="" />}
                  {index === 2 && <img src={thirdImg} alt="" />}
                </div>
              </div>
            ))}

            <div className="d-flex justify-content-end">
              <div
                className="btn text-white px-3 m-4 rounded-3 "
                data-bs-toggle="modal"
                data-bs-target={"#" + "ViewBranchModal"}
                style={{ backgroundColor: "#1A1F9D" }}
              >
                View all
              </div>
              <ViewBranchModal releasedTutorials={releasedTutorials} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SandhyaSabha;
