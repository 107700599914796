import React from 'react'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import { Link } from 'react-router-dom'
import Icons from '../../components/ui/Icons'
import { useGetTutorialsQuery, useUpdateTutorialByIdMutation } from '../../services/tutorialSlice'

const TutorialThisWeek = () => {

    const response = useGetTutorialsQuery()
    const [updateTutorial, updateTutorialRes] = useUpdateTutorialByIdMutation();

    const handleUpdate = (e, id) => {
        const body = {
            publishInUserApp: e.target.checked
        }
        updateTutorial({ id, body })
            .then((res) => {
                if (!res.error) {
                    response.refetch()
                }
            })
    }


    if (response.isLoading) return <Loading />
    if (response.isError) return <ApiErrorModal response={response} />

    return (
        <div className=''>

            <div className='bg-white position-sticky sticky-top p-4 shadow'>
                <h2 className='fw-bold'>Tutorials <span className='text-danger'>This Week</span></h2>
                <p>Tutorials listed here are displayed in the user app, manage the user view efficiently at this place</p>
            </div>

            <div className="px-5">

                {response.isSuccess && response.data.filter(i => i.publishInUserApp === true).map(item =>

                    <div className='d-flex bg-white rounded-4 my-3 shadow' key={item.id}>

                        <div className='p-4 pe-5 border-end flex-grow-1'>
                            <h6 className='fw-bold'>{item.title}</h6>
                            <p className='fs-13 text-muted'>{`${item.section} > ${item.books} > ${item.topic}`}</p>
                        </div>

                        <div className='p-4 pe-5 border-end flex-grow-1'>
                            <h6 className='fw-bold'>{item.avgCompletionTime}</h6>
                            <p className='fs-13 text-muted'>Avg. Completion Time</p>
                        </div>

                        <div className='p-4 pe-5 border-end flex-grow-1'>
                            {/* <h6 className='fw-bold'>Last Updated</h6> */}
                            <p className='fs-13 text-muted'>Ratings</p>
                        </div>

                        <div className='p-4 border-end d-flex align-items-center'>
                            {/* <div className="btn btn-success btn-sm px-3 rounded-pill text-white">Resolved</div> */}
                            <div className="form-check form-switch">
                                <input className="form-check-input fs-3" type="checkbox" onChange={(e) => handleUpdate(e, item.id)} defaultChecked={item.publishInUserApp} role="switch" id={"toggle" + item.id} />
                            </div>
                        </div>

                        <div className='p-4 pe-5 border-end flex-grow-1'>
                            <h6 className='fw-bold'>10 Suggestions</h6>
                            <p className='fs-13 text-danger fw-bold'>View Suggestions</p>
                        </div>

                        <div className='d-flex p-4'>
                            <Link className='btn btn-outline-success p-0 rounded-circle text-hover-white' to={'/tutorials/' + item.id}>{Icons.arrow()}</Link>
                        </div>

                    </div>
                )}
            </div>

        </div>
    )
}

export default TutorialThisWeek