import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBooks: builder.query({ query: () => `book`, keepUnusedDataFor: 0 }),
        getBookById: builder.query({ query: (id) => `book?id=${id}`, keepUnusedDataFor: 0 }),
        createBook: builder.mutation({ query: (body) => ({ url: `book`, method: 'POST', body }) }),
        updateBook: builder.mutation({ query: ({id, body}) => ({ url: `book?id=${id}`, method: 'PUT', body }) }),
        deleteBook: builder.mutation({ query: (id) => ({ url: `book?id=${id}`, method: 'DELETE' }) }),
        getChapters: builder.query({ query: () => `chapter`, keepUnusedDataFor: 0 }),
        createChapter: builder.mutation({ query: (body) => ({ url: `chapter`, method: 'POST', body }) }),
        updateChapter: builder.mutation({ query: ({id, body}) => ({ url: `chapter?id=${id}`, method: 'PATCH', body }) }),
        deleteChapter: builder.mutation({ query: (id) => ({ url: `chapter?id=${id}`, method: 'DELETE' }) }),
    })
})

export const {
    useGetBooksQuery,
    useGetBookByIdQuery,
    useCreateBookMutation,
    useUpdateBookMutation,
    useDeleteBookMutation,
    useGetChaptersQuery,
    useCreateChapterMutation,
    useUpdateChapterMutation,
    useDeleteChapterMutation
} = extendedApiSlice
