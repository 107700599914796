import React, { useState, useEffect, useRef } from 'react'
import Input from '../../components/form/Input'
import { useLocation, useNavigate } from 'react-router-dom';
import { useCreateBookMutation, useGetBookByIdQuery } from '../../services/bookSlice';
import poster from '../../assets/images/training_poster.png'
import CreateModule from './CreateModule';
import { useCreateModuleMutation, useGetModulesQuery } from '../../services/moduleSlice';
import { useCreateSessionMutation } from '../../services/sessionSlice';
import TrainingDeleteModal from '../../components/modals/deleteModals/TrainingDeleteModal';
import Loading from '../../components/ui/Loading';
import Icons from '../../components/ui/Icons';
import EditModuleNameModal from '../../components/modals/EditModuleNameModal';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import Button from '../../components/form/Button';

const Training = () => {

  // const location = useLocation()
  // const arr = location.pathname.split('/')
  // const id = arr[2];

  const [selectedTab, setSelectedTab] = useState(null);
  const activeTabClassName = "flex-grow-1 bg-primary text-white p-4 pe-5 text-center border-end border-5 border-danger fw-bold text-nowrap";
  const inactiveTabClassName = "flex-grow-1 p-4 pe-5 text-center text-nowrap";


  const [createModule, createModuleRes] = useCreateModuleMutation()
  const [createSession, createSessionRes] = useCreateSessionMutation()
  const response = useGetModulesQuery();

  const navigate = useNavigate();

  const addModuleTab = (num) => {
    createModule({
      name: "Module " + num,
      overview: "<p>Write your content here...</p>"
    })
      .then(() => {
        response.refetch()
      });
  };

  const tabsContainerRef = useRef(null);

  useEffect(() => {
    // Scroll to the selected tab when it changes
    if (tabsContainerRef.current) {
      const selectedTabElement = tabsContainerRef.current.querySelector('.active-tab');
      if (selectedTabElement) {
        selectedTabElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  }, [selectedTab]);

  useEffect(() => {
    if (response.isSuccess) {
      setSelectedTab(response.data.length > 0 ? 0 : null)
    }
  }, [response.isSuccess, response.data])

  useEffect(() => {
    if (response.isFetching) {
      setSelectedTab(null)
    }
  }, [response.isFetching])


  if (response.isLoading || response.isFetching) return <Loading />
  if (response.isError) return <ApiErrorModal response={response} />

  return (

    <>
      {/* <div className="bg-white  p-4 shadow">
        <h3 className="fw-bold mb-2">Manage  <span className="text-danger">Training </span></h3>
        <p>Manage all training modules right at this place.</p>
      </div> */}
      <div className='py-3 px-5'>



        {/* <div className="position-relative">
        <div className="btn btn-sm bg-danger text-white rounded-pill px-3 position-absolute end-0" style={{ marginTop: 45, marginRight: 55 }}>Edit Slides</div>
        <img src={poster} alt="" className='w-100 mb-4' />
      </div> */}

        <div className='card rounded-5  flex-row shadow  overflow-hidden' style={{ height: '75vh' }} >
          <div className='tabs-container overflow-hidden position-relative d-flex flex-column hide-scroll' ref={tabsContainerRef} >
            <div className='flex-grow-1 overflow-auto'>

              {response.data.map((tab, index) => (
                <div
                  key={index}
                  className={selectedTab === index ? activeTabClassName + ' active-tab' : inactiveTabClassName}
                  onClick={() => setSelectedTab(index)}
                >
                  {tab.name}
                  <span className='ms-2'><EditModuleNameModal id={tab.id} refetch={response.refetch} /></span>
                  <TrainingDeleteModal id={tab.id} refetch={response.refetch} />
                </div>
              ))}

            </div>

            <Button
              type="button"
              className="btn bg-danger py-3 w-100 text-white rounded-0"
              style={{ position: 'absolute', bottom: 0, left: 0 }} // Adjust the position of the button
              res={createModuleRes}
              loadingLabel={"Creating"}
              onClick={() => addModuleTab(response.data.length)}
            >
              Create Module
            </Button>
          </div>
          {typeof selectedTab === 'number' &&
            <div className='w-100 '>
              <CreateModule
                module={response.data[selectedTab]}
                onContentChange={() => { }}
                refetch={response.refetch}
              />
            </div>
          }
        </div>


      </div>
    </>
  )


}

export default Training