import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBranches: builder.query({ query: () => `branch`, keepUnusedDataFor: 0 }),
        getBranch: builder.query({ query: (id) => `branch?id=${id}`, keepUnusedDataFor: 0 }),
    })
})

export const {
   useGetBranchQuery,
   useGetBranchesQuery
} = extendedApiSlice
