import React from 'react'
import { useUpdateChapterMutation } from '../../services/bookSlice';
import SuccessModal from '../../components/modals/SuccessModal';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import Button from '../../components/form/Button';
import FormattedInput from '../../components/form/FormattedInput';

const CreateChapter = ({ chapter, refetch }) => {

    const id = chapter.id;
    const [updateChapter, updateChapterRes] = useUpdateChapterMutation(chapter.id)
    const name='formattedInput'+id

    console.log("chta",chapter)

    const onSubmit = (e) => {
        e.preventDefault()
        const body = {
            text: e.target[name].value
        }
        updateChapter({ id, body })
            .then((res) => {
                if (!res.error) {
                    refetch()
                }
            })
    }

    return <div className='p-5'>

        {updateChapterRes.isSuccess && <SuccessModal message={"Chapter has been updated."} />}
        {updateChapterRes.isError && <ApiErrorModal response={updateChapterRes} />}

        <form className='' onSubmit={onSubmit} noValidate>
            <FormattedInput name={name} defaultValue={chapter.text} label={' '} />
            <Button
                className='btn btn-primary text-white rounded-pill text-nowrap px-5'
                res={updateChapterRes}
                loadingLabel={"Updating..."}
            >
                Update
            </Button>
        </form>
    </div>
}

export default CreateChapter