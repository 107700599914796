import React, { useState } from 'react'
import { useCreateTutorialMutation, useGetTutorialsQuery } from '../../services/tutorialSlice';
import SuccessModal from '../../components/modals/SuccessModal';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import Button from '../../components/form/Button';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import Loading from '../../components/ui/Loading';
import Sections from '../../constants/Sections';
import FormattedInput from '../../components/form/FormattedInput';
import Cropper from '../../components/Cropper';
import SliderEditModal from '../../components/ui/SliderEditModal';
import Slider from '../../components/ui/Slider';
import UploadImage from '../../components/ui/UploadImage';

const CreateTutorial = () => {

  const [selectedTab, setSelectedTab] = useState("Overview");
  const [image, setImage] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("ENGLISH");


  const activeTabClassName = "flex-grow-1 bg-light py-4 text-center border-bottom border-5 border-danger fw-bold";
  const inactiveTabClassName = "flex-grow-1 bg-primary py-4 text-center text-white";
  const inputClass = "bg-white mx-3";

  const [isCurrentTutorialsChecked, setIsCurrentTutorialsChecked] = useState(false);
  const [isAcceptingAssignmentsChecked, setIsAcceptingAssignmentsIsChecked] = useState(false);
  const [isPublishInUserAppChecked, setIsPublishInUserAppChecked] = useState(false);

  const [createTutorial, createTutorialRes] = useCreateTutorialMutation();
  const tutorialResponse = useGetTutorialsQuery();

  const handleCurrentTutorialsCheckboxChange = () => setIsCurrentTutorialsChecked(!isCurrentTutorialsChecked);
  const handleAcceptingAssignmentsCheckboxChange = () => setIsAcceptingAssignmentsIsChecked(!isAcceptingAssignmentsChecked);
  const handlePublishInUserAppCheckboxChange = () => setIsPublishInUserAppChecked(!isPublishInUserAppChecked);

  const [selectedSection, setSelectedSection] = useState("Vidhya");
  const [selectedBook, setSelectedBook] = useState("Adhyayan");
  const [selectedTopicCheck, setSelectedTopicCheck] = useState(false);

  const handleCheckOnChange = () => {
    setSelectedTopicCheck(!selectedTopicCheck)
  }

  const handleBookChange = (value) => {
    setSelectedBook(value);
  };

  const booksOptions = selectedSection
    ? Sections.SECTIONS_DATA.find((section) => section.name === selectedSection)?.books.map((book) => book.name) || []
    : [];

  const getTopicsOptions = (tutorials) => tutorials.filter(i => i.section === selectedSection && i.books === selectedBook).map(item => item.topic)


  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    const formData = new FormData();

    formData.append('title', form['Tutorial Name'].value);
    formData.append('pageTitle', form['Assign Title'].value);
    formData.append('avgCompletionTime', form['Avg Completion Time'].value);
    formData.append('releaseDate', form['Release Date'].value);
    formData.append('books', form['Book'].value);
    formData.append('section', form['Section'].value);
    formData.append('topic', form['Topic'].value);

    formData.append('overview', form['Overview'].value);
    formData.append('tutorial', form['Tutorial'].value);
    formData.append('manual', form['ICGS Manual'].value);
    formData.append('activity', form['Activity'].value);
    formData.append('material', form['Material'].value);
  
    // Append Hindi fields
    formData.append('overviewHindi', form['Overview Hindi'].value);
    formData.append('tutorialHindi', form['Tutorial Hindi'].value);
    formData.append('manualHindi', form['ICGS Manual Hindi'].value);
    formData.append('activityHindi', form['Activity Hindi'].value);
    formData.append('materialHindi', form['Material Hindi'].value);
  
    // Append Gujrati fields
    formData.append('overviewGujrati', form['Overview Gujrati'].value);
    formData.append('tutorialGujrati', form['Tutorial Gujrati'].value);
    formData.append('manualGujrati', form['ICGS Manual Gujrati'].value);
    formData.append('activityGujrati', form['Activity Gujrati'].value);
    formData.append('materialGujrati', form['Material Gujrati'].value);

    formData.append('quizzlet', ".");
    formData.append('assignment', form['Assignment'].value);
    formData.append('currentTutorials', isCurrentTutorialsChecked);
    formData.append('acceptingAssignments', isAcceptingAssignmentsChecked);
    formData.append('publishInUserApp', isPublishInUserAppChecked);

    if (image) formData.append('image', image)



    // Append attachments if needed
    // formData.append('attachments', file);

    createTutorial(formData)
      .then((res) => {
        if (!res.error) {
          tutorialResponse.refetch();
        }
      })
      .catch((error) => {
        console.error('Error creating tutorial:', error);
      });
  };


  if (tutorialResponse.isLoading) return <Loading />
  if (tutorialResponse.isError) return <ApiErrorModal response={tutorialResponse} />

  const topicsOptions = getTopicsOptions(tutorialResponse.data)

  return (
    <div className='p-5'>

      {createTutorialRes.isSuccess && <SuccessModal label={"Created!"} message={"Tutorial has been created."} to={{ link: "/tutorials", text: "Go to Tutorials" }} closeTo={"/tutorials"} />}
      {createTutorialRes.isError && <ApiErrorModal response={createTutorialRes} />}
      <form className="" onSubmit={onSubmit} noValidate>

        <div className='d-flex flex-column justify-content-between align-items-start mb-5'>
          <h2 className='fw-bold text-danger mb-3'>Tutorials</h2>

          <div className='d-flex justify-content-between w-100'>

            <div>
              <div className='d-flex mb-3'>
                <Input name={'Tutorial Name'} labelName={'Tutorial Name'} inputClass={inputClass} required />
                <Input name={'Avg Completion Time'} labelName={'Avg Completion Time'} inputClass={inputClass} />
                <Input type={'date'} name={'Release Date'} labelName={'Release Date'} inputClass={inputClass} />


              </div>

              <div className='d-flex'>
                <Select
                  name={'Section'}
                  inputClass={inputClass}
                  options={Sections.SECTIONS_DATA.map((item) => item.name)}
                  value={selectedSection}
                  onChange={(e) => setSelectedSection(e.target.value)}

                />
                <Select
                  name={'Book'}
                  inputClass={inputClass}
                  options={booksOptions}
                  value={selectedBook}
                  onChange={(e) => handleBookChange(e.target.value)}

                />
                <Select
                  name={'Language'}
                  lableName={' '}
                  inputClass={inputClass}
                  options={[
                    'ENGLISH', 'HINDI', 'GUJRATI'
                  ]}
                  value={selectedLanguage}
                  onChange={(e) => setSelectedLanguage(e.target.value)}
                  required
                />

                {
                  topicsOptions.length > 0 &&
                  <div className='mx-3'>
                    <input type="checkbox" value="" id="topicCheck" onChange={handleCheckOnChange} />
                    <label htmlFor="topicCheck" className='mx-3'>
                      Add new topic
                    </label>
                  </div>
                }
                {
                  !selectedTopicCheck && topicsOptions.length > 0 &&
                  <Select name={'Topic'} options={topicsOptions} inputClass={inputClass} />
                }
                {
                  (selectedTopicCheck || topicsOptions.length === 0) &&
                  <Input name={'Topic'} inputClass={inputClass} />
                }
              </div>
            </div>

            <div className="form-check form-switch me-4 d-flex flex-column">


              <div>
                <input className="form-check-input" type="checkbox" role="switch" id="toggle" onChange={handleCurrentTutorialsCheckboxChange} />
                <label className="form-check-label fw-bold" htmlFor="toggle">Current Tutorials</label>
              </div>

              <div>
                <input className="form-check-input" type="checkbox" role="switch" id="toggle2" onChange={handleAcceptingAssignmentsCheckboxChange} />
                <label className="form-check-label fw-bold" htmlFor="toggle2">Accepting Assingments</label>
              </div>

              <div>
                <input className="form-check-input" type="checkbox" role="switch" id="toggle3" onChange={handlePublishInUserAppCheckboxChange} />
                <label className="form-check-label fw-bold" htmlFor="toggle3">Publish in user app</label>
              </div>

            </div>

          </div>


        </div>


        <div className='card rounded-5 overflow-hidden shadow'>
          <div className="d-flex justify-content-between">
            <div className={selectedTab === "Overview" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Overview")}>Overview</div>
            <div className={selectedTab === "Tutorial" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Tutorial")}>Tutorial</div>
            <div className={selectedTab === "ICGS Manual" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("ICGS Manual")}>ICGS Manual</div>
            <div className={selectedTab === "Activity" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Activity")}>Activity</div>
            <div className={selectedTab === "Material" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Material")}>Material</div>
            <div className={selectedTab === "Suggestions" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Suggestions")}>Suggestions</div>
            <div className={selectedTab === "Assignment" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Assignment")}>Assignment</div>
          </div>

          {/* <div className={selectedTab === "Overview" ? "d-block" : "d-none"}><FormattedInput name={"Overview"} label={' '} /></div>
          <div className={selectedTab === "Tutorial" ? "d-block" : "d-none"}><FormattedInput name={"Tutorial"} label={' '} /></div>
          <div className={selectedTab === "ICGS Manual" ? "d-block" : "d-none"}><FormattedInput name={"ICGS Manual"} label={' '} /></div>
          <div className={selectedTab === "Activity" ? "d-block" : "d-none"}><FormattedInput name={"Activity"} label={' '} /></div>
          <div className={selectedTab === "Material" ? "d-block" : "d-none"}><FormattedInput name={"Material"} label={' '} /></div> */}


          {/* =========== */}

        
            <>
              <div className={(selectedTab === "Overview" && selectedLanguage === "ENGLISH") ? "d-block" : "d-none"}><FormattedInput name={"Overview"} label={' '} /></div>
              <div className={(selectedTab === "Tutorial" && selectedLanguage === "ENGLISH") ? "d-block" : "d-none"}><FormattedInput name={"Tutorial"} label={' '} /></div>
              <div className={(selectedTab === "ICGS Manual" && selectedLanguage === "ENGLISH") ? "d-block" : "d-none"}><FormattedInput name={"ICGS Manual"} label={' '} /></div>
              <div className={(selectedTab === "Activity" && selectedLanguage === "ENGLISH") ? "d-block" : "d-none"}><FormattedInput name={"Activity"} label={' '} /></div>
              <div className={(selectedTab === "Material" && selectedLanguage === "ENGLISH") ? "d-block" : "d-none"}><FormattedInput name={"Material"} label={' '} /></div>
            </>
       

          
            <>
              <div className={(selectedTab === "Overview" && selectedLanguage === "HINDI") ? "d-block" : "d-none"}><FormattedInput name={"Overview Hindi"} label={' '} /></div>
              <div className={(selectedTab === "Tutorial" && selectedLanguage === "HINDI") ? "d-block" : "d-none"}><FormattedInput name={"Tutorial Hindi"} label={' '} /></div>
              <div className={(selectedTab === "ICGS Manual" && selectedLanguage === "HINDI") ? "d-block" : "d-none"}><FormattedInput name={"ICGS Manual Hindi"} label={' '} /></div>
              <div className={(selectedTab === "Activity" && selectedLanguage === "HINDI") ? "d-block" : "d-none"}><FormattedInput name={"Activity Hindi"} label={' '} /></div>
              <div className={(selectedTab === "Material" && selectedLanguage === "HINDI") ? "d-block" : "d-none"}><FormattedInput name={"Material Hindi"} label={' '} /></div>
            </>
        
       
            <>
              <div className={(selectedTab === "Overview" && selectedLanguage === "GUJRATI") ? "d-block" : "d-none"}><FormattedInput name={"Overview Gujrati"} label={' '} /></div>
              <div className={(selectedTab === "Tutorial" && selectedLanguage === "GUJRATI") ? "d-block" : "d-none"}><FormattedInput name={"Tutorial Gujrati"} label={' '} /></div>
              <div className={(selectedTab === "ICGS Manual" && selectedLanguage === "GUJRATI") ? "d-block" : "d-none"}><FormattedInput name={"ICGS Manual Gujrati"} label={' '} /></div>
              <div className={(selectedTab === "Activity" && selectedLanguage === "GUJRATI") ? "d-block" : "d-none"}><FormattedInput name={"Activity Gujrati"} label={' '} /></div>
              <div className={(selectedTab === "Material" && selectedLanguage === "GUJRATI") ? "d-block" : "d-none"}><FormattedInput name={"Material Gujrati"} label={' '} /></div>
            </>
      



          {/* //================= */}
          <div className={selectedTab === "Suggestions" ? "d-block" : "d-none"}><FormattedInput name={"Suggestions"} label={' '} /></div>

          <div className={selectedTab === "Assignment" ? "d-block" : "d-none"}>

            <div className="div m-3">
              {/* <Cropper id={4} image={image} setImage={setImage} aspectRatio={3 / 2} label={'Add cover'} containerClass={'w-25 ms-3 mt-2'} /> */}
              {/* <Slider images={image} type={"Tutorial"} position /> */}
              {/* <UploadImage image={image} setImage={setImage} /> */}
            </div>

            <FormattedInput name={"Assignment"} label={' '}>
              <div className='m-3 text-center'>
                <Input name={'Assign Title'} inputClass={inputClass} />
              </div>
            </FormattedInput>

            <div>
              <Cropper id={4} image={image} setImage={setImage} aspectRatio={3 / 2} label={'Add cover'} containerClass={'w-25 ms-3 mt-2'} />

            </div>
          </div>
          {/* <div className={selectedTab === "Assig Title" ? "d-block" : "d-none"}><FormattedInput name={"Assig Title"} label={' '} /></div> */}

        </div>

        <Button
          className='col-2 btn btn-primary text-white rounded-pill text-nowrap my-3'
          res={createTutorialRes}
          loadingLabel={"Creating"}
        // onClick={handleCreate}
        >
          Create
        </Button>

      </form>
    </div>
  )
}

export default CreateTutorial