import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAssignments: builder.query({ query: () => `assignment`, keepUnusedDataFor: 0 }),
        getAssignmentById: builder.query({ query: (id) => `assignment?id=${id}`, keepUnusedDataFor: 0 }),
        createAssignment: builder.mutation({ query: (body) => ({ url: `assignment`, method: 'POST', body }) }),
        updateAssignment: builder.mutation({ query: ({ id, body }) => ({ url: `assignment?id=${id}`, method: 'PATCH', body }) }),
        deleteAssignment: builder.mutation({ query: (id) => ({ url: `assignment?id=${id}`, method: 'DELETE' }) }),
    })
})

export const {
    useGetAssignmentsQuery,
    useGetAssignmentByIdQuery,
    useCreateAssignmentMutation,
    useUpdateAssignmentMutation,
    useDeleteAssignmentMutation
} = extendedApiSlice
