import React from 'react'
import Input from '../../components/form/Input'

const Create = () => {
    return (
        <div className='py-3 px-5'>
            <div className='bg-white rounded-5 shadow'>
                <h2 className='text-danger fw-bold text-center py-3'>Create Branch</h2>
                <div className='text-center bg-primary py-2 text-white'>PRIMARY DETAILS</div>

                <div className='row row-cols-2 p-5 gx-5'>
                    <Input name={'Branch Name'} label={'Branch Name'} containerClass={'mb-4'} />
                    <Input name={'Branch ID'} label={'Branch ID'} containerClass={'mb-4'} />
                    <Input name={'Establishment Date'} label={'Establishment Date'} containerClass={'mb-4'} />
                    <Input name={'Country'} label={'Country'} containerClass={'mb-4'} />
                    <Input name={'State'} label={'State'} containerClass={'mb-4'} />
                    <Input name={'City'} label={'City'} />
                </div>

                <div className='text-center bg-primary py-2 text-white'>SCHOOL DETAILS</div>

                <div className='row row-cols-2 p-5 gx-5'>
                    <Input name={'Current Board'} label={'Current Board'} containerClass={'mb-4'} />
                    <Input name={'Affiliation Date'} label={'Affiliation Date'} containerClass={'mb-4'} />
                    <Input name={'Grade Levels'} label={'Grade Levels'} containerClass={'mb-4'} />
                    <Input name={'Medium (language)'} label={'Medium (language)'} containerClass={'mb-4'} />
                    <Input name={'Accreditation'} label={'Accreditation'} containerClass={'mb-4'} />
                    <Input name={'Student capacity'} label={'Student capacity'} />
                </div>

                <div className='text-center bg-primary py-2 text-white'>HOSTEL FACILITY</div>

                <div className='row row-cols-2 p-5 gx-5'>
                    <Input name={'Grade Levels'} label={'Grade Levels'} containerClass={'mb-4'} />
                    <Input name={'Student capacity'} label={'Student capacity'} />
                </div>

                <div className="btn btn-primary rounded-pill text-white px-4 ms-5 mb-4">Create Branch</div>

            </div>
        </div>
    )
}

export default Create