import React, { useState } from 'react'
import poster from '../../assets/images/info_poster.png'
import poster2 from '../../assets/images/info2.png'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import { Link } from 'react-router-dom'
import { useGetInfosQuery, useUpdateInfoMutation } from '../../services/infoSlice'
import InfoDeleteModal from '../../components/modals/deleteModals/InfoDeleteModal'
import { useCreateSliderMutation, useGetSliderQuery } from '../../services/sliderSlice'
import SuccessModal from '../../components/modals/SuccessModal'
import Cropper from '../../components/Cropper'
import Api from '../../constants/Api'
import EditSlidesModal from '../../components/modals/EditSlidesModal'
import Slider from '../../components/ui/Slider'
import { FaTrash } from 'react-icons/fa6'
import Button from '../../components/form/Button'
import { TiArrowSortedDown, TiArrowSortedUp, TiEdit } from 'react-icons/ti'

const Info = () => {

  const response = useGetInfosQuery();


  const [updateInfo, setUpdateInfo] = useUpdateInfoMutation()

  const updateOrder = (slide1, slide2) => {
    updateInfo({ id: slide1.id, body: { order: slide2.order } })
      .then((res1) => {
        if (!res1.error) {
          updateInfo({ id: slide2.id, body: { order: slide1.order } })
            .then((res2) => {
              if (!res1.error) response.refetch()
            })
        }
      })
  }

  if (response.isLoading) return <Loading />
  if (response.isError) return <ApiErrorModal response={response} />

  return (
    <div className='py-3 px-5'>

      <Slider type={"Info"} />

      <div className='d-flex justify-content-between align-items-center mb-3'>
        <h3 className='fw-bold mb-3 text-danger'>About ICGS</h3>
        <Link to={'create'} className="btn bg-danger text-white rounded-pill px-5">Add New Info</Link>
      </div>

      {response.data.map((item, index) => (
        <div key={item.id} className='card border-0 rounded-5 shadow p-4 my-3'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='' dangerouslySetInnerHTML={{ __html: item.content }}></div>
            <div className=' d-flex'>
              <Link to={"" + item.id} className="btn btn-primary px-3 text-white me-3 align-self-start"><TiEdit /></Link>
              <InfoDeleteModal id={item.id} refetch={response.refetch} />
              <div className='d-flex flex-column align-items-center'>
                {index !== 0 &&
                  <Button
                    className='btn rounded-circle mb-2 btn-sm btn-primary ms-3' 
                    res={setUpdateInfo}
                    onClick={() => updateOrder(response.data[index], response.data[index - 1])}
                  >
                    <TiArrowSortedUp />
                  </Button>
                }
                {index < response.data.length - 1 &&
                  <Button
                    className='btn rounded-circle btn-sm btn-primary ms-3'
                    res={setUpdateInfo}
                    onClick={() => updateOrder(response.data[index], response.data[index + 1])}
                  >
                    <TiArrowSortedDown />
                  </Button>
                }
              </div>
            </div>
          </div>
        </div>
      ))}




    </div>
  )
}

export default Info
