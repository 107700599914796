import React, { useEffect, useState } from 'react'
import { useDeleteSubmissionMutation, useGetTutorialByIdQuery, useGetTutorialsQuery, useUpdateTutorialByIdMutation } from '../../services/tutorialSlice';
import SuccessModal from '../../components/modals/SuccessModal';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import Button from '../../components/form/Button';
import { useParams } from 'react-router-dom';
import Loading from '../../components/ui/Loading';
import Sections from '../../constants/Sections';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import Api from '../../constants/Api';
import FormattedInput from '../../components/form/FormattedInput';
import Cropper from '../../components/Cropper';
import Slider from '../../components/ui/Slider';
import UploadImage from '../../components/ui/UploadImage';

const EditTutorial = () => {

  const { id } = useParams();
  const [image, setImage] = useState(null);

  const [selectedTab, setSelectedTab] = useState("Overview");
  const activeTabClassName = "flex-grow-1 bg-light py-4 text-center border-bottom border-5 border-danger fw-bold";
  const inactiveTabClassName = "flex-grow-1 bg-primary py-4 text-center text-white";
  const inputClass = "bg-white mx-3";

  const [editorOverview, setEditorOverview] = useState("");
  const [editorTutorial, setEditorTutorial] = useState("");
  const [editorAssignment, setEditorAssignment] = useState("");

  const [selectedLanguage, setSelectedLanguage] = useState("ENGLISH");
  const [isCurrentTutorialsChecked, setIsCurrentTutorialsChecked] = useState(false);
  const [isAcceptingAssignmentsChecked, setIsAcceptingAssignmentsIsChecked] = useState(false);
  const [isPublishInUserAppChecked, setIsPublishInUserAppChecked] = useState(false);

  const [updateTutorial, updateTutorialRes] = useUpdateTutorialByIdMutation();
  const tutorialResById = useGetTutorialByIdQuery(id)
  const tutorialResponse = useGetTutorialsQuery();

  const [deleteSub, deleteSubRes] = useDeleteSubmissionMutation()

  const handleCurrentTutorialsCheckboxChange = () => {

    setIsCurrentTutorialsChecked(!isCurrentTutorialsChecked);

    const body = {
      currentTutorials: !isCurrentTutorialsChecked,
    }
    updateTutorial({ id, body })
      .then((res) => {
        if (!res.error) {
          tutorialResponse.refetch()
        }
      })
  }

  const handleAcceptingAssignmentsCheckboxChange = () => {
    setIsAcceptingAssignmentsIsChecked(!isAcceptingAssignmentsChecked);

    const body = {
      acceptingAssignments: !isAcceptingAssignmentsChecked,
    }
    updateTutorial({ id, body })
      .then((res) => {
        if (!res.error) {
          tutorialResponse.refetch()
        }
      })

  }

  const handlePublishInUserAppCheckboxChange = () => {
    setIsPublishInUserAppChecked(!isPublishInUserAppChecked);
    const body = {
      publishInUserApp: !isPublishInUserAppChecked,
    }
    updateTutorial({ id, body })
      .then((res) => {
        if (!res.error) {
          tutorialResponse.refetch()
        }
      })
  }

  const [selectedSection, setSelectedSection] = useState("Vidhya");
  const [selectedBook, setSelectedBook] = useState("Adhyayan");
  const [selectedTopicCheck, setSelectedTopicCheck] = useState(false);

  const handleCheckOnChange = () => {
    setSelectedTopicCheck(!selectedTopicCheck)
  }

  const handleBookChange = (value) => {
    setSelectedBook(value);
  };

  const booksOptions = selectedSection
    ? Sections.SECTIONS_DATA.find((section) => section.name === selectedSection)?.books.map((book) => book.name) || []
    : [];

  const getTopicsOptions = (tutorials) => tutorials.filter(i => i.section === selectedSection && i.books === selectedBook).map(item => item.topic)

  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    const formData = new FormData();

    formData.append('title', form['Tutorial Name'].value);
    formData.append('pageTitle', form['Assign Title'].value);
    formData.append('avgCompletionTime', form['Avg Completion Time'].value);
    formData.append('releaseDate', form['Release Date'].value);
    formData.append('books', form['Book'].value);
    formData.append('section', form['Section'].value);
    formData.append('topic', form['Topic'].value);
    formData.append('overview', form['Overview'].value);
    formData.append('tutorial', form['Tutorial'].value);
    formData.append('manual', form['ICGS Manual'].value);
    formData.append('activity', form['Activity'].value);



    // Append Hindi fields
    formData.append('overviewHindi', form['Overview Hindi'].value);
    formData.append('tutorialHindi', form['Tutorial Hindi'].value);
    formData.append('manualHindi', form['ICGS Manual Hindi'].value);
    formData.append('activityHindi', form['Activity Hindi'].value);
    formData.append('materialHindi', form['Material Hindi'].value);

    // Append Gujrati fields
    formData.append('overviewGujrati', form['Overview Gujrati'].value);
    formData.append('tutorialGujrati', form['Tutorial Gujrati'].value);
    formData.append('manualGujrati', form['ICGS Manual Gujrati'].value);
    formData.append('activityGujrati', form['Activity Gujrati'].value);
    formData.append('materialGujrati', form['Material Gujrati'].value);

    formData.append('quizzlet', ".");
    formData.append('assignment', form['Assignment'].value);
    formData.append('material', form['Material'].value);
    formData.append('currentTutorials', isCurrentTutorialsChecked);
    formData.append('acceptingAssignments', isAcceptingAssignmentsChecked);
    formData.append('publishInUserApp', isPublishInUserAppChecked);
    formData.append('language', selectedLanguage);

    if (image) formData.append('image', image)

    // Append attachments if needed
    // formData.append('attachments', file);

    updateTutorial({ id, body: formData })
      .then((res) => {
        if (!res.error) {
          tutorialResponse.refetch();
        }
      })
      .catch((error) => {
        console.error('Error creating tutorial:', error);
      });
  };


  const handleSubDelete = (id) => {

    deleteSub(id)
      .then(() => tutorialResById.refetch())
  }

  useEffect(() => {
    if (tutorialResById.isSuccess) {
      setSelectedSection(tutorialResById.data.section)
      setSelectedBook(tutorialResById.data.books)
      setIsCurrentTutorialsChecked(tutorialResById.data.currentTutorials)
      setIsAcceptingAssignmentsIsChecked(tutorialResById.data.acceptingAssignments)
      setIsPublishInUserAppChecked(tutorialResById.data.publishInUserApp)
    }
  }, [tutorialResById.isSuccess])

  if (tutorialResById.isLoading || tutorialResponse.isLoading) return <Loading />
  if (tutorialResById.isError) return <ApiErrorModal response={tutorialResById} />
  if (tutorialResponse.isError) return <ApiErrorModal response={tutorialResponse} />

  const topicsOptions = getTopicsOptions(tutorialResponse.data)

  return (
    <div className='px-5 pt-4' style={{ display: 'flex', flexDirection: 'column' }} >

      {updateTutorialRes.isSuccess && <SuccessModal label={"Updated!"} message={"Tutorial has been updated."} to={{ link: "/tutorials", text: "Go to Tutorials" }} />}
      {updateTutorialRes.isError && <ApiErrorModal response={updateTutorialRes} />}
      <form className="" onSubmit={onSubmit} noValidate>

        <div className='d-flex  flex-column justify-content-between align-items-start mb-5'>
          {/* <h5 className='fw-bold  text-danger'>Tutorials</h5> */}

          <div className='d-flex justify-content-between w-100'>

            <div>
              <div className='d-flex mb-3'>
                <Input name={'Tutorial Name'} inputClass={inputClass} defaultValue={tutorialResById.data.title} required />
                <Input name={'Avg Completion Time'} inputClass={inputClass} defaultValue={tutorialResById.data.avgCompletionTime} />
                {/* <Input type={'date'} name={'Release Date'} inputClass={inputClass} defaultValue={tutorialResById.data.releaseDate ? new Date(tutorialResById.data.releaseDate).toISOString().split('T')[0] : null} /> */}
                <Input type={'date'} name={'Release Date'} inputClass={inputClass} defaultValue={
                  tutorialResById && tutorialResById.data && tutorialResById.data.releaseDate
                    ? new Date(tutorialResById.data.releaseDate).toISOString().split('T')[0]
                    : null
                } />

              </div>

              <div className='d-flex'>
                <Select
                  name={'Section'}
                  lableName={' '}
                  inputClass={inputClass}
                  options={Sections.SECTIONS_DATA.map((item) => item.name)}
                  value={selectedSection}
                  onChange={(e) => setSelectedSection(e.target.value)}

                />
                <Select
                  name={'Book'}
                  lableName={' '}
                  inputClass={inputClass}
                  options={booksOptions}
                  value={selectedBook}
                  onChange={(e) => handleBookChange(e.target.value)}
                  required
                />

                <Select
                  name={'Language'}
                  lableName={' '}
                  inputClass={inputClass}
                  options={[
                    'ENGLISH', 'HINDI', 'GUJRATI'
                  ]}
                  value={selectedLanguage}
                  onChange={(e) => setSelectedLanguage(e.target.value)}
                  required
                />

                {
                  !selectedTopicCheck && topicsOptions.length > 0 &&
                  <Select name={'Topic'} options={topicsOptions} inputClass={inputClass} required />
                }
                {
                  (selectedTopicCheck || topicsOptions.length === 0) &&
                  <Input name={'Topic'} inputClass={inputClass} />
                }
                {
                  topicsOptions.length > 0 &&
                  <div className='mx-3'>
                    <input type="checkbox" value="" id="topicCheck" onChange={handleCheckOnChange} />
                    <label htmlFor="topicCheck" className='mx-3'>
                      Add Topic
                    </label>
                  </div>
                }
              </div>
            </div>

            <div className="form-check form-switch me-4 d-flex flex-column">

              <div>
                <input className="form-check-input" type="checkbox" role="switch" id="toggle" onChange={handleCurrentTutorialsCheckboxChange} checked={isCurrentTutorialsChecked} />
                <label className="form-check-label fw-bold" htmlFor="toggle">Current Tutorials</label>
              </div>

              <div>
                <input className="form-check-input" type="checkbox" role="switch" id="toggle2" onChange={handleAcceptingAssignmentsCheckboxChange} checked={isAcceptingAssignmentsChecked} />
                <label className="form-check-label fw-bold" htmlFor="toggle2">Accepting Assingments</label>
              </div>

              <div>
                <input className="form-check-input" type="checkbox" role="switch" id="toggle3" onChange={handlePublishInUserAppCheckboxChange} checked={isPublishInUserAppChecked} />
                <label className="form-check-label fw-bold" htmlFor="toggle3">Publish in user app</label>
              </div>

            </div>

          </div>

        </div>


        <div className='card rounded-5 overflow-hidden h-100 shadow' style={{ height: '500px', overflow: 'hidden' }}>
          <div className="d-flex justify-content-between">
            <div className={selectedTab === "Overview" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Overview")}>Overview</div>
            <div className={selectedTab === "Tutorial" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Tutorial")}>Tutorial</div>
            <div className={selectedTab === "ICGS Manual" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("ICGS Manual")}>ICGS Manual</div>
            <div className={selectedTab === "Activity" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Activity")}>Activity</div>
            <div className={selectedTab === "Material" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Material")}>Material</div>
            <div className={selectedTab === "Suggestions" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Suggestions")}>Suggestions</div>
            <div className={selectedTab === "Assignment" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Assignment")}>Assignment</div>
            <div className={selectedTab === "Submission" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Submission")}>Submission</div>
          </div>

          <div className={(selectedTab === "Overview" && selectedLanguage === "ENGLISH") ? "d-block" : "d-none"} style={{ overflow: 'auto', maxHeight: '100%' }}>
            <FormattedInput label={' '} name={"Overview"} defaultValue={tutorialResById.data.overview} />
          </div>

          <div className={(selectedTab === "Tutorial" && selectedLanguage === "ENGLISH") ? "d-block" : "d-none"} style={{ overflow: 'auto', maxHeight: '100%' }}>
            <FormattedInput label={' '} name={"Tutorial"} defaultValue={tutorialResById.data.tutorial} />
          </div>

          <div className={(selectedTab === "ICGS Manual" && selectedLanguage === "ENGLISH") ? "d-block" : "d-none"}><FormattedInput label={' '} name={"ICGS Manual"} defaultValue={tutorialResById.data.manual} /></div>
          <div className={(selectedTab === "Activity" && selectedLanguage === "ENGLISH") ? "d-block" : "d-none"}><FormattedInput label={' '} name={"Activity"} defaultValue={tutorialResById.data.activity} /></div>
          <div className={(selectedTab === "Material" && selectedLanguage === "ENGLISH") ? "d-block" : "d-none"}><FormattedInput label={' '} name={"Material"} defaultValue={tutorialResById.data.material} /></div>



          <div className={(selectedTab === "Overview" && selectedLanguage === "HINDI") ? "d-block" : "d-none"} style={{ overflow: 'auto', maxHeight: '100%' }}>
            <FormattedInput label={' '} name={"Overview Hindi"} defaultValue={tutorialResById.data.overviewHindi} />
          </div>

          <div className={(selectedTab === "Tutorial" && selectedLanguage === "HINDI") ? "d-block" : "d-none"} style={{ overflow: 'auto', maxHeight: '100%' }}>
            <FormattedInput label={' '} name={"Tutorial Hindi"} defaultValue={tutorialResById.data.tutorialHindi} />
          </div>

          <div className={(selectedTab === "ICGS Manual" && selectedLanguage === "HINDI") ? "d-block" : "d-none"}><FormattedInput label={' '} name={"ICGS Manual Hindi"} defaultValue={tutorialResById.data.manualHindi} /></div>
          <div className={(selectedTab === "Activity" && selectedLanguage === "HINDI") ? "d-block" : "d-none"}><FormattedInput label={' '} name={"Activity Hindi"} defaultValue={tutorialResById.data.activityHindi} /></div>
          <div className={(selectedTab === "Material" && selectedLanguage === "HINDI") ? "d-block" : "d-none"}><FormattedInput label={' '} name={"Material Hindi"} defaultValue={tutorialResById.data.materialHindi} /></div>


          {/* //==========? */}


          <div className={(selectedTab === "Overview" && selectedLanguage === "GUJRATI") ? "d-block" : "d-none"} style={{ overflow: 'auto', maxHeight: '100%' }}>
            <FormattedInput label={' '} name={"Overview Gujrati"} defaultValue={tutorialResById.data.overviewGujrati} />
          </div>

          <div className={(selectedTab === "Tutorial" && selectedLanguage === "GUJRATI") ? "d-block" : "d-none"} style={{ overflow: 'auto', maxHeight: '100%' }}>
            <FormattedInput label={' '} name={"Tutorial Gujrati"} defaultValue={tutorialResById.data.tutorialGujrati} />
          </div>

          <div className={(selectedTab === "ICGS Manual" && selectedLanguage === "GUJRATI") ? "d-block" : "d-none"}><FormattedInput label={' '} name={"ICGS Manual Gujrati"} defaultValue={tutorialResById.data.manualGujrati} /></div>
          <div className={(selectedTab === "Activity" && selectedLanguage === "GUJRATI") ? "d-block" : "d-none"}><FormattedInput label={' '} name={"Activity Gujrati"} defaultValue={tutorialResById.data.activityGujrati} /></div>
          <div className={(selectedTab === "Material" && selectedLanguage === "GUJRATI") ? "d-block" : "d-none"}><FormattedInput label={' '} name={"Material Gujrati"} defaultValue={tutorialResById.data.materialGujrati} /></div>














          <div className={selectedTab === "Suggestions" ? "d-block  suggestions-container" : "d-none"} style={{ height: '340px', overflow: 'auto' }}>
            {
              tutorialResById.data.suggestions.map((item) => {
                const imageUrl = `${Api.IMAGE_URL}${item.image?.id}${item.image?.ext}`;
                console.log(item.content);

                return (
                  <div className='bg-info mb-3 p-4'>
                    <div>userId : {item.userId}</div>
                    <div dangerouslySetInnerHTML={{ __html: item.content }} ></div>
                    {item.image && <img src={imageUrl} className='w-25 h-25 mt-2 rounded' alt="Image" />}
                  </div>
                )
              })
            }
          </div>



          <div className={selectedTab === "Assignment" ? "d-block" : "d-none"}>
            <FormattedInput label={' '} name={"Assignment"} defaultValue={tutorialResById.data.assignment}>
              <div className='m-3'>
                <Input name={'Assign Title'} inputClass={inputClass} defaultValue={tutorialResById.data.pageTitle} />
              </div>
            </FormattedInput>
            <div className="div m-3">
              {/* <UploadImage /> */}

              {
                !image && <img src={`${Api.IMAGE_URL}${tutorialResById?.data?.image?.id}${tutorialResById?.data?.image?.ext}`} className='w-25 h-25 mt-2  rounded' alt="Image" />

              }
            </div>
            <Cropper id={4} image={image} setImage={setImage} aspectRatio={3 / 2} label={'Add cover'} containerClass={'w-25 ms-3 mt-2'} />

          </div>


          <div className={selectedTab === "Submission" ? "d-block" : "d-none"} style={{ height: '340px', overflow: 'auto' }}>
            {
              tutorialResById?.data?.submissions?.map((item) => {

                // const imageUrl = `${Api.IMAGE_URL}${item.image?.id}${item.image?.ext}`;
                return (
                  <div className='bg-info mb-3 p-4'>

                    <button className='btn btn-danger float-end' type='button' onClick={() => handleSubDelete(item.id)}>delete</button>
                    <div className='fw-bold'>USER ID : {item.userId}</div>
                   
                    <div className=' row g-2 mt-3 '>
                                {
                                    item.submissionFiles
                                        .filter((item) => {
                                            if (item.ext === '.jpg' || item.ext === '.jpeg' || item.ext === '.png' || item.ext === '.gif') {
                                                return true
                                            }
                                            else {
                                                return false;
                                            }
                                        })
                                        .map((item) => (

                                            <>
                                                <img src={Api.BASE_URL + `uploads/submitImages/${item?.id}${item?.ext}`} className='rounded col-3' alt="" style={{ width: 300, height: 300 }} />

                                            </>
                                        ))

                                }

                            </div >

                          <div className=" my-4">
                                {
                                    item.submissionFiles
                                        .filter((item) => {
                                            if (item.ext === '.mp3' || item.ext === '.wav') {
                                                return true
                                            }
                                            else {
                                                return false;
                                            }
                                        })
                                        .map((item) => (
                                            <>
                                                <audio key={item.id} controls><source src={Api.BASE_URL + `uploads/audio/${item?.id}${item?.ext}`} type="audio/mpeg" /></audio>

                                            </>))

                                }
                            </div> 

                    <div className='mt-3' dangerouslySetInnerHTML={{ __html: item.content }} ></div>
                    {/* {
                      item.image && <img src={imageUrl} className='w-25 h-25 mt-2  rounded' alt="Image" />

                    } */}
                  </div>
                )
              })
            }

            {
              tutorialResById?.data?.answers?.map((item) => {

                // const imageUrl = `${Api.IMAGE_URL}${item.image?.id}${item.image?.ext}`;
                return (
                  <div className='bg-info mb-3 p-4'>
                    {
                      console.log("eeeeee", item.images)
                    }
                    <div>USER ID : {item.userId}</div>
                    <div className="fw-bold">Ans1. {item.ansOne}</div>


                    <div className="fw-bold">Ans2. {item.ansTwo}</div>


                    <div className="fw-bold">Ans3. {item.ansThree}</div>

                    {
                      item?.images?.map((item2 => <img src={Api.BASE_URL + Api.SUBMIT_IMAGES + item2.id + item2.ext} alt="" className='me-4 my-3' width={400} />))
                    }
                  </div>
                )
              })
            }


          </div>

        </div>

        <Button
          className='col-2 btn btn-primary text-white rounded-pill text-nowrap my-3'
          res={updateTutorialRes}
          loadingLabel={"Updating"}
        >
          Update
        </Button>
      </form>

    </div>
  )
}

export default EditTutorial