import React from 'react'
import { useGetTutorialsQuery } from '../../services/tutorialSlice';
import Loading from '../../components/ui/Loading';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import { Link, useParams } from 'react-router-dom';
import Icons from '../../components/ui/Icons';
import { useGetAssignmentByIdQuery, useGetAssignmentsQuery } from '../../services/assignmentSlice';
import SubmissionViewModal from '../../components/modals/SubmissionViewModal';

const AllSubmissions = () => {

    const { id } = useParams()

    const response = useGetAssignmentByIdQuery(id);

    if (response.isLoading) return <Loading />
    if (response.isError) return <ApiErrorModal response={response} />

    return (

        <>
            <div className="bg-white position-sticky sticky-top p-4 shadow">
                <h3 className="fw-bold mb-2">Submission <span className="text-danger">For</span></h3>
                <p className='fw-bold fs-5'>{response.data.assignmentName}</p>
            </div>
            <div className='py-3 px-5 '>

                {
                    response.data.submissions.length > 0 && (
                        <div>
                            {/* <h3 className='fw-bold mt-5'>Submissions for Assignment - {response.data.assignmentName}</h3> */}
                            <div className="">
                                <div className="row  row-cols-2 ">
                                    {
                                        response.data.submissions.map((submission,i) =>
                                            <div className="" key={i}>
                                                <div key={submission.id} className='  d-flex flex-row justify-content-between card shadow rounded-4 border-0 my-3 p-3  '>


                                                    <div className="">
                                                        <div className=''>Assignment Submitted By</div>
                                                        <div className='fw-bold'>{submission.userName}</div>
                                                    </div>
                                                    {/* <div className='fw-bold'>Assignment Name - {item.assignmentName}</div> */}
                                                    {/* <div className='fw-bold'>Submission ID - {submission.id}</div>
                                                <div className='fw-bold'>User ID - {submission.userId}</div> */}
                                                    {/* <div dangerouslySetInnerHTML={{ __html: submission.content }}></div> */}
{
    console.log("eeeeeeeeeew",submission)
}

                                                    <SubmissionViewModal id={submission.id} submission={submission} content={submission.content} />
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>


                        </div>
                    )
                }
            </div>
        </>
    )
}

export default AllSubmissions



