import React from 'react'
import Loading from '../../components/ui/Loading';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import { Link } from 'react-router-dom';
import Icons from '../../components/ui/Icons';
import { useGetTutorialsQuery, useUpdateTutorialByIdMutation } from '../../services/tutorialSlice';

const TutorialAssignments = () => {

    const response = useGetTutorialsQuery();

    const [updateAssignment, updateAssignmentRes] = useUpdateTutorialByIdMutation()


    const handleToggleUpdate = (item) => {



        let body = { acceptingAssignments: !item.acceptingAssignments }
        updateAssignment({ id: item.id, body })
            .then(() => response.refetch())
    }



    if (response.isLoading) return <Loading />
    if (response.isError) return <ApiErrorModal response={response} />

    return (
        <div className=''>
            {response.isSuccess &&
                <>
                    <div className='bg-white position-sticky sticky-top p-4 shadow'>
                        <h3 className='fw-bold'>Tutorial <span className='text-danger'>Assignments</span></h3>
                        <p>Here you can find assignments that are associated with any tutorial. Manage all the tutorial assignments right here.</p>
                    </div>
                    <div className="px-5">
                        {response.data.map(item =>
                            <div className='card shadow rounded-4 border-0 my-3 p-3 flex-row justify-content-between align-items-center'>
                                <div>
                                    <p className='fw-bold'>
                                        {
                                            ((new DOMParser().parseFromString(item?.pageTitle || '', 'text/html').body.textContent || '').replace(/(\r\n|\n|\r)/gm, ' ').substring(0, 50) + (item?.pageTitle?.length > 50 ? '...' : '')).trim()
                                        }
                                    </p>
                                    <p className='fs-13 text-muted'>{`${item.section} > ${item.books} > ${item.topic}`}</p>
                                    {/* <div dangerouslySetInnerHTML={{ __html: item.assignment }}></div> */}
                                </div>

                                <div className='d-flex align-items-center'>
                                    <div className="form-check form-switch fs-3 me-4">
                                        <input className="form-check-input" type="checkbox" role="switch" id="toggle2" checked={item.acceptingAssignments} onChange={() => handleToggleUpdate(item)} />
                                    </div>
                                    <Link className='btn btn-outline-primary border-0 p-0 rounded-circle text-hover-white' to={`/tutorials/${item.id}`}>{Icons.arrow()}</Link>
                                </div>

                            </div>
                        )}

                    </div>

                </>
            }
        </div>
    )
}

export default TutorialAssignments