import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useGetNoticeByIdQuery, useGetNoticesQuery } from '../../services/noticeSlice'
import { useGetBranchesQuery } from '../../services/branchSlice'
import ResponsiveContainerCard from '../ui/ResponsiveContainerCard'
import { useGetTutorialsQuery } from '../../services/tutorialSlice'
import Api from '../../constants/Api'
import firstImg from '../../assets/images/first.png'
import secondImg from '../../assets/images/second.png'
import thirdImg from '../../assets/images/third.png'

const ViewBranchModal = ({ releasedTutorials }) => {

    const modalId = "ViewBranchModal"
    const branchRes = useGetBranchesQuery()
    const tutorialRes = useGetTutorialsQuery()
    const backgroundColor = '#04143E'

    const headers = ["SNo", "Gurukul Branch", "Main Branch", "Status", "Total Tutors", "Tutorials Taught", "Untaught Tutorials", "Rankings"]

    const getTableHead = () => (
        <thead>
            <tr>
                {headers.map((header) => (
                    <th key={header} className='text-white sticky-top' style={{ backgroundColor }}>
                        {header}
                    </th>
                ))}
            </tr>
        </thead>
    );

    const calculateTutorials = (branchId, users) => {
        const taughtTutorials = releasedTutorials.filter((tutorial) => {
            return users.some((user) => tutorial.markAsReads.some((read) => read.userId == user.id));
        });

        const untaughtTutorials = releasedTutorials.filter((tutorial) => {
            return !users.some((user) => tutorial.markAsReads.some((read) => read.userId == user.id));
        });

        return {
            taughtTutorials: taughtTutorials.length,
            untaughtTutorials: untaughtTutorials.length,
        };
    };


    // Create an array of branch objects with performance metrics
    const branchPerformances = branchRes?.data?.map((data) => {
        const tutorials = calculateTutorials(data.id, data.users);
        let bname = branchRes?.data?.find((item) => item.id == data.mainBranchId)?.branchName
        return {
            branchId: data.id,
            branchName: data.branchName,
            mainBranch: bname,
            status: data.status,
            totalTutors: data.users.length,
            taughtTutorials: tutorials.taughtTutorials,
            untaughtTutorials: tutorials.untaughtTutorials,
            profilePicture: {
                id: data?.profilePicture?.id,
                ext: data?.profilePicture?.ext
            }
        };
    });


    const sortedBranches = branchPerformances?.sort((a, b) => b.taughtTutorials - a.taughtTutorials);




    return (
        <>
            {/* <Link type="button" className="text-danger fw-bold" data-bs-toggle="modal" data-bs-target={"#" + modalId}>
                See Now
            </Link> */}


            <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered   modal-xl">
                    <div className="modal-content h-25 ">
                        <div className="modal-header border-0 m-0 pb-0">
                            <h1 className="modal-title fs-5" id={modalId + "Label"}>{branchRes.data?.title}</h1>
                            <button id={modalId + "CloseBtn"} type="button" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                        <div className="modal-body  " style={{height:600}}>
                            <ResponsiveContainerCard
                                titleBar={() =>
                                    <div className="d-flex align-items-center  py-3 px-4">
                                        <div className='me-auto ms-5 fs-2 fw-bold'>
                                            <span className='text-danger'>Branch</span> Performance Report
                                        </div>

                                    </div>
                                }
                            >
                                <table className="table  overflow-y-auto">
                                    {getTableHead()}
                                    <tbody className=''>
                                        {sortedBranches?.map((branch, index) => (
                                            <tr key={index}>

                                                {/* <td>{index + 1}</td> */}
                                                <td>   <img src={Api.SUPERADMIN_URL + `uploads/images/profilePictures/${branch?.profilePicture?.id}${branch?.profilePicture?.ext}`} className='rounded-circle' alt="" style={{ width: 30, height: 30 }} /></td>
                                                <td>{branch.branchName}</td>
                                                <td>{branch.mainBranch}</td>
                                                <td> <span className={branch?.status == 'Active' ? 'bg-success px-2 py-1 text-white rounded-pill' : 'bg-danger px-2 py-1 text-white rounded-pill'}>{branch.status}</span></td>
                                                <td><div>{branch.totalTutors}</div> <span> Active Tutors</span></td>
                                                <td><div>{branch.taughtTutorials}</div> <span> of {releasedTutorials.length || 0} release</span></td>
                                                <td><div>{branch.untaughtTutorials}</div> <span> of {releasedTutorials.length || 0} release</span></td>
                                                <td>
                                                    {index === 0 && <img src={firstImg} alt="" />}
                                                    {index === 1 && <img src={secondImg} alt="" />}
                                                    {index === 2 && <img src={thirdImg} alt="" />}
                                                    {index > 2 && index + 1}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </ResponsiveContainerCard>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewBranchModal