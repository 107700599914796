import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAbout: builder.query({ query: () => `aboutSection`, keepUnusedDataFor: 0 }),
        getAboutById: builder.query({ query: (id) => `aboutSection?id=${id}`, keepUnusedDataFor: 0 }),
        createAbout: builder.mutation({ query: (body) => ({ url: `aboutSection`, method: 'POST', body }) }),
        updateAbout: builder.mutation({ query: ({ id, body }) => ({ url: `aboutSection?id=${id}`, method: 'PATCH', body }) }),
        deleteAbout: builder.mutation({ query: (id) => ({ url: `aboutSection?id=${id}`, method: 'DELETE' }) }),
    })
})

export const {
    useGetAboutQuery,
    useGetAboutByIdQuery,
    useCreateAboutMutation,
    useUpdateAboutMutation,
    useDeleteAboutMutation
} = extendedApiSlice
