import React, { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

import { useDispatch, useSelector } from 'react-redux';
import { setAuthToken } from '../../redux/authSlice';
import { useRefreshTokenMutation } from '../../services/supportSlice';

const CheckAndRefreshToken = () => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.authToken);
    const [refreshToken, refreshTokenRes] = useRefreshTokenMutation();

    const updateToken = async () => {
        if (token) {
            const decodedToken = jwtDecode(token);

            const currentTime = Math.floor(Date.now() / 1000);

            if (decodedToken.exp < currentTime + 90) {
                try {
                    console.log("Refreshing token...");
                    const response = await refreshToken();

                    if (response.data) {
                        console.log("Token Refreshed Successfully");
                        dispatch(setAuthToken(response.data.token));
                    }
                } catch (error) {
                    console.error('Error refreshing token:', error);
                }
            } else {
                console.log("Token is not about to expire. No refresh needed.");
            }
        }
    };


    useEffect(() => {
        const intervalId = setInterval(() => {
            updateToken();
        }, 1000 * 60 * 2);
        return () => clearInterval(intervalId);
    }, []);



    return <></>;
};

export default CheckAndRefreshToken;
