import React, { useEffect } from 'react'
import danger from '../../assets/images/danger.png'
import srgs from '../../assets/images/srgs.png'

const ErrorModal = ({ label, message }) => {

    const renderMessage = typeof message == "string" ? message : (typeof message?.message == "string" ? message?.message : "Something went wrong")

    useEffect(() => {
        document.getElementById("errorModalButton")?.click()
    }, [])

    return (
        <>
            <button type="button" id="errorModalButton" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#errorComponentModal">
                Launch demo modal
            </button>

            <div className="modal fade text-dark" id="errorComponentModal" tabIndex="-1" aria-labelledby="errorComponentModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg-white rounded-4">
                        <div className="alert alert-danger mb-0 border border-5 border-danger rounded-4 ">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-center text-dark">
                                <div className="mb-3">
                                    <img className="mb-3" src={srgs} alt="Danger" width={125} />
                                    <h4>SGRS Utility digital assistance</h4>
                                </div>
                                <img className="" src={danger} alt="Danger" width={100} />
                                <h1 className="modal-title" id="errorComponentModalLabel">{label}</h1>
                                <p className='mb-0'>{renderMessage}</p>

                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-light rounded-pill px-4 shadow-sm text-white" style={{ backgroundColor: "#FF7C66" }} data-bs-dismiss="modal">Close</button>
                                {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ErrorModal