import { Route, Routes, } from 'react-router-dom';
import { useEffect } from 'react';


import "./assets/scss/bootstrap.scss"
import "./assets/css/global.css"
import "./assets/css/fonts.css"
import "./assets/css/scrollbar.css"
import "./assets/css/settings.css"

import Layout from './components/layout/Layout';
import Dashboard from './pages/Dashboard';
import SandhyaSabha from './pages/sandhyaSabha/SandhyaSabha';
import Assignments from './pages/assignments/Assignments';
import CourseBooks from './pages/courseBooks/CourseBooks';
import Training from './pages/training/Training';
import Info from './pages/info/Info';
import Downloadables from './pages/downloadables/Downloadables';
import Support from './pages/support/Support';
import ManageUsers from './pages/mangeUser/ManageUsers';
import Create from './pages/mangeUser/Create';
import CreateAssignment from './pages/assignments/CreateAssignment';
import CreateCategory from './pages/courseBooks/CreateCategory';
import CreateBook from './pages/courseBooks/CreateBook';
import Tutorials from './pages/tutorials/Tutorials';
import CreateTutorial from './pages/tutorials/CreateTutorial';
import EditBook from './pages/courseBooks/EditBook';
import Sessions from './pages/training/Sessions';
import CreateDownloadables from './pages/downloadables/CreateDownloadables';
import { useSelector } from 'react-redux';
import EditTutorial from './pages/tutorials/EditTutorial';
import EditAssignment from './pages/assignments/EditAssignment';
import EditCategory from './pages/courseBooks/EditCategory';
import EditDownloadables from './pages/downloadables/EditDownloadables';
import SpecialAssignments from './pages/assignments/SpecialAssignments';
import Settings2 from './pages/setting/Settings2';
import CreateInfo from './pages/info/CreateInfo';
import EditInfo from './pages/info/EditInfo';
import CreateNotice from './pages/notice/CreateNotice';
import EditNotice from './pages/notice/EditNotice';
import TutorialAssignments from './pages/assignments/TutorialAssignments';
import Submissions from './pages/assignments/Submissions';
import CurrentTutorials from './pages/tutorials/CurrentTutorials';
import TutorialThisWeek from './pages/tutorials/TutorialThisWeek';
import IcgsPinwheel from './pages/icgsPinwheel/IcgsPinwheel';
import ViewBook from './pages/icgsPinwheel/ViewBook';
import ViewSection from './pages/icgsPinwheel/ViewSection';
import AllSubmissions from './pages/assignments/AllSubmissions';
import Notices from './pages/notice/Notices';
import SettingsV1 from './pages/setting/SettingsV1';
import SingleSignOn from './pages/signIn/SingleSignOn';
import SignIn from './pages/signIn/SignIn';
import SignInByToken from './pages/signIn/SignInByToken';
import SignInHandler from './pages/signIn/SignInHandler';

function App() {

  const isSignedIn = useSelector(state => state.auth.authToken);

  useEffect(() => { import('bootstrap') }, [])

  return (

    <Routes>

      {
        isSignedIn
          ?
          <Route path='/' element={<Layout />}>

            <Route index element={<Dashboard />} />
            <Route path='singleSignOn' element={<SingleSignOn />} />
            <Route path='token/:token' element={<SignInByToken />} />

            <Route path='sandhyaSabha' element={<SandhyaSabha />} />

            <Route path='assignments' >
              <Route index element={<Assignments />} />
              <Route path='create' element={<CreateAssignment />} />
              <Route path='special'>
                <Route index element={<SpecialAssignments />} />
                <Route path=':id' element={<EditAssignment />} />
              </Route>
              <Route path='tutorial'>
                <Route index element={<TutorialAssignments />} />
                {/* <Route path=':id' element={<EditAssignment />} /> */}
              </Route>
              <Route path='submissions'>
                <Route index element={<Submissions />} />
                <Route path=':id' element={<AllSubmissions />} />
              </Route>
            </Route>

            <Route path='tutorials' >
              <Route index element={<Tutorials />} />
              <Route path='create' element={<CreateTutorial />} />
              <Route path=':id' element={<EditTutorial />} />
              <Route path='current' element={<CurrentTutorials />} />
              <Route path='thisWeek' element={<TutorialThisWeek />} />
            </Route>

            <Route path='coursebooks' >
              <Route index element={<CourseBooks />} />
              <Route path='create' element={<CreateBook />} />
              <Route path=':id' element={<CourseBooks />} />
              <Route path='edit'>
                <Route path=':id' element={<EditBook />} />
              </Route>
              <Route path='createCategory' element={<CreateCategory />} />
              <Route path='editCategory'>
                <Route path=':id' element={<EditCategory />} />
              </Route>
            </Route>

            <Route path='support' >
              <Route index element={<Support />} />
            </Route>

            <Route path='downloadables' >
              <Route index element={<Downloadables />} />
              <Route path='create' element={<CreateDownloadables />} />
              <Route path=':id' element={<EditDownloadables />} />
            </Route>

            <Route path='training'>
              <Route index element={<Training />} />
              <Route path=':id' element={<Sessions />} />
            </Route>

            <Route path='icgsPinwheel'>
              <Route index element={<IcgsPinwheel />} />
              <Route path=':section/:book' element={<ViewBook />} />
              <Route path=':section'>
                <Route index element={<ViewSection />} />
                <Route path=':book' element={<ViewBook />} />
              </Route>
              {/* <Route path='vidhya'>
                <Route index element={<Vidhya />} />
                <Route path=':id' element={<ViewBook />} />
              </Route>
              <Route path='sadvidhya'>
                <Route index element={<Brahmvidhya />} />
                <Route path=':id' element={<ViewBook />} />
              </Route>
              <Route path='brahmvidhya'>
                <Route index element={<Charitram />} />
                <Route path=':id' element={<ViewBook />} />
              </Route>
              <Route path='charitram'>
                <Route index element={<Vidhya />} />
                <Route path=':id' element={<ViewBook />} />
              </Route> */}
            </Route>

            <Route path='info' >
              <Route index element={<Info />} />
              <Route path='create' element={<CreateInfo />} />
              <Route path=':id' element={<EditInfo />} />
            </Route>

            <Route path='notice' >
              <Route index element={<Notices />} />

              <Route path='create' element={<CreateNotice />} />
              <Route path=':id' element={<EditNotice />} />
            </Route>

            <Route path='settingV1' element={<SettingsV1 />} />
            <Route path='settings' element={<Settings2 />} />

            <Route path='manageUsers' >
              <Route index element={<ManageUsers />} />
              <Route path='create' element={<Create />} />
            </Route>

            <Route path='*' element={<Dashboard />} />

          </Route>
          :
          <Route path='/'>
            <Route index element={<SignIn />} />
            <Route path='token/:token' element={<SignInByToken />} />
            <Route path='*' element={<SignInHandler />} />
          </Route>
      }

    </Routes>


  );

}

export default App;