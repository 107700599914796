import React, { useState } from 'react'
import Input from '../../components/form/Input'
import { useCreateAssignmentMutation } from '../../services/assignmentSlice'
import SuccessModal from '../../components/modals/SuccessModal';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import Button from '../../components/form/Button';
import FormattedInput from '../../components/form/FormattedInput';
import Cropper from '../../components/Cropper';

const CreateAssignment = () => {

    const [createAssignment, createAssignmentRes] = useCreateAssignmentMutation();
    const [image, setImage] = useState(null);

    const onSubmit = (event) => {
        event.preventDefault();
        const form = event.target;

        if (form.checkValidity()) {
            const formData = new FormData();

            formData.append('assignmentName', form['Assignment Name'].value);
            formData.append('subtitle', form['Subtitle'].value);
            formData.append('content', form['Content'].value);
            // formData.append('pageTitle', form['Assig Title'].value);
            formData.append('pageTitle', ' ');
            if (image) formData.append('image', image);

            createAssignment(formData)
                .then((res) => {
                    if (!res.error) {
                        form.reset();
                        setImage(null)
                        form.classList.remove('was-validated');
                    }
                })
                .catch((error) => {
                    console.error('Error creating assignment:', error);
                });
        } else {
            form.classList.add('was-validated');
        }
    };



    return (
        <div className='py-3 px-5'>

            {createAssignmentRes.isSuccess && <SuccessModal label={"Created!"} message={"Assignment has been created."} to={{ link: "/assignments/special", text: "Go to Special Assignments" }} closeTo={"/assignments"} />}
            {createAssignmentRes.isError && <ApiErrorModal response={createAssignmentRes} />}

            <h2 className='fw-bold text-danger mb-1'>Assignments</h2>
            <p className='text-muted'>This page offers a quick view of all your assignments, making it easy to stay organized and meet deadlines.</p>

            <div className='px-4 py-2 bg-white rounded-5 shadow my-5'>

                <form className="" onSubmit={onSubmit} noValidate>
                    <Input name={'Assignment Name'} containerClass={'my-5'} required />
                    <Input name={'Subtitle'} containerClass={'my-5'} required />
                    {/* <Input name={'Assig Title'} containerClass={'my-5'} required /> */}
                    <FormattedInput height={400} name={"Content"} />

                    <div className=" mt-4" style={{}}>
                        {/* <FormattedInput height={300} name={'Assig Title'} /> */}
                    </div>

                
                    <div>
                        <Cropper id={4} image={image} setImage={setImage} aspectRatio={3 / 2} label={'Add cover'} containerClass={'w-25'} />
                    </div>

                    <Button
                        className='col-2 btn btn-primary text-white rounded-pill text-nowrap my-3'
                        res={createAssignmentRes}
                        loadingLabel={"Creating"}
                    >
                        Create
                    </Button>
                </form>

            </div>

        </div>
    )
}

export default CreateAssignment