import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../form/Button'
import { getApiErrorMessage } from '../../../utility/errorMessages'
import { useDeleteCategoryMutation } from '../../../services/categorySlice'
import Icons from '../../ui/Icons'

const CategoryDeleteModal = ({ id, refetch, refetch2 }) => {

    const modalId = "categoryDeleteModal" + id
    const navigate = useNavigate()
    const [deleteCategory, deleteCategoryRes] = useDeleteCategoryMutation()

    const onDelete = () => {
        deleteCategory(id).then((res) => {
            if (!res.error) {
                document.getElementById(modalId + 'CloseBtn').click()
                refetch()
                refetch2()
            }
        })
    }

    return (
        <>
            <button type="button" className="btn btn-primary px-3 rounded-pill" data-bs-toggle="modal" data-bs-target={"#" + modalId}>
                Delete
            </button>

            <div className="modal fade" id={modalId} tabindex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id={modalId + "Label"}>Category Delete</h1>
                            <button id={modalId + "CloseBtn"} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Do you really want to delete this category?
                        </div>
                        <div className="modal-footer">
                            {deleteCategoryRes.isError &&
                                <div className='me-auto fw-bold text-danger'>
                                    {getApiErrorMessage(deleteCategoryRes).label}: {getApiErrorMessage(deleteCategoryRes).message}
                                </div>
                            }
                            <button type="button" className="btn btn-success text-white" data-bs-dismiss="modal">No</button>
                            <Button
                                className="btn btn-danger text-white"
                                type="button"
                                onClick={onDelete}
                                res={deleteCategoryRes}
                                loadingLabel={"Deleting"}
                            >
                                Yes
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategoryDeleteModal