import React, { useEffect, useRef } from 'react'
import Input from '../../components/form/Input'
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateBookMutation, useCreateChapterMutation, useDeleteBookMutation, useGetBookByIdQuery, useUpdateBookMutation } from '../../services/bookSlice';
import { useState } from 'react';
import CreateChapter from './CreateChapter';
import ChapterDeleteModal from '../../components/modals/deleteModals/ChapterDeleteModal';
import Loading from '../../components/ui/Loading';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import Button from '../../components/form/Button';
import { useGetCategoriesQuery } from '../../services/categorySlice';
import SuccessModal from '../../components/modals/SuccessModal';
import EditChapterNameModal from '../../components/modals/EditChapterNameModal';
import Api from '../../constants/Api';
import Cropper from '../../components/Cropper';
import BookDeleteModal from '../../components/modals/deleteModals/BookDeleteModal';

const EditBook = () => {

    const { id } = useParams();

    const [selectedTab, setSelectedTab] = useState(null);
    const activeTabClassName = "flex-grow-1 bg-light p-4 pe-5 text-center border-end border-5 border-danger fw-bold";
    const inactiveTabClassName = "flex-grow-1 bg-primary p-4 pe-5 text-center text-white";

    const [updateBook, updateBookRes] = useUpdateBookMutation();
    const [createChapter, createChapterRes] = useCreateChapterMutation();
    const [image, setImage] = useState(null);
    const [editBookCard, setEditBookCard] = useState(0);
    const bookRes = useGetBookByIdQuery(id);
    const categoryRes = useGetCategoriesQuery()

    const [deleteBook, deleteBookRes] = useDeleteBookMutation()


    const navigate = useNavigate();
    const tabsContainerRef = useRef(null);

    useEffect(() => {
        // Scroll to the selected tab when it changes
        if (tabsContainerRef.current) {
            const selectedTabElement = tabsContainerRef.current.querySelector('.active-tab');
            if (selectedTabElement) {
                selectedTabElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }
    }, [selectedTab]);

    useEffect(() => {
        if (bookRes.isSuccess) {
            setSelectedTab(bookRes.data.chapters.length > 0 ? 0 : null)
        }
    }, [bookRes.isSuccess, bookRes.data])

    useEffect(() => {
        if (bookRes.isFetching) {
            setSelectedTab(null)
        }
    }, [bookRes.isFetching])

    const onSubmit = (event) => {
        event.preventDefault();

        const form = event.target;
        let body = new FormData();
        body.append('name', form['name'].value)
        body.append('authorName', form['authorName'].value)
        body.append('language', form['language'].value)
        // body.append('rating', form['rating'].value)
        body.append('color', form['color'].value)
        body.append('categoryId', form['Category'].value)
        if (image) body.append('images', image)

        updateBook({ id, body })
    }

    const handleCreateChapter = (num) => {
        createChapter({
            name: "Chapter" + num,
            text: "<p>Write your content here...</p>",
            bookId: id,
        })
            .then(() => {
                bookRes.refetch()
            });;
    };

    if (updateBookRes.isLoading || createChapterRes.isLoading || bookRes.isLoading || bookRes.isFetching
        || categoryRes.isLoading || categoryRes.isFetching) return <Loading />

    if (bookRes.isError) return <ApiErrorModal response={bookRes} />
    if (categoryRes.isError) return <ApiErrorModal response={categoryRes} />

    // if (deleteBookRes.isSuccess) return <SuccessModal label={"Deleted!"} message={"Book has been deleted."} to={{ link: "/courseBooks", text: "Go to Course Books" }} closeTo={"/courseBooks"} />

    return (
        <div className='p-5'>


            {updateBookRes.isSuccess && <SuccessModal label={"Updated!"} message={"Book has been updated."} to={{ link: "/courseBooks", text: "Go to Course Books" }} closeTo={"/courseBooks"} />}
            {updateBookRes.isError && <ApiErrorModal response={updateBookRes} />}

            <div className='d-flex justify-content-center flex-column '>
                <div className='d-flex justify-content-between align-items-center mb-3 '>
                    <h2 className='fw-bold text-danger mb-2'>Edit Book</h2>
                    <div>
                        <BookDeleteModal id={id} refetch={bookRes.refetch} deleteBook={deleteBook} deleteBookRes={deleteBookRes} />
                        <div className="btn btn-primary rounded-pill px-5 text-center py-2" onClick={() => setEditBookCard(!editBookCard)}>Edit</div>
                    </div>
                </div>

                <div className={editBookCard ? 'p-4 bg-white rounded-5 shadow my-5 w-100' : 'd-none'}>

                    <form className="" onSubmit={onSubmit}>
                        <div className='d-flex'>
                            <label htmlFor="color" className="form-label fw-bold">Background Colour</label>
                            <input type="color" defaultValue={bookRes.data && bookRes.data.color} className="form-control form-control-color mx-auto" name='color' id="color" title="Choose your color" />
                        </div>
                        <Input name={'name'} defaultValue={bookRes.data && bookRes.data.name} label={'Book Name'} containerClass={'my-5'} />
                        <Input name={'authorName'} defaultValue={bookRes.data && bookRes.data.authorName} label={'Author Name'} containerClass={'my-5'} />
                        <Input name={'language'} defaultValue={bookRes.data && bookRes.data.language} label={'Language'} containerClass={'my-5'} />
                        <select name={'Category'} className="form-select" aria-label="Default select example">
                            {categoryRes.data && categoryRes.data?.map(item =>
                                <option key={item.id} value={item.id} selected={item.id === bookRes.data.categoryId ? true : false}>{item.name}</option>
                            )}
                        </select>
                        {
                            (bookRes.data.images?.length > 0 && image === null) &&
                            <img src={Api.IMAGE_URL + bookRes.data.images[0].id + bookRes.data.images[0].ext} alt="" className='me-4 my-3' width={100} />
                        }
                        <Cropper id={5} image={image} setImage={setImage} aspectRatio={2 / 3} label={'Change cover'} containerClass={'mt-5'} />
                        {/* <Input name={'rating'} defaultValue={bookRes.data && bookRes.data.rating} label={'Rating'} containerClass={'my-5'} /> */}
                        <Button
                            className='btn btn-primary text-white rounded-pill text-nowrap my-3'
                            res={updateBookRes}
                            loadingLabel={"Updating"}
                        >
                            Update
                        </Button>
                    </form>

                </div>
            </div>


            <div className='card rounded-5 overflow-hidden flex-row shadow '>
                <div className='tabs-container d-flex flex-column justify-content-between hide-scroll' ref={tabsContainerRef} style={{ height: '650px', overflowY: 'auto' }}>
                    <div className='overflow-auto flex-grow-1'>
                        {bookRes.isSuccess && bookRes.data.chapters.map((tab, index) => (
                            <div
                                key={index}
                                className={selectedTab === index ? activeTabClassName + ' active-tab' : inactiveTabClassName}
                                onClick={() => setSelectedTab(index)}
                            >
                                {tab.name}
                                <EditChapterNameModal id={tab.id} chapters={bookRes.data.chapters} refetch={bookRes.refetch} />
                                <ChapterDeleteModal id={tab.id} refetch={bookRes.refetch} />

                            </div>
                        ))}

                    </div>
                    <button className='btn text-white bg-danger rounded-0 p-3' onClick={() => handleCreateChapter(bookRes.data.chapters.length)}>
                        Create Chapter
                    </button>
                </div>

                {
                    typeof selectedTab === 'number' &&
                    <div className='w-100'>
                        <CreateChapter
                            key={bookRes.data.chapters[selectedTab].id}
                            id={bookRes.data.id}
                            chapter={bookRes.data.chapters[selectedTab]}
                            refetch={bookRes.refetch}
                        />
                    </div>
                }
            </div>

        </div>
    )
}

export default EditBook