import React, { useEffect, useState } from "react";
import poster from "../../assets/images/assignment_poster.png";
import one from "../../assets/images/assignment_one.png";
import two from "../../assets/images/assignment_two.png";
import three from "../../assets/images/assignment_three.png";
import Icons from "../../components/ui/Icons";
import { Link, useNavigate } from "react-router-dom";
import {
  useGetTutorialsQuery,
  useUpdateTutorialByIdMutation,
} from "../../services/tutorialSlice";
import Loading from "../../components/ui/Loading";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import AssignmentDeleteModal from "../../components/modals/deleteModals/AssignmentDeleteModal";
import Api from "../../constants/Api";
import Cropper from "../../components/Cropper";
import SuccessModal from "../../components/modals/SuccessModal";
import EditSlidesModal from "../../components/modals/EditSlidesModal";
import Slider from "../../components/ui/Slider";
import img1 from "../../assets/images/p1.png";
import {
  useGetAssignmentsQuery,
  useUpdateAssignmentMutation,
} from "../../services/assignmentSlice";

const COLOR = {
  BLUE: "#01519B",
  RED: "#A01F1F",
  GREEN: "#1FA024",
  VIOLET: "#79008D",
};

const options = [
  {
    img: one,
    backgroundColor: "#D0DBF8",
    borderColor: "#002993",
    text: "Special Assignments",
    to: "special",
  },
  {
    img: two,
    backgroundColor: "#F3DFEC",
    borderColor: "#7B0051",
    text: "Tutorial Assignments",
    to: "tutorial",
  },
  {
    img: three,
    backgroundColor: "#E5BFF0",
    borderColor: "#840CA6",
    text: "Submissions",
    to: "submissions",
  },
];

const Assignments = () => {
  const tutorialRes = useGetTutorialsQuery();
  const assignmentRes = useGetAssignmentsQuery();

  const [updateTutorial, updateTutorialRes] = useUpdateTutorialByIdMutation();
  const [updateAssignment, updateAssignmentRes] = useUpdateAssignmentMutation();

  const [toggleValue, setToggleValue] = useState();

  const navigate = useNavigate();

  const handleToggleUpdate = (item) => {
    const newStatus = item.status === "Active" ? "Inactive" : "Active";

    let body = { status: newStatus };
    updateAssignment({ id: item.id, body }).then(() => assignmentRes.refetch());
  };

  const handleToggleTutorial = (item) => {
    const newStatus = item.status === "Active" ? "Inactive" : "Active";

    let body = { status: newStatus };
    updateTutorial({ id: item.id, body }).then(() => tutorialRes.refetch());
  };

  if (tutorialRes.isLoading || assignmentRes.isLoading) return <Loading />;
  if (tutorialRes.isError) return <ApiErrorModal response={tutorialRes} />;

  return (
    <div className="py-4 px-5 h-100">

      <div className="row d-flex h-100 ">
        <div className="col-8 position-relative">
          <Slider type="Assignments" />

          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="fw-bold mb-3">
                Manage <span className="text-danger">Assignments</span>
              </h2>
              <div
                className="btn bg-danger text-white rounded-pill px-5"
                onClick={() => navigate("create")}
              >
                Add New Assignment
              </div>
            </div>
            <p className="text-muted">
              This page offers a quick view of all your assignments, making it
              easy to stay organized and meet deadlines.
            </p>
          </div>

          <div className="container">
            <div className="row">
              {options.map((option, i) => (
                <div key={i} className="col">
                  <div
                    className="card rounded-5 border-5 overflow-hidden shadow"
                    style={{ borderColor: option.borderColor }}
                  >
                    <div>
                      <img
                        src={option.img}
                        className="w-100 h-100 object-fit-cover"
                        alt=""
                      />
                    </div>
                  </div>
                  <Link
                    to={option.to}
                    className="card rounded-4 px-3 py-3 text-center shadow border-0 text-decoration-none"
                    style={{
                      backgroundColor: option.backgroundColor,
                      marginTop: -50,
                    }}
                  >
                    {option.text}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="col-4 h-100">
          <div className="py-4 px-3 bg-warning d-flex flex-column overflow-hidden hide-scroll rounded-4 h-100 ">
            <h3 className="fw-bold mb-3">
              Active <span className="text-white">Assignments</span>
            </h3>
            <div className="overflow-auto">
              {/* {tutorialRes.data.filter(i => i.publishInUserApp === true).map(item =>
                <div className='card shadow rounded-4 border-0 my-3 p-3 flex-row justify-content-between align-items-center'>
                  <div dangerouslySetInnerHTML={{ __html: item.assignment }}></div>

                  <div className='d-flex align-items-center'>
                    <div className="form-check form-switch fs-3 me-4">
                      <input className="form-check-input" type="checkbox" role="switch" id="toggle3" />
                    </div>
                    {Icons.arrow()}
                  </div>

                </div>
              )} */}

              {assignmentRes?.data
                ?.filter((i) => i.status == "Active")
                .map((item) => (
                  <div
                    className="card shadow rounded-4 border-0 my-3 p-3 flex-row justify-content-between align-items-center"
                    key={item.id}
                  >
                    <div>
                      <div className="fw-bold">{item.assignmentName}</div>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      ></div>
                    </div>

                    <div className="d-flex align-items-center">
                      <div className="form-check form-switch fs-3 me-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="toggle3"
                          checked={item.status == "Active" ? true : false}
                          onChange={() => handleToggleUpdate(item)}
                        />
                      </div>
                      <Link
                        to={`special/${item.id}`}
                        className="  btn btn-outline-primary border-0 p-0 rounded-circle text-hover-white"
                      >
                        {" "}
                        {Icons.arrow()}
                      </Link>
                    </div>
                  </div>
                ))}

              {tutorialRes?.data
                ?.filter((i) => i.status == "Active")
                .map((item) => (
                  <div className="card shadow rounded-4 border-0 my-3 p-3 flex-row justify-content-between align-items-center">
                    <div>
                      <div className="fw-bold">{item.title}</div>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.assignment }}
                      ></div>
                    </div>

                    <div className="d-flex align-items-center">
                      <div className="form-check form-switch fs-3 me-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="toggle3"
                          checked={item.status == "Active" ? true : false}
                          onChange={() => handleToggleTutorial(item)}
                        />
                      </div>
                      <Link
                        to={`special/${item.id}`}
                        className="btn btn-outline-primary border-0 p-0 rounded-circle text-hover-white"
                      >
                        {" "}
                        {Icons.arrow()}
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assignments;
