import React, { useEffect, useState } from "react";
import Icons from "../../components/ui/Icons";
import { useNavigate } from "react-router-dom";
import { useGetSupportTicketsQuery } from "../../services/supportSlice";
import Loading from "../../components/ui/Loading";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import SupportAction from "./SupportAction";
import ticketImg from "../../assets/images/ticket.png";
import rodImg from "../../assets/images/rod.png";

const Support = () => {
  const supportTicketsRes = useGetSupportTicketsQuery();
  const navigate = useNavigate();

  const [selectedTicket, setSelectedTicket] = useState(null);

  if (supportTicketsRes.isLoading) return <Loading />;
  if (supportTicketsRes.isError)
    return <ApiErrorModal response={supportTicketsRes} />;

  return (
    <div className="d-flex py-5 hide justify-content-between h-100 ">
      <div
        className=" hide-scroll flex-grow-1 mx-4 p-3 overflow-hidden"
        style={{ width: "60%" }}
      >
        <div className="rounded-4 bg-primary mx-3 p-3">
          <h2 className="text-white fw-bold">Tickets</h2>
        </div>

        {/* <div className='bg-white p-3 overflow-auto h-100'>
          {supportTicketsRes.data.map((item) =>

            <div className='d-flex rounded-4 my-3 shadow' key={item.id}>

              <div className='p-3 pe-5 border-end flex-grow-1'>
                <h6 className='fw-bold'>Ticket ID</h6>
                <p className='fs-13 text-muted'>{item.id}</p>
              </div>

              <div className='p-3 pe-5 border-end flex-grow-1'>
                <h6 className='fw-bold'>Topic</h6>
                <p className='fs-13 text-muted'>{item.topic}</p>
              </div>

              <div className='p-3 pe-5 border-end flex-grow-1'>
                <h6 className='fw-bold'>Last Updated</h6>
                <p className='fs-13 text-muted'>{item.updatedAt}</p>
              </div>

              <div className='p-3 border-end d-flex align-items-center'>
                <div className="btn btn-success btn-sm px-3 rounded-pill text-white">Resolved</div>
              </div>

              <div className='d-flex p-3'>
                <div className='btn p-0' onClick={() => setSelectedTicket(item.id)}>{Icons.arrow()}</div>
              </div>

            </div>
          )}
        </div> */}

        <div className="overflow-auto container p-0 h-100">
          {supportTicketsRes.data?.map((item) => (
            <div
              className="row row-cols-5 d-flex align-items-center bg-white flex-grow-1 rounded-4 m-3 shadow "
              key={item.id}
            >
              <div className="col-2">
                <div className="py-3 border-end  ">
                  <div className="fw-bold">Ticket ID</div>
                  <p className="fs-11 text-muted">{item.id}</p>
                </div>
              </div>

              <div className="col-4">
                <div className="py-3  border-end ">
                  <h6 className="fw-bold">Topic</h6>
                  <p className="fs-11 text-muted">
                    {item.topic.length > 15
                      ? item.topic.substring(0, 14) + "..."
                      : item.topic}
                  </p>
                </div>
              </div>

              <div className="col-3">
                <div className="py-3 border-end">
                  <h6 className="fw-bold">Last Updated</h6>
                  <p className="fs-11 text-muted">
                    {item.updatedAt &&
                      new Date(item.updatedAt).toLocaleDateString()}
                  </p>
                </div>
              </div>

              <div className="col-2 ">
                <div className="py-3  border-end">
                  {item.status == "Created" ? (
                    <div
                      className="btn btn-sm  rounded-pill text-white"
                      style={{ backgroundColor: "red" }}
                    >
                      Unresolve
                    </div>
                  ) : (
                    <div
                      className="btn btn-sm  rounded-pill text-white"
                      style={{ backgroundColor: "#508D02" }}
                    >
                      Resolved
                    </div>
                  )}
                </div>
              </div>

              <div className="col-1">
                <div className="py-3 text-center">
                  <div
                    className="btn btn-outline-success border-0 p-0 rounded-circle text-hover-white"
                    onClick={() => setSelectedTicket(item.id)}
                  >
                    {Icons.arrow()}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div style={{ width: "40%" }} className="">
        {selectedTicket && <SupportAction id={selectedTicket} />}
        {!selectedTicket && (
          <div className="bg-white rounded-start-5 shadow overflow-hidden h-100">
            <div
              className="d-flex align-items-center justify-content-between p-4"
              style={{ backgroundColor: "#F5F5F5" }}
            >
              <h5 className="text-danger fw-bold me-3">
                Resolve <span className="text-black">Tickets</span>
              </h5>
            </div>
            <div className="p-3 text-center">
              <img src={ticketImg} alt="" className="w-100" />
              <h5 className="my-3">
                Prioritize the resolution of unresolved tickets for enhanced
                service provision.
              </h5>
              <p>
                Tickets resolved by you will be marked as messages from the
                Administrator, ensuring efficient management.
              </p>
              <img src={rodImg} alt="" className="w-100" />
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="d-flex p-5 justify-content-between h-100">
      <div
        className="bg-light rounded-5 overflow-hidden hide-scroll me-4 h-100"
        style={{ width: "60%" }}
      >
        <h2 className="fw-bold text-danger p-4">Tickets</h2>

        <div className="bg-white p-4 overflow-auto h-100">
          {supportTicketsRes.data.map((item) => (
            <div
              className="d-flex bg-white rounded-4 my-3 shadow"
              key={item.id}
            >
              <div className="p-4 pe-5 border-end flex-grow-1">
                <h6 className="fw-bold">Ticket ID</h6>
                <p className="fs-13 text-muted">{item.id}</p>
              </div>

              <div className="p-4 pe-5 border-end flex-grow-1">
                <h6 className="fw-bold">Topic</h6>
                <p className="fs-13 text-muted">{item.topic}</p>
              </div>

              <div className="p-4 pe-5 border-end flex-grow-1">
                <h6 className="fw-bold">Last Updated</h6>
                <p className="fs-13 text-muted">{item.updatedAt}</p>
              </div>

              <div className="p-4 border-end d-flex align-items-center">
                <div className="btn btn-success btn-sm px-3 rounded-pill text-white">
                  Resolved
                </div>
              </div>

              <div className="d-flex p-4">
                <div
                  className="btn p-0"
                  onClick={() => setSelectedTicket(item.id)}
                >
                  {Icons.arrow()}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div style={{ width: "40%" }}>
        {selectedTicket && <SupportAction id={selectedTicket} />}
        {!selectedTicket && (
          <div className="bg-white rounded-start-5  shadow overflow-hidden">
            <div
              className="d-flex align-items-center justify-content-between p-4"
              style={{ backgroundColor: "#F5F5F5" }}
            >
              <h5 className="text-danger fw-bold me-3">
                Resolve <span className="text-black">Tickets</span>
              </h5>
            </div>
            <div className="p-3 text-center">
              <img src={ticketImg} alt="" className="w-100" />
              <h5 className="my-5">
                Prioritize the resolution of unresolved tickets for enhanced
                service provision.
              </h5>
              <p>
                Tickets resolved by you will be marked as messages from the
                Administrator, ensuring efficient management.
              </p>
              <img src={rodImg} alt="" className="w-100" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Support;
