import React from "react";
import Icons from "../ui/Icons";
import SignOut from "./SignOut";
import { Link, useLocation } from "react-router-dom";
import Loading from "../ui/Loading";
import ApiErrorModal from "../modals/ApiErrorModal";
import { useGetUserDetailsQuery } from "../../services/adminSlice";
import LoginUser from "./LoginUser";
import ProfileDropdown from "./ProfileDropdown";
import { GiHamburgerMenu } from "react-icons/gi";

const Headbar = () => {
  const { pathname } = useLocation();
  const response = useGetUserDetailsQuery();

  if (response.isLoading) return <Loading />;
  if (response.isError) return <ApiErrorModal response={response} />;

  if (pathname.startsWith("/training/") || pathname.startsWith("/singleSignOn")) {
    return <></>;
  }

  return (
    <div className="bg-light  shadow z-1 p-3 shadow d-flex justify-content-between">
      <button
        className="d-lg-none btn btn-primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasExample"
        aria-controls="offcanvasExample"
      >
        <GiHamburgerMenu />
      </button>
      <div className="d-flex">
        <Link
          className="btn bg-white rounded-circle  me-4 shadow"
          to={"notice"}
        >
          {Icons.notification()}
        </Link>
        <ProfileDropdown />
      </div>
      {/* <SignOut /> */}
    </div>
  );

  return (
    <div className="bg-light p-3 shadow d-flex align-items-center justify-content-between">
      <div className="d-flex">
        {/* <div className='btn bg-white rounded-circle  me-4 shadow'  data-bs-toggle="modal" data-bs-target={"#" + "viewAllNoticeModal"} >{Icons.notification()}</div> */}
        <Link
          className="btn bg-white rounded-circle  me-4 shadow"
          to={"notice"}
        >
          {Icons.notification()}
        </Link>
        {/* <div className='px-3 bg-white rounded-pill shadow d-flex align-items-center'>
          <div className='me-2 fw-bold'>{response.data.name}{Icons.downArrow("ms-3")}</div>
        </div> */}
        <ProfileDropdown />
      </div>

      {/* <div className='d-flex gap-2'>
        <LoginUser />
        <SignOut />
      </div> */}

      {/* <ViewAllNoticeModal /> */}
    </div>
  );
};

export default Headbar;
