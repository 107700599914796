import React, { useEffect, useState } from 'react'
import bookImg from '../../assets/images/book_poster.png'
import starImg from '../../assets/images/star.png'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useGetCategoriesQuery } from '../../services/categorySlice'
import { useGetBooksQuery } from '../../services/bookSlice'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import BookDeleteModal from '../../components/modals/deleteModals/BookDeleteModal'
import CategoryDeleteModal from '../../components/modals/deleteModals/CategoryDeleteModal'
import Icons from '../../components/ui/Icons'
import Slider from '../../components/ui/Slider'
import rightPosterimg from '../../assets/images/coursebook_poster.png'
import Select from '../../components/form/Select'
import CreateBookModal from '../../components/modals/CreateBookModal'
import Api from '../../constants/Api'
import Rating from 'react-rating'
import icgsWheel from '../../assets/gif/icgsWheel.gif'

const CourseBooks = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const categoryRes = useGetCategoriesQuery();
  const bookRes = useGetBooksQuery();


  const sortedCategories = categoryRes.isSuccess
    ? [...categoryRes.data].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    : [];

  const sortedBooks = bookRes.isSuccess
    ? [...bookRes.data].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    : [];

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };


  if (categoryRes.isLoading || bookRes.isLoading || bookRes.isFetching) return <Loading />
  if (categoryRes.isError) return <ApiErrorModal response={categoryRes} />
  if (bookRes.isError) return <ApiErrorModal response={bookRes} />


  const selected = categoryRes.data.find(i => i.id == id)


  return (
    <>
      {/* <div className='overflow-hidden hide-scroll h-100 d-flex  py-5'> */}
      <div className='container-fluid px-0 py-4 d-flex h-100'>
        {/* <div className=' ps-5 container overflow-auto h-100 w-75 flex-grow-1'> */}
        <div className='w-75 px-5 overflow-hidden hide-scroll'>

          <div className="overflow-auto h-100 w-100 p-0">

            <h2 className='fw-bold mb-2 text-danger'>Course Books</h2>
            <Slider type={id ? "CourseBooksWithId-" + id : "CourseBooks"} />

            {
              !id &&
              <>
                <div className='d-flex justify-content-between align-items-center my-3'>
                  <h2 className='fw-bold mb-3 text-danger'>All Categories</h2>
                  <div className='d-flex'>
                    <div className="btn bg-danger text-white rounded-pill px-5" onClick={() => navigate('createCategory')}>Add New Category</div>
                  </div>
                </div>


                <div className='row row-cols-4 g-4 mb-5 p-0'>
                  {
                    categoryRes.isSuccess && categoryRes.data.map(item =>
                      <div key={item.id} className="col" >
                        <div className="text-center rounded-3 fw-bold px-3 py-4 h-100 " onClick={() => navigate('/coursebooks/' + item.id)} style={{ backgroundColor: `${item.color}` }}>
                          <div> {truncateText(item.name, 30)}..</div>

                          <div className="" onClick={(e) => e.stopPropagation()}>
                            {/* <Link className='btn' to={'editCategory/' + item.id} >
                              {Icons.edit()}
                            </Link> */}
                            {/* <CategoryDeleteModal id={item.id} refetch={categoryRes.refetch} refetch2={bookRes.refetch} /> */}
                          </div>

                        </div>
                      </div>
                    )
                  }
                </div>
              </>
            }

            <div className='d-flex justify-content-between align-items-center my-3'>
              <h2 className='fw-bold mb-3 text-danger'> {selected ? `Books of ${selected.name}` : 'All Books'}</h2>
              <div>
                {
                  id && <Link className='btn btn-primary  rounded-pill px-4 me-2' to={'/coursebooks/editCategory/' + id} >
                    Edit
                  </Link>
                }
                {
                  id && <CategoryDeleteModal id={id} refetch={categoryRes.refetch} refetch2={bookRes.refetch} />
                }

                <div className="btn bg-danger text-white rounded-pill ms-2 px-5" onClick={() => navigate('/coursebooks/create')}>Add New Book</div>
                {/* <CreateBookModal /> */}
              </div>

            </div>

            <div className='row row-cols-3 g-4'>
              {
                bookRes.data

                  .filter(i => !id || i.category?.id == id)
                  .map((item,i) => {

                    let totalRating = 0;

                    item.ratings.forEach((rating) => {
                      totalRating += rating.rating;
                    });

                    const averageRating = totalRating / item.ratings.length;

                    return(
                      <>
                        <div key={item.id} className="col ">
                              <div className='d-flex p-3 h-100 rounded-4 overflow-hidden w-100 shadow border border-3 border-white' style={{ backgroundColor: item.color }} onClick={() => navigate('/coursebooks/edit/' + item.id)}>
                                {
                                  item.images.length > 0 &&
                                  <img src={Api.IMAGE_URL + item.images[0].id + item.images[0].ext} alt="" className='me-3 rounded-3' width={150} height={200} style={{ objectFit: 'cover' }} />
                                }
                                <div className='d-flex flex-column justify-content-center overflow-hidden'>
                                  <h6 className='fw-bold  fs-5  mb-2' style={{}}>{item.name}</h6>
                                  {/* <h6 className='fw-bold mb-2' style={{}}>Hath jhalya to lidhu ugari</h6> */}
                                  <p className='text-muted lh-sm mb-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.language}</p>
                                  {/* <p className='text-muted lh-sm mb-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>English</p> */}
                                  <p className='text-muted lh-sm mb-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.authorName}</p>
                                  {/* <p className='text-muted lh-sm mb-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Vivek Swami Ji</p> */}
                                  <p className='text-muted lh-sm mb-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.category.name}</p>
                                  {/* <p className='text-muted lh-sm mb-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Dharmic course</p> */}

                                  <Rating
                                    initialRating={averageRating}
                                    readonly
                                    fullSymbol={Icons.rating('fs-5 text-warning  m-0', {})}
                                    emptySymbol={Icons.rating('fs-5  m-0', {color: '#fafafa'})}
                                    // emptySymbol={Icons.ratingOutline('fs-5 text-white  m-0', {})}
                                  />
                                </div>
                              </div>
                            </div>
                      </>
                    )
                    return (
                      <>
                        <div key={item.id} className="col ">
                          <div className='d-flex px-2 py-3  h-100 rounded-4 overflow-hidden w-100' onClick={()=>navigate('/coursebooks/edit/' + item.id)} style={{ backgroundColor: item.color }} >
                            {
                              item.images.length > 0 &&
                              <img src={Api.IMAGE_URL + item.images[0].id + item.images[0].ext} alt="" className='me-4' width={100} height={150} style={{ objectFit: 'cover' }} />
                            }
                            <div className='d-flex flex-column justify-content-center'>
                              {/* <h6 className='fw-bold mb-2' style={{}}>{item.name}</h6> */}
                              {/* <p className='text-muted lh-sm mb-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.language}</p>
                            <p className='text-muted lh-sm mb-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.authorName}</p>
                            <p className='text-muted lh-sm mb-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.category.name}</p> */}

                              <h6 className='fw-bold mb-2' style={{}}>{truncateText(item.name, 21)}</h6>
                              <p className='text-muted lh-sm mb-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{truncateText(item.language, 21)}</p>
                              <p className='text-muted lh-sm mb-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{truncateText(item.authorName, 21)}</p>
                              <p className='text-muted lh-sm mb-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{truncateText(item.category.name, 21)}</p>


                              <Rating
                                initialRating={averageRating}
                                readonly
                                fullSymbol={Icons.rating('fs-3 text-warning m-0', {})}
                                emptySymbol={Icons.ratingOutline('fs-3 text-muted m-0', {})}
                              />
                              <div className='d-flex' onClick={(e) => e.stopPropagation()}>
                                {/* <Link className='btn' to={'/coursebooks/edit/' + item.id}>
                                  {Icons.edit()}
                                </Link> */}
                                {/* <BookDeleteModal id={item.id} refetch={bookRes.refetch} /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>

                    )
                  }

                  )
              }
            </div>
          </div>

        </div>
        {/* <div className='h-100' style={{ width: 300 }}>
          <div className=' h-100 d-flex align-items-center'>
            <Link to={'/icgsPinwheel'}>
              <img className="text-center" src={rightPosterimg} style={{ width: 400 }} alt="" />
            </Link>
          </div>
        </div> */}

        {/* <div className='w-25 my-5 bg-white overflow-auto rounded-start-5 d-flex align-items-start '>

          {
            !id ? <img className="text-center w-100 " src={icgsWheel} alt="" /> :

              <div className='p-3   bg-danger'>
                <h4 className='my-2  text-danger'>About</h4>
                <p>
                  {categoryRes.data.find((item) => item.id == id).text}
                </p>

              </div>
          }


        </div> */}

        <Link to={'/icgsPinwheel'} className='w-25  bg-white overflow-hidden rounded-start-5 d-flex align-items-center'>
          <img className="text-center w-100 " src={icgsWheel} alt="" />
        </Link>

      </div>
    </>
  )
}

export default CourseBooks
