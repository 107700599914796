import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

const ManageUsers = () => {

  const navigate = useNavigate();

  const items = [
    { id: "ID 20 - ", name: "ID 20 - Cristofer", status: "Active", role: "Admin", post: "USER", branch: "Bran0125fss" },
    { id: "ID 20 - ", name: "ID 20 - Cristofer", status: "Active", role: "Admin", post: "USER", branch: "Bran0125fss" },
    { id: "ID 20 - ", name: "ID 20 - Cristofer", status: "Active", role: "Admin", post: "USER", branch: "Bran0125fss" },
    { id: "ID 20 - ", name: "ID 20 - Cristofer", status: "Active", role: "Admin", post: "USER", branch: "Bran0125fss" },
    { id: "ID 20 - ", name: "ID 20 - Cristofer", status: "Active", role: "Admin", post: "USER", branch: "Bran0125fss" },
    { id: "ID 20 - ", name: "ID 20 - Cristofer", status: "Active", role: "Admin", post: "USER", branch: "Bran0125fss" },
    { id: "ID 20 - ", name: "ID 20 - Cristofer", status: "Active", role: "Admin", post: "USER", branch: "Bran0125fss" },
    { id: "ID 20 - ", name: "ID 20 - Cristofer", status: "Active", role: "Admin", post: "USER", branch: "Bran0125fss" },
    { id: "ID 20 - ", name: "ID 20 - Cristofer", status: "Active", role: "Admin", post: "USER", branch: "Bran0125fss" },
  ]

  return (
    <div className='py-3 px-5'>

      <div className='card rounded-5 overflow-hidden shadow p-3'>

        <div className='d-flex justify-content-between align-items-center px-3'>
          <div className='d-flex'>
            <p className='me-5 my-3 fw-bold'>Branches</p>
            <p className='me-5 my-3 fw-bold'>Users</p>
          </div>
          <div className="btn btn-danger btn-sm text-white rounded-pill" onClick={() => navigate('create')}>Create New Branch</div>
        </div>

        <table className="table table-hover">
          <thead>
            <tr className='border-bottom'>
              <th scope="col" className='bg-primary text-white'></th>
              <th scope="col" className='bg-primary text-white'>NAME</th>
              <th scope="col" className='bg-primary text-white'>USER STATUS</th>
              <th scope="col" className='bg-primary text-white'>USER ROLE</th>
              <th scope="col" className='bg-primary text-white'>USER POST</th>
              <th scope="col" className='bg-primary text-white'>BRANCH</th>
              <th scope="col" className='bg-primary text-white'>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {items.map(item =>
              <tr key={item.id} onClick={() => { }}>
                <th scope="row">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                    </label>
                  </div>
                </th>
                <th className='py-3'>{item.name}</th>
                <th className='bg-primary rounded-pill text-white d-flex fs-13 justify-content-center'>{item.status}</th>
                <th className='text-danger py-3'>{item.role}</th>
                <th className='py-3'>{item.post}</th>
                <th className='py-3'><Link className='text-danger'>{item.branch}</Link></th>
                <td className='d-flex py-3'>
                  <div className='btn btn-sm btn-primary rounded-pill px-3 me-3 py-0'>View Details</div>
                  <div className='btn btn-sm btn-primary rounded-pill px-3 text-white py-0'>Edit Branch</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

      </div>

    </div>
  )
}

export default ManageUsers
