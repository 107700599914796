import React, { useState } from 'react'
import Input from '../../components/form/Input'
import poster from '../../assets/images/course-poster.png'
import { useNavigate } from 'react-router-dom';
import { useCreateCategoryMutation } from '../../services/categorySlice';
import InputTextArea from '../../components/form/InputTextArea';
import SuccessModal from '../../components/modals/SuccessModal';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import Button from '../../components/form/Button';
import Loading from '../../components/ui/Loading';
import Api from '../../constants/Api';
import Cropper from '../../components/Cropper';
import Slider from '../../components/ui/Slider';

const CreateCategory = () => {

    const [createCategory, createCategoryRes] = useCreateCategoryMutation();

    const onSubmit = (event) => {
        event.preventDefault();
        const form = event.target

        if (form.checkValidity()) {

            const body = {
                name: form['name'].value,
                text: form['text'].value,
                color: form['color'].value
            }

            createCategory(body).then((res) => {
                if (!res.error) {
                    form.reset()
                    form.classList.remove('was-validated')
                }
            })

        }
        else form.classList.add('was-validated')
    }



    return (
        <div className='p-5'>

            {createCategoryRes.isSuccess && <SuccessModal label={"Created!"} message={"Category has been created."} to={{ link: "/courseBooks", text: "Go to Course Books" }} closeTo={"/courseBooks"} />}
            {createCategoryRes.isError && <ApiErrorModal response={createCategoryRes} />}


            <div className='p-4 bg-white rounded-5 shadow my-5'>

                <form className="" onSubmit={onSubmit} noValidate>

                    <h3 className='fw-bold text-danger mb-3'>Create Category</h3>

                    <div className="d-flex align-items-center">
                        <div className='flex-grow-1'>

                            <Input labelName={'Name'} labelClass={'me-5'} name={'name'} label={'Category Name'} containerClass={'mb-3'} inputClass={'me-5'} required />
                            <InputTextArea name={'text'} label={'About'} rows={3} inputClass={'mx-5 '} required />

                          


                        </div>

                        <div className='px-4 ms-5'>
                            <label htmlFor="color" className="form-label fw-bold">Colour Pallet</label>
                            <input type="color" className="form-control form-control-color mx-auto" name='color' id="color" title="Choose your color" />
                        </div>
                    </div>

                    <hr className='my-5' />

                    {/* <h6 className='fw-bold mb-3'>Category Slider</h6> */}
                    {/* <Slider type="Category" /> */}

                    <Button
                        className='col-2 btn btn-primary text-white rounded-pill text-nowrap my-3'
                        res={createCategoryRes}
                        loadingLabel={"Creating"}
                    >
                        Create
                    </Button>
                </form>

            </div>

        </div>
    )
}

export default CreateCategory