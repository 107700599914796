import React from 'react'

const InputTextArea = ({ name, label, placeholder, type, containerClass, inputClass, ...attributes }) => {

    const containerClassName = "d-flex align-items-center justify-content-between " + (containerClass ? containerClass : "")
    const inputClassName = "form-control rounded-4 px-3  " + (inputClass ? inputClass : "")
    const inputType = type ? type : "text"

    return (
        <div className={containerClassName}>
            {label &&
                <label htmlFor={name} className='fw-bold lh-sm text-nowrap'>{label}</label>
            }
            <textarea
                type={inputType}
                className={inputClassName}
                id={name}
                name={name}
                placeholder={placeholder ? placeholder : name}
                {...attributes}
            />
        </div>
    )
}

export default InputTextArea