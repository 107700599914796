import React, { useState } from 'react'
import { useParams } from 'react-router'
import { useGetTutorialsQuery } from '../../services/tutorialSlice';
import Loading from '../../components/ui/Loading';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import Select from '../../components/form/Select';

const ViewBook = () => {

    const { book, section } = useParams()
    const response = useGetTutorialsQuery()

    const activeTabClassName = "flex-grow-1 bg-light py-4 text-center border-bottom border-5 border-danger fw-bold";
    const inactiveTabClassName = "flex-grow-1 bg-primary py-4 text-center text-white";

    const [selectedOption, setSelectedOption] = useState({});
    const [selectedTab, setSelectedTab] = useState("Overview");
    const [selectedLanguage, setSelectedLanguage] = useState("ENGLISH");


    if (response.isLoading) return <Loading />
    if (response.isError) return <ApiErrorModal response={response} />

    const tutorialData = response.data.filter(item => item.section.toLowerCase() === section && item.books === book)
    const tutorialDataString = response.data.filter(item => item.section.toLowerCase() === section && item.books === book).map(i => i.topic)

    console.log(selectedOption);

    return (
        <div className='px-5 py-4 h-100'>
            <div className='ms-auto w-25'>
                <Select
                    name={'Language'}
                    lableName={'Language'}
                    inputClass={'bg-white ms-4 '}
                    options={[
                        'ENGLISH', 'HINDI', 'GUJRATI'
                    ]}
                    value={selectedLanguage}
                    onChange={(e) => setSelectedLanguage(e.target.value)}
                    required
                />
            </div>


            <div className='p-5 h-100'>
                <div className='d-flex h-100'>
                    <div className='rounded-start-5 overflow-hidden hide-scroll shadow h-100 bg-white'>
                        <h5 className='bg-primary text-white p-4'>{book}</h5>
                        <div className="overflow-auto h-100">
                            {tutorialDataString.filter((value, index, array) => array.indexOf(value) === index).map(item =>
                                <div className='px-4 py-4' key={item}>
                                    {/* <div className='py-2 bg-danger'>{item}</div> */}
                                    {tutorialData.filter(i => i.topic === item).map(item2 =>
                                        <div key={item2.title} className={(selectedOption.topic === item && selectedOption.tutorial === item2.title) ? 'px-4 py-2 border bg-primary text-white rounded-4' : 'px-4 py-2'}
                                            onClick={() => setSelectedOption({ topic: item, tutorial: item2.title, data: item2 })}>{item2.title}</div>
                                    )}
                                </div>
                            )}

                        </div>
                    </div>

                    <div className='card rounded-end-5 overflow-hidden shadow w-100 h-100'>
                        {selectedOption.data ? (
                            <>
                                <div className="d-flex justify-content-between shadow border ">
                                    <div className={selectedTab === "Overview" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Overview")}>Overview</div>
                                    <div className={selectedTab === "Tutorial" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Tutorial")}>Tutorial</div>
                                    <div className={selectedTab === "ICGS Manual" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("ICGS Manual")}>ICGS Manual</div>
                                    <div className={selectedTab === "Activity" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Activity")}>Activity</div>
                                    <div className={selectedTab === "Material" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Material")}>Material</div>
                                </div>

                                <div
                                    className={selectedTab === "Overview" ? "d-block p-4 overflow-y-auto" : "d-none"}
                                    // style={{height:200}}
                                    dangerouslySetInnerHTML={{ __html: selectedOption?.data.overview }}
                                ></div>
                                <div
                                    className={selectedTab === "Tutorial" ? "d-block p-4  overflow-y-auto" : "d-none"}
                                    dangerouslySetInnerHTML={{ __html: selectedOption?.data.tutorial }}
                                ></div>
                                <div
                                    className={selectedTab === "ICGS Manual" ? "d-block p-4  overflow-y-auto" : "d-none"}
                                    dangerouslySetInnerHTML={{ __html: selectedOption?.data.manual }}
                                ></div>
                                <div
                                    className={selectedTab === "Activity" ? "d-block p-4  overflow-y-auto" : "d-none"}
                                    dangerouslySetInnerHTML={{ __html: selectedOption?.data.activity }}
                                ></div>
                                <div
                                    className={selectedTab === "Material" ? "d-block p-4  overflow-y-auto" : "d-none"}
                                    dangerouslySetInnerHTML={{ __html: selectedOption?.data.material }}
                                ></div>
                            </>
                        ) : (
                            // Default content for the book shape when no topic is selected

                            <div className="d-flex justify-content-between bg-danger">
                                <div className={selectedTab === "Overview" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Overview")}>Overview</div>
                                <div className={selectedTab === "Tutorial" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Tutorial")}>Tutorial</div>
                                <div className={selectedTab === "ICGS Manual" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("ICGS Manual")}>ICGS Manual</div>
                                <div className={selectedTab === "Activity" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Activity")}>Activity</div>
                                <div className={selectedTab === "Material" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Material")}>Material</div>

                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className='px-5 py-4 h-100'>
            <div className='ms-auto w-25 mb-3'>
                <Select
                    name={'Language'}
                    lableName={'Language'}
                    inputClass={'bg-white ms-4 '}
                    options={[
                        'ENGLISH', 'HINDI', 'GUJRATI'
                    ]}
                    value={selectedLanguage}
                    onChange={(e) => setSelectedLanguage(e.target.value)}
                    required
                />
            </div>

            <div className='d-flex h-100'>
                <div className='rounded-start-5 overflow-hidden hide-scroll shadow h-100 bg-white'>
                    <h5 className='bg-primary text-white p-4'>{book}</h5>
                    <div className="overflow-auto h-100">
                        {tutorialDataString.filter((value, index, array) => array.indexOf(value) === index).map(item =>
                            <div className='px-4 py-4' key={item}>
                                <div className='py-2 bg-danger'>{item}</div>
                                {tutorialData.filter(i => i.topic === item).map(item2 =>
                                    <div key={item2.title} className={(selectedOption.topic === item && selectedOption.tutorial === item2.title) ? 'px-4 py-2 border bg-primary text-white rounded-4' : 'px-4 py-2'}
                                        onClick={() => setSelectedOption({ topic: item, tutorial: item2.title, data: item2 })}>{item2.title}</div>
                                )}
                            </div>
                        )}

                    </div>
                </div>

                <div className='card rounded-end-5 overflow-hidden shadow w-100 h-100'>
                    {selectedOption.data ? (
                        <>
                            <div className="d-flex justify-content-between shadow border ">
                                <div className={selectedTab === "Overview" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Overview")}>Overview</div>
                                <div className={selectedTab === "Tutorial" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Tutorial")}>Tutorial</div>
                                <div className={selectedTab === "ICGS Manual" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("ICGS Manual")}>ICGS Manual</div>
                                <div className={selectedTab === "Activity" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Activity")}>Activity</div>
                                <div className={selectedTab === "Material" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Material")}>Material</div>
                            </div>

                            <div
                                className={(selectedTab === "Overview" && selectedLanguage === "ENGLISH") ? "d-block p-4 overflow-y-auto" : "d-none"}
                                // style={{height:200}}
                                dangerouslySetInnerHTML={{ __html: selectedOption?.data.overview }}
                            ></div>
                            <div
                                className={(selectedTab === "Tutorial" && selectedLanguage === "ENGLISH") ? "d-block p-4  overflow-y-auto" : "d-none"}
                                dangerouslySetInnerHTML={{ __html: selectedOption?.data.tutorial }}
                            ></div>
                            <div
                                className={(selectedTab === "ICGS Manual" && selectedLanguage === "ENGLISH") ? "d-block p-4  overflow-y-auto" : "d-none"}
                                dangerouslySetInnerHTML={{ __html: selectedOption?.data.manual }}
                            ></div>
                            <div
                                className={(selectedTab === "Activity" && selectedLanguage === "ENGLISH") ? "d-block p-4  overflow-y-auto" : "d-none"}
                                dangerouslySetInnerHTML={{ __html: selectedOption?.data.activity }}
                            ></div>
                            <div
                                className={(selectedTab === "Material" && selectedLanguage === "ENGLISH") ? "d-block p-4  overflow-y-auto" : "d-none"}
                                dangerouslySetInnerHTML={{ __html: selectedOption?.data.material }}
                            ></div>


                            <div
                                className={(selectedTab === "Overview" && selectedLanguage === "HINDI") ? "d-block p-4 overflow-y-auto" : "d-none"}
                                // style={{height:200}}
                                dangerouslySetInnerHTML={{ __html: selectedOption?.data.overviewHindi }}
                            ></div>
                            <div
                                className={(selectedTab === "Tutorial" && selectedLanguage === "HINDI") ? "d-block p-4  overflow-y-auto" : "d-none"}
                                dangerouslySetInnerHTML={{ __html: selectedOption?.data.tutorialHindi }}
                            ></div>
                            <div
                                className={(selectedTab === "ICGS Manual" && selectedLanguage === "HINDI") ? "d-block p-4  overflow-y-auto" : "d-none"}
                                dangerouslySetInnerHTML={{ __html: selectedOption?.data.manualHindi }}
                            ></div>
                            <div
                                className={(selectedTab === "Activity" && selectedLanguage === "HINDI") ? "d-block p-4  overflow-y-auto" : "d-none"}
                                dangerouslySetInnerHTML={{ __html: selectedOption?.data.activityHindi }}
                            ></div>
                            <div
                                className={(selectedTab === "Material" && selectedLanguage === "HINDI") ? "d-block p-4  overflow-y-auto" : "d-none"}
                                dangerouslySetInnerHTML={{ __html: selectedOption?.data.materialHindi }}
                            ></div>


                            <div
                                className={(selectedTab === "Overview" && selectedLanguage === "GUJRATI") ? "d-block p-4 overflow-y-auto" : "d-none"}
                                // style={{height:200}}
                                dangerouslySetInnerHTML={{ __html: selectedOption?.data.overviewGujrati }}
                            ></div>
                            <div
                                className={(selectedTab === "Tutorial" && selectedLanguage === "GUJRATI") ? "d-block p-4  overflow-y-auto" : "d-none"}
                                dangerouslySetInnerHTML={{ __html: selectedOption?.data.tutorialGujrati }}
                            ></div>
                            <div
                                className={(selectedTab === "ICGS Manual" && selectedLanguage === "GUJRATI") ? "d-block p-4  overflow-y-auto" : "d-none"}
                                dangerouslySetInnerHTML={{ __html: selectedOption?.data.manualGujrati }}
                            ></div>
                            <div
                                className={(selectedTab === "Activity" && selectedLanguage === "GUJRATI") ? "d-block p-4  overflow-y-auto" : "d-none"}
                                dangerouslySetInnerHTML={{ __html: selectedOption?.data.activityGujrati }}
                            ></div>
                            <div
                                className={(selectedTab === "Material" && selectedLanguage === "GUJRATI") ? "d-block p-4  overflow-y-auto" : "d-none"}
                                dangerouslySetInnerHTML={{ __html: selectedOption?.data.materialGujrati }}
                            ></div>
                        </>
                    ) : (
                        // Default content for the book shape when no topic is selected

                        <div className="d-flex justify-content-between bg-danger">
                            <div className={selectedTab === "Overview" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Overview")}>Overview</div>
                            <div className={selectedTab === "Tutorial" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Tutorial")}>Tutorial</div>
                            <div className={selectedTab === "ICGS Manual" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("ICGS Manual")}>ICGS Manual</div>
                            <div className={selectedTab === "Activity" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Activity")}>Activity</div>
                            <div className={selectedTab === "Material" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Material")}>Material</div>

                        </div>
                    )}
                </div>
            </div>
        </div>
    );

}

export default ViewBook