import React from 'react'
import { useGetTutorialsQuery } from '../../services/tutorialSlice';
import Loading from '../../components/ui/Loading';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import { Link } from 'react-router-dom';
import Icons from '../../components/ui/Icons';
import { useGetAssignmentsQuery } from '../../services/assignmentSlice';
import SubmissionViewModal from '../../components/modals/SubmissionViewModal';

const Submissions = () => {
    const response = useGetAssignmentsQuery();

    if (response.isLoading) return <Loading />
    if (response.isError) return <ApiErrorModal response={response} />

    return (

        <>
            <div className="bg-white position-sticky sticky-top p-4 shadow">
                <h3 className="fw-bold mb-2">Assignment <span className="text-danger">Submissions</span></h3>
                <p>Efficiently monitor user submissions on this page. Review assignments and access progress for an enhanced learning experience.</p>
            </div>
            <div className='py-3 px-5'>


                {response.isSuccess &&
                    <>
                        <div className="row">
                            {response.data.map((item, index) => (
                                item.submissions.length > 0 && (
                                    <div key={item.assignmentId} className="col-12  ">
                                        <div className='card shadow rounded-4 border-0 my-2  p-3 flex-row justify-content-between gap-0 align-items-center'>
                                            <div>
                                                <Link to={`${item.id}`} className="fw-bold fs-13 text-danger text-decoration-none">View Submissions for Assignment</Link>
                                                <div className='fw-bold'>#{item.id} - {item.assignmentName}</div>
                                            </div>
                                            <Link to={`${item.id}`} className='btn btn-outline-success p-0 rounded-circle text-hover-white'> {Icons.arrow()}</Link>
                                        </div>
                                    </div>
                                )
                            ))}
                        </div>

                    </>
                }
            </div>
        </>
    )
}

export default Submissions




