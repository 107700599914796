import React from 'react'

const ContainerCard = ({ children }) => {

    const containerClassName = " h-100 overflow-hidden"
    const cardClassName = "card rounded-5 shadow-lg h-100 overflow-hidden"

    return (
        <div className={containerClassName} ><div className={cardClassName}>{children}</div></div>
    )
}

export default ContainerCard