import { NavLink, useLocation } from 'react-router-dom'
import logoGif from "../../assets/gif/logo.gif"
import Icons from '../ui/Icons';
import { useGetUserDetailsQuery } from '../../services/adminSlice';
import ApiErrorModal from '../modals/ApiErrorModal';
import Loading from '../ui/Loading';


export const sideBarLinks = [
    { to: "/", text: "Dashboard", icon: Icons.dashboard },
    { to: "/sandhyaSabha", text: "Sandhya Sabha", icon: Icons.sandhyaSabha },
    { to: "/assignments", text: "Assignments", icon: Icons.assignments },
    { to: "/coursebooks", text: "Course Books", icon: Icons.courseBooks },
    { to: "/icgsPinwheel", text: "ICGS Pinwheel", icon: Icons.pinWheel },
    { to: "/downloadables", text: "Downloadables", icon: Icons.downloadables },
    { to: "/training", text: "ICGS Training", icon: Icons.training },
    { to: "/support", text: "Resolve Tickets", icon: Icons.support },
    // { to: "/notice", text: "Notices", icon: Icons.support },
    { to: "/info", text: "About ICGS", icon: Icons.info },
    { to: "/settingV1", text: "Account Settings", icon: Icons.setting },
    // { to: "/manageUsers", text: "Manage Users", icon: Icons.manageUsers },
];

const Sidebar = () => {

    const response = useGetUserDetailsQuery()
    const { pathname } = useLocation()

    const navItem = (link, access) => {
        const allowList = ["/", "/info", "/settingV1", "/support", "/manageUsers", "/notice"]
        const shouldRenderLink = allowList.includes(link.to) || access[link.to.replace('/', '')] === true;
        return (shouldRenderLink) ? (
            <li key={link.text} className="nav-item">
                <NavLink to={link.to} className="nav-link d-flex align-items-center">
                    {link.icon('me-3', { width: 20, height: 20 })}
                    {link.text}
                </NavLink>
            </li>
        ) : null;
    };

    if (pathname.startsWith("/training/") || pathname.startsWith("/singleSignOn")) {
        return <></>
    }

    return (
        <div className="d-none d-lg-flex flex-column flex-shrink-0 bg-primary h-100 shadow" style={{ width: 280, zIndex: 1 }}>

            <div className='d-flex justify-content-center mb-3'>
                <div className="bg-white rounded-bottom-5 p-3">
                    <img src={logoGif} alt="" style={{ width: 150 }} />
                </div>
            </div>

            <div className='overflow-auto py-3'>
                <ul className="nav nav-pills flex-column mb-auto px-3">
                    {response.isLoading ?
                        <Loading />
                        :
                        (response.isError ?
                            <ApiErrorModal response={response} />
                            :
                            sideBarLinks.map((link) => navItem(link, response.data.brahmvidyaPortalAccess)))
                    }
                </ul>
            </div>
        </div>
    )
}

export default Sidebar