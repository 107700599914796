import React from 'react'
import Input from '../../components/form/Input'
import poster from '../../assets/images/course-poster.png'
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCategoryByIdQuery, useUpdateCategoryMutation } from '../../services/categorySlice';
import InputTextArea from '../../components/form/InputTextArea';
import SuccessModal from '../../components/modals/SuccessModal';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import Button from '../../components/form/Button';
import { useGetAssignmentByIdQuery } from '../../services/assignmentSlice';
import Loading from '../../components/ui/Loading';
import Slider from '../../components/ui/Slider';

const EditCategory = () => {

    const { id } = useParams()

    const [updateCategory, updateCategoryRes] = useUpdateCategoryMutation();
    const categoryRes = useGetCategoryByIdQuery(id);
    const navigate = useNavigate();

    const onSubmit = (event) => {
        event.preventDefault();
        const form = event.target

        if (form.checkValidity()) {

            const body = {
                name: form['name'].value,
                text: form['text'].value,
                color: form['color'].value
            }

            updateCategory({ id, body })
            // .then((res) => {
            //     if (!res.error) {
            //         form.reset()
            //         form.classList.remove('was-validated')
            //     }
            // })

        }
        else form.classList.add('was-validated')
    }

    if (categoryRes.isLoading) return <Loading />
    if (categoryRes.isError) return <ApiErrorModal response={categoryRes} />

    return (
        <div className='p-5'>

            {updateCategoryRes.isSuccess && <SuccessModal label={"Updated!"} message={"Category has been updated."} to={{ link: "/courseBooks", text: "Go to Course Books" }} closeTo={"/courseBooks"} />}
            {updateCategoryRes.isError && <ApiErrorModal response={updateCategoryRes} />}

            <h2 className='fw-bold text-danger mb-2'>Create Category</h2>

            <div className='p-4 bg-white rounded-5 shadow my-5'>

                <form className="" onSubmit={onSubmit} noValidate>

                    <h3 className='fw-bold text-danger mb-3'>Category Details</h3>

                    <div className="d-flex align-items-center">
                        <div className='flex-grow-1'>
                            <Input name={'name'} labelName={'Category Name'} containerClass={'mb-3'} inputClass={''} defaultValue={categoryRes.data?.name} required />
                            <InputTextArea name={'text'} label={'About'} rows={3} inputClass={'p-3  mx-5'} defaultValue={categoryRes.data?.text} required />
                        </div>

                        <div className='px-4 ms-5'>
                            <label htmlFor="color" className="form-label fw-bold">Colour Pallet</label>
                            <input type="color" className="form-control form-control-color mx-auto" name='color' id="color" title="Choose your color" defaultValue={categoryRes.data?.color} />
                        </div>
                    </div>

                    <hr className='my-5' />

                    <h6 className='fw-bold mb-3'>Category Slider</h6>
                    {/* <Slider type="Category" /> */}
                    <Slider type={"CourseBooksWithId-" + id} />

                    <Button
                        className='col-2 btn btn-primary text-white rounded-pill text-nowrap my-3'
                        res={updateCategoryRes}
                        loadingLabel={"Updating"}
                    >
                        Update
                    </Button>
                </form>

            </div>

        </div>
    )
}

export default EditCategory