import React from 'react'
import { useGetInfoByIdQuery, useUpdateInfoMutation } from '../../services/infoSlice';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import SuccessModal from '../../components/modals/SuccessModal';
import Loading from '../../components/ui/Loading';
import { Link, useParams } from 'react-router-dom';
import FormattedInput from '../../components/form/FormattedInput';

const EditInfo = () => {

  const { id } = useParams()
  const [updateInfo, updateInfoRes] = useUpdateInfoMutation();
  const response = useGetInfoByIdQuery(id)

  const onSubmit = (e) => {
    e.preventDefault();
    const body = {
      title: ".",
      content: e.target['formattedInput'].value
    }

    updateInfo({ id, body })
  }

  if (response.isLoading) return <Loading />
  if (response.isError) return <ApiErrorModal response={response} />

  return (
    <div className='py-3 px-5'>

      {updateInfoRes.isSuccess && <SuccessModal label={"Updated!"} message={"Info has been created."} to={{ link: "/info", text: "Go to Info" }} closeTo={"/info"} />}
      {updateInfoRes.isError && <ApiErrorModal response={updateInfoRes} />}


      <form onSubmit={onSubmit} className='card rounded-4 overflow-hidden my-3'>
        <FormattedInput defaultValue={response.data.content} />
        <div className='align-self-end'>
          <button className="btn btn-primary rounded-pill px-5 m-3" >Publish</button>
          <Link to={-1} className="btn btn-primary rounded-pill px-5 m-3">Back</Link>
        </div>
      </form>
    </div>
  )
}

export default EditInfo