import React from 'react'
import { Link } from 'react-router-dom';
import SuccessModal from '../../components/modals/SuccessModal';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import { useUpdateModuleMutation } from '../../services/moduleSlice';
import Button from '../../components/form/Button';
import FormattedInput from '../../components/form/FormattedInput';

const CreateModule = ({ module, refetch }) => {

    const id = module?.id
    const [updateModule, updateModuleRes] = useUpdateModuleMutation()

    const onSubmit = (e) => {
        e.preventDefault()
        const body = {
            overview: e.target['formattedInput'].value
        }
        updateModule({ id, body })
            .then((res) => {
                if (!res.error) {
                    refetch()
                }
            })
    }


    return (
        <div className='py-3 px-5'>

            {updateModuleRes.isSuccess && <SuccessModal label={"Updated!"} message={"Module has been updated."} closeTo={"/training"} />}
            {updateModuleRes.isError && <ApiErrorModal response={updateModuleRes} />}

            <h3 className='fw-bold mb-3'><span className='text-danger'>Overview</span> of Module</h3>
            <form className='' onSubmit={onSubmit} noValidate>
                <FormattedInput defaultValue={module.overview} />
                <div className='d-flex justify-content-end mt-3'>
                    <Link className="btn btn-primary rounded-pill text-white px-5 me-3" to={"/training/" + module?.id}>Edit Session</Link>
                    <Button
                        className='btn btn-primary text-white rounded-pill text-nowrap px-5'
                        res={updateModuleRes}
                        loadingLabel={"Updating..."}
                    >
                        Update Module
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default CreateModule