import React from 'react'
import { Link } from 'react-router-dom'
import userImg from '../assets/images/userPhoto.png'
import userImg2 from '../assets/images/user2.png'
import { useGetNoticesQuery } from '../services/noticeSlice'
import Loading from '../components/ui/Loading'
import ApiErrorModal from '../components/modals/ApiErrorModal'
import ViewNoticeModal from '../components/modals/ViewNoticeModal'
import Slider from '../components/ui/Slider'
import gurukulVadodaraimg from '../assets/images/gurukulvadodara.png'
import { useGetUserDetailsQuery } from '../services/adminSlice'
import ShortcutEditModal from '../components/ui/ShortcutEditModal'
import { useGetShortcutQuery } from '../services/shortcutSlice'

const Dashboard = () => {

  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const userRes = useGetUserDetailsQuery()
  const noticeRes = useGetNoticesQuery()
  const shortcutRes = useGetShortcutQuery()

  if (noticeRes.isLoading || userRes.isLoading || shortcutRes.isLoading) return <Loading />
  if (noticeRes.isError) return <ApiErrorModal response={noticeRes} />
  if (userRes.isError) return <ApiErrorModal response={userRes} />

  const sortedNotices = noticeRes.isSuccess
    ? [...noticeRes.data].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    : [];

  return (
    <div className='  px-5 h-100  py-4'>

      <div className="row h-100">

        <div className="col-7 d-flex flex-column">

          <Slider type="Dashboard" />

          <div className='d-flex flex-column bg-white rounded-5 p-3 flex-grow-1 shadow  ' >


            <div className='row row-cols-3'>
              {
                shortcutRes.data.map((item,i) =>
                  <div className=" mt-3" key={i}> 

                    <Link to={item.link} target={item.tab != 'Same Tab' ? '__blank' : ''} className=" m-2 card shadow p-3 shadow d-flex justify-content-center align-items-center text-decoration-none fw-bold" style={{ width: 200, height: 79, borderBottom: '6px solid blue' }}>{item.name}</Link>
                  </div>

                )
              }
            </div>




            <div className="mt-auto">
              <ShortcutEditModal />
            </div>



          </div>

        </div>

        <div className="col-5 h-100">

          <div className='card px-5 rounded-5  h-100 d-flex flex-column hide-scroll shadow'>


            <div className="text-center overflow-hidden mb-3  pb-5"><span className='text-white shadow text-center bg-primary rounded-bottom-4 px-5 py-2 mb-3 fs-4'>Notices</span></div>


            <div className="d-flex flex-column overflow-hidden flex-grow-1 ">
              <div className='overflow-auto flex-grow-1 align-items-center justify-content-center'>
                {noticeRes?.data.map(item =>
                  <div key={item.id}>
                    <div className='d-flex align-items-center'>
                      <div className='bg-secondary py-2 px-3 rounded-4 text-white justify-content-center me-3'>
                        <h3>{new Date(item.date).getDate()<10 ? '0'+ new Date(item.date).getDate() : new Date(item.date).getDate()}</h3>
                        <p className='fs-13 text-center'>{months[new Date(item.date).getMonth()]}</p>
                      </div>
                      <div>
                        <p className='fw-bold'>{item.title} </p>
                        <p className='fw-bold'><ViewNoticeModal id={item.id} /> | <Link to={'/notice/' + item.id} className='text-danger'>edit</Link></p>

                      </div>
                    </div>
                    <hr className='text-light' />
                  </div>
                )}

              </div>
              <div className='d-flex justify-content-center my-3'>
                <Link to={'notice/create'} className="btn btn-primary rounded-pill px-5">Create</Link>
                <Link className="btn btn-primary rounded-pill px-5 ms-4 " to={"notice"}>History</Link>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>
  )
}

export default Dashboard
