import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getModuleById: builder.query({ query: (id) => `trainingModule?id=${id}`, keepUnusedDataFor: 0 }),
        getModules: builder.query({ query: () => `trainingModule`, keepUnusedDataFor: 0 }),
        createModule: builder.mutation({ query: (body) => ({ url: `trainingModule`, method: 'POST', body }) }),
        updateModule: builder.mutation({ query: ({ id, body }) => ({ url: `trainingModule?id=${id}`, method: 'PATCH', body }) }),
        deleteModule: builder.mutation({ query: (id) => ({ url: `trainingModule?id=${id}`, method: 'DELETE' }) }),
    })
})

export const { useGetModuleByIdQuery, useGetModulesQuery, useCreateModuleMutation, useUpdateModuleMutation, useDeleteModuleMutation } = extendedApiSlice
