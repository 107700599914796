import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useCreateAboutMutation, useGetAboutByIdQuery, useGetAboutQuery, useUpdateAboutMutation } from '../../services/aboutSlice'
import Loading from '../ui/Loading'
import Icons from '../ui/Icons'
import Api from '../../constants/Api'

const SubmissionViewModal = ({ id, type, content, submission }) => {

    const modalId = "submissionViewModal" + id

    const [editorContent, setEditorContent] = useState(content);




    return (
        <>
            <Link type="button" className=" p-1  btn btn-outline-primary rounded-circle text-hover-white" data-bs-toggle="modal" data-bs-target={"#" + modalId}>
                {Icons.arrow()}
            </Link>

            <div className="modal fade" id={modalId} tabindex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content rounded-4">
                        <div className="modal-header d-flex justify-content-center p-0">
                            <h1 className="modal-title fs-5 bg-primary text-white px-3 py-1 rounded-bottom-4 mb-3" id={modalId + "Label"}>Assignment Submission</h1>
                        </div>
                        <div className="modal-body " style={{ minHeight: '20rem' }}>

                            <div className='m-2 my-3'>{content}</div>

                            <div className=' row g-2 '>
                                {
                                    submission.submissionFiles
                                        .filter((item) => {
                                            if (item.ext === '.jpg' || item.ext === '.jpeg' || item.ext === '.png' || item.ext === '.gif') {
                                                return true
                                            }
                                            else {
                                                return false;
                                            }
                                        })
                                        .map((item, i) => (

                                            <div key={i} className='m-2 ' >
                                                <img  src={Api.BASE_URL + `uploads/submitImages/${item?.id}${item?.ext}`} className='rounded col' alt="" />

                                            </div>
                                        ))

                                }

                            </div >

                            <div className=" my-4">
                                {
                                    submission.submissionFiles
                                        .filter((item) => {
                                            if (item.ext === '.mp3' || item.ext === '.wav') {
                                                return true
                                            }
                                            else {
                                                return false;
                                            }
                                        })
                                        .map((item) => (
                                            <>
                                                <audio key={item.id} controls><source src={Api.BASE_URL + `uploads/audio/${item?.id}${item?.ext}`} type="audio/mpeg" /></audio>

                                            </>))

                                }
                            </div>






                            <div className='text-dark ' dangerouslySetInnerHTML={{ __html: content }}></div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary text-white rounded-pill px-4" data-bs-dismiss="modal" >Close</button>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default SubmissionViewModal