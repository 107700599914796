import React from "react";
import ReactDOM from "react-dom";
import ImageUploading from "react-images-uploading";
import Cropper from "../Cropper";


const UploadImage = ({image, setImage}) => {
    const maxNumber = 69;
    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImage(imageList);
    };

    return (
        <div className="App">
            <ImageUploading
                multiple
                value={image}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                acceptType={["jpg"]}
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps
                }) => (
                    // write your building UI
                    <div className=" my-3">
                        <button
                            className="btn btn-primary"
                            style={isDragging ? { color: "red" } : null}
                            onClick={onImageUpload}
                            {...dragProps}
                        >
                            Upload or Drag Images
                        </button>
                        {/* <Cropper id={4} image={images} setImage={setImages} aspectRatio={3 / 2} label={'Add cover'} containerClass={'w-25 ms-3 mt-2'} /> */}

                        &nbsp;
                        <button className="btn btn-primary" onClick={onImageRemoveAll}>Remove all images</button>
                        <div className="d-flex justify-content-between">
                            {imageList.map((image, index) => (
                                <div key={index} className="">
                                    <img src={image.data_url} alt="" width="100" />
                                    <div className="">
                                        <button className="btn btn-success" onClick={() => onImageUpdate(index)}>Update</button>
                                        <button className="btn btn-danger" onClick={() => onImageRemove(index)}>Remove</button>
                                    </div>
                                </div>
                            ))}


                        </div>
                    </div>
                )}
            </ImageUploading>
        </div>
    );
}

export default UploadImage
