import React from 'react'
import AssignmentDeleteModal from '../../components/modals/deleteModals/AssignmentDeleteModal'
import Icons from '../../components/ui/Icons'
import { Link } from 'react-router-dom'
import { useGetAssignmentsQuery, useUpdateAssignmentMutation } from '../../services/assignmentSlice'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modals/ApiErrorModal'

const SpecialAssignments = () => {

    const response = useGetAssignmentsQuery();

    const [updateAssignment, updateAssignmentRes] = useUpdateAssignmentMutation()


    const handleToggleUpdate = (item) => {

        const newStatus = item.status === 'Active' ? 'Inactive' : 'Active';

        let body = { status: newStatus }
        updateAssignment({ id: item.id, body })
            .then(() => response.refetch())
    }

    if (response.isLoading) return <Loading />
    if (response.isError) return <ApiErrorModal response={response} />

    return (
        <div className=''>
            {response.isSuccess &&
                <>
                    <div className='bg-white position-sticky sticky-top p-4 shadow'>
                        <h3 className='fw-bold '>Special <span className='text-danger'>Assignments</span></h3>
                        <p>Assignments not associated with any tutorial are coined as special assignments you can manage all the special assignments right here.</p>

                    </div>

                    <div className="px-5 pt-3">
                        {response.data.map((item, i) =>
                            <div key={i} className='card shadow rounded-4 border-0 my-3 p-3 flex-row justify-content-between bg-dange'>
                                <div className='w-75'>
                                    <p className='fw-bold'>{item.assignmentName}</p>
                                    <p className='fs-13 text-muted'>{item.subtitle}</p>
                                </div>

                                <div className='w-25 d-flex justify-content-between '>

                                    <div className='d-flex align-items-center '>
                                        <AssignmentDeleteModal id={item.id} refetch={response.refetch} />
                                    </div>

                                    <div className='d-flex align-items-center text-end '>
                                        <div className="form-check form-switch fs-3 me-4">
                                            <input className="form-check-input" type="checkbox" role="switch" id="toggle3" checked={item.status == 'Active' ? true : false} onChange={() => handleToggleUpdate(item)} />
                                        </div>
                                        <Link className="btn btn-outline-primary border-0 p-0 rounded-circle text-hover-white" to={`${item.id}`}> {Icons.arrow()}</Link>
                                    </div>
                                </div>

                            </div>
                        )}

                    </div>

                </>
            }
        </div>
    )
}

export default SpecialAssignments