import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSessions: builder.query({ query: () => `session`, keepUnusedDataFor: 0 }),
        getSessionById: builder.query({ query: (id) => `session?id=${id}`, keepUnusedDataFor: 0 }),
        createSession: builder.mutation({ query: ({id, body}) => ({ url: `session?id=${id}`, method: 'POST', body }) }),
        updateSession: builder.mutation({ query: ({id, body}) => ({ url: `session?id=${id}`, method: 'PATCH', body }) }),
        deleteSession: builder.mutation({ query: (id) => ({ url: `session?id=${id}`, method: 'DELETE' }) }),
    })
})

export const {
    useGetSessionsQuery,
    useGetSessionByIdQuery,
    useCreateSessionMutation,
    useUpdateSessionMutation,
    useDeleteSessionMutation
} = extendedApiSlice
