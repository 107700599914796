import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({ query: (body) => ({ url: `brahmvidya/login`, method: 'POST', body }) }),
        changePassword: builder.mutation({ query: (body) => ({ url: `changePassword`, method: 'PATCH', body }) }),
        // signOut: builder.mutation({ query: (body) => ({ url: `signOut`, method: 'POST', body }) }),
    })
})

export const {
    useLoginMutation,
    useChangePasswordMutation,
    useSignOutMutation
} = extendedApiSlice
