import React from 'react'
import { useCreateInfoMutation } from '../../services/infoSlice';
import SuccessModal from '../../components/modals/SuccessModal';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import FormattedInput from '../../components/form/FormattedInput';
import { Link } from 'react-router-dom';

const CreateInfo = () => {

    const [createInfo, createInfoRes] = useCreateInfoMutation();

    const handlePublish = (e) => {
        e.preventDefault();
        createInfo({
            title: ".",
            content: e.target['formattedInput'].value
        })
    }

    return (
        <div className='py-3 px-5'>

            {createInfoRes.isSuccess && <SuccessModal label={"Created!"} message={"Info has been created."} to={{ link: "/info", text: "Go to Info" }} closeTo={"/info"} />}
            {createInfoRes.isError && <ApiErrorModal response={createInfoRes} />}

            <form onSubmit={handlePublish}>

                <div className='card rounded-4 overflow-hidden my-3'>
                    <FormattedInput  />
                    <div className='align-self-end'>
                        <button className="btn btn-primary rounded-pill px-5 m-3" type='submit'>Publish</button>
                        <Link to={-1} className="btn btn-primary rounded-pill px-5 m-3">Back</Link>
                    </div>
                </div>

            </form>
        </div>
    )
}

export default CreateInfo