import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserDetails: builder.query({ query: () => ``, keepUnusedDataFor: 0 }),
        // createCategory: builder.mutation({ query: (body) => ({ url: `category`, method: 'POST', body }) }),
        
        updateEmailOrContact: builder.mutation({ query: (body) => ({ url: `changeEmailOrContact`, method: 'PATCH', body }) }),

        getTerms: builder.query({ query: () => `termsAndConditions`, keepUnusedDataFor: 0 }),
        createTerms: builder.mutation({ query: (body) => ({ url: `termsAndConditions`, method: 'POST', body }) }),
        updateTerms: builder.mutation({ query: ({ id, body }) => ({ url: `termsAndConditions?id=${id}`, method: 'PUT', body }) }),
        deleteTerms: builder.mutation({ query: (id) => ({ url: `termsAndConditions?id=${id}`, method: 'DELETE' }) }),

        getTechSupport: builder.query({ query: () => `technicalSupport`, keepUnusedDataFor: 0 }),
        createTechSupport: builder.mutation({ query: (body) => ({ url: `technicalSupport`, method: 'POST', body }) }),
        updateTechSupport: builder.mutation({ query: ({ id, body }) => ({ url: `technicalSupport?id=${id}`, method: 'PATCH', body }) }),
        deleteTechSupport: builder.mutation({ query: (id) => ({ url: `technicalSupport?id=${id}`, method: 'DELETE' }) }),

        getCmsSupport: builder.query({ query: () => `cmsSupport`, keepUnusedDataFor: 0 }),
        createCmsSupport: builder.mutation({ query: (body) => ({ url: `cmsSupport`, method: 'POST', body }) }),
        updateCmsSupport: builder.mutation({ query: ({ id, body }) => ({ url: `cmsSupport?id=${id}`, method: 'PATCH', body }) }),
        deleteCmsSupport: builder.mutation({ query: (id) => ({ url: `cmsSupport?id=${id}`, method: 'DELETE' }) }),

        
        getPolicy: builder.query({ query: () => `policySetting`, keepUnusedDataFor: 0 }),
        createPolicy: builder.mutation({ query: (body) => ({ url: `policySetting`, method: 'POST', body }) }),
        updatePolicy: builder.mutation({ query: ({ id, body }) => ({ url: `policySetting?id=${id}`, method: 'PUT', body }) }),
        deletePolicy: builder.mutation({ query: (id) => ({ url: `policySetting?id=${id}`, method: 'DELETE' }) }),

    })
})

export const { useGetUserDetailsQuery , useGetPolicyQuery,useCreatePolicyMutation,useUpdatePolicyMutation,useDeletePolicyMutation, useGetCmsSupportQuery,useCreateCmsSupportMutation,useUpdateCmsSupportMutation,useDeleteCmsSupportMutation, useUpdateEmailOrContactMutation ,useGetTermsQuery,useCreateTermsMutation,useUpdateTermsMutation,useDeleteTermsMutation  ,useGetTechSupportQuery,useCreateTechSupportMutation,useUpdateTechSupportMutation,useDeleteTechSupportMutation} = extendedApiSlice
 