import React, { useEffect, useState } from 'react'
import { useCreateModuleMutation, useGetModuleByIdQuery } from '../../services/moduleSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { useCreateSessionMutation } from '../../services/sessionSlice'
import CreateSession from './CreateSession'
import SessionDeleteModal from '../../components/modals/deleteModals/SessionDeleteModal'
import EditSessionNameModal from '../../components/modals/EditSessionNameModal'
import logo from '../../assets/gif/portals.gif'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modals/ApiErrorModal'

const Sessions = () => {

    const navigate = useNavigate()
    const { id } = useParams()
    const response = useGetModuleByIdQuery(id)
    const [createSession, createSessionRes] = useCreateSessionMutation()

    const [selectedSessionTab, setSelectedSessionTab] = useState();

    const activeSessionTabClassName = "flex-grow-1 bg-white py-3 text-center border-end border-5 border-danger fw-bold";
    const inactiveSessionTabClassName = "flex-grow-1 bg-light py-3 text-center";

    useEffect(() => {
        if (response.isSuccess && response.data.sessions.length > 0) {
            setSelectedSessionTab(response.data.sessions[0].id)
        }
    }, [response.isSuccess])

    // useEffect(() => {
    //     if (response.isFetching) {
    //         setSelectedSessionTab(0)
    //     }
    // }, [response.isFetching])

    if (response.isLoading ) return <Loading />
    if (response.isError) return <ApiErrorModal response={response} />

    const module = response.data;

    const addSessionTab = (num) => {

        const body = {
            name: "Session " + num,
            overview: "<p>Write your oveview here...</p>",
            notes: "<p>Write your notes here...</p>",
            queAns: "<p>Write your questions & answers here...</p>",
            announcement: "<p>Write your announcement here...</p>",
            rating: "<p>Write your rating here...</p>",
            videoLink: ""
        }

        createSession({ id, body })
            .then(() => {
                response.refetch()
            });
    }

    return (
        <div className='d-flex h-100'>

            <div className='text-center bg-primary d-flex flex-column' style={{ width: 350 }}>
                <div className='p-4'>
                    <img src={logo} alt="" className='rounded-4' style={{ width: 300 }} />
                </div>
                <h4 className='fw-bold bg-white p-4 text-primary rounded-top-5 mb-1'>{module.name}</h4>
                <div className='bg-light flex-grow-1 overflow-y-auto'>
                {module.sessions.map((item) =>
                        <div className={selectedSessionTab === item.id ? activeSessionTabClassName : inactiveSessionTabClassName} onClick={() => setSelectedSessionTab(item.id)}>
                            {item.name}
                            <span className='ms-2'><EditSessionNameModal id={item.id} refetch={response.refetch} /></span>
                            <SessionDeleteModal id={item.id} refetch={response.refetch} />
                        </div>
                    )}
                   
                    <p className='text-danger py-3 sticky-bottom mt-2 text-white bg-danger' onClick={() => addSessionTab(module.sessions.length)}>Add New Session</p>
                </div>
            </div>

            <div className='d-flex flex-column flex-grow-1 border-start border-primary border-2 h-100'>
                <div className='d-flex bg-primary pt-4 pe-3 justify-content-between'>
                    <div className='bg-white pt-2 mb-1 rounded-top-4 px-4 fw-bold fs-5 text-primary'>Training Department</div>
                    <div className="btn btn-light rounded-pill mb-3" onClick={() => navigate('/training')}>Back to training</div>
                </div>
                <div className='overflow-auto flex-grow-1'>
                {
                        (selectedSessionTab) &&
                        <CreateSession session={module.sessions.find((session) => session.id === selectedSessionTab)} id={id} refetch={response.refetch} />
                    }
                </div>
            </div>

        </div>
    )
}

export default Sessions