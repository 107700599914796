import React, { useState } from 'react'
import Button from '../form/Button';
import { getApiErrorMessage } from '../../utility/errorMessages';
import Loading from '../ui/Loading';
import ApiErrorModal from './ApiErrorModal';
import Check from '../form/Check';
import { useCreateTermsMutation, useDeleteTermsMutation, useGetTermsQuery, useUpdateTermsMutation } from '../../services/adminSlice';
import FormattedInput from '../form/FormattedInput';
import { useGetTutorialsQuery, useUpdateTutorialByIdMutation } from '../../services/tutorialSlice';

const ViewEditTechSupport = ({ refetch, id, isCreate, createTech, updateTech, getTech ,tutorialData }) => {

    const modalId = "ViewEditTechSupport" + id



    const [formEnabled, setFormEnabled] = useState(false)


    const [updateTutorial, updateTutorialRes] = useUpdateTutorialByIdMutation()






    const onSubmit = (event) => {
        event.preventDefault();
        const form = event.target
        const sandhyaTitle = form['formattedInput'].value

        console.log("11111111111", sandhyaTitle)

        if (form.checkValidity()) {
            const body = {
                phone: '',
                email: '',
                sandhyaTitle: sandhyaTitle,
            }

            const bodyOne = {
                sandhyaTitle: sandhyaTitle,
            }

            if (isCreate) {
                createTech(body).then((res) => {
                    if (!res.error) {
                        document.getElementById(modalId + 'CloseBtn').click();
                        refetch();
                        form.classList.remove('was-validated')
                    }
                })
            }
            else {

                updateTech({ id, body: bodyOne }).then(async (res) => {
                    if (!res.error) {

                        await tutorialData.data.forEach(async (tutorial) => {
                            await updateTutorial({
                                id: tutorial.id,
                                body: { publishInUserApp: false },
                            });
                        });

                        document.getElementById(modalId + 'CloseBtn').click();
                        refetch();
                        form.classList.remove('was-validated')
                    }
                })
            }

        }
        else form.classList.add('was-validated')

    }

    if (getTech.isLoading || tutorialData.isLoading) return <Loading />
    if (getTech.isError) return <ApiErrorModal response={getTech} />



    return (
        <>



            {
                isCreate ?
                    <div className="btn btn-primary btn-sm  ms-auto  w-25 text-white rounded-pill px-3" id={modalId + "Button"} data-bs-toggle="modal" data-bs-target={"#" + modalId}>Reset</div>  //create
                    :
                    <div className="btn btn-primary ms-auto w-25   btn-sm text-white rounded-pill px-3" id={modalId + "Button"} data-bs-toggle="modal" data-bs-target={"#" + modalId}>Reset</div> //edit
            }

            <div className="modal fade text-dark" id={modalId} tabIndex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">

                <form className="modal-dialog modal-dialog-centered modal-lg" onSubmit={onSubmit} noValidate>

                    <div className="modal-content bg-white rounded-4">
                        <div className="rounded-4">
                            <div className="modal-header py-0 border-0 position-relative">
                                <div className='px-5 bg-primary rounded-top-5 rounded-end-5 text-white py-2 position-absolute start-0'>Primary Details</div>
                                <button id={modalId + "CloseBtn"} type="button" className="btn-close me-2 mt-2" data-bs-dismiss="modal" aria-label="Close"></button>

                            </div>
                            <div className="modal-body text-dark text-start p-5">

                                <h5 className="fw-bold mb-3"></h5>

                                <FormattedInput name='formattedInput' defaultValue={getTech?.data[0]?.sandhyaTitle} label={'Title'} disabled={!formEnabled} />



                            </div>
                            <div className="modal-footer justify-content-end border-0">
                                {updateTech.isError &&
                                    <div className='me-auto fw-bold text-danger'>
                                        {getApiErrorMessage(updateTech).label}: {getApiErrorMessage(updateTech).message}
                                    </div>
                                }

                                <Check name={"Check to edit"} setChecked={setFormEnabled} containerClass={"me-3"} required />


                                <Button
                                    className="btn btn-secondary rounded-pill px-4 shadow-sm"
                                    loadingLabel="Updating"
                                    res={updateTech}
                                    disabled={!formEnabled}
                                    type='submit'
                                >
                                    Update
                                </Button>

                            </div>
                        </div>
                    </div>
                </form >
            </div >

        </>
    )
}

export default ViewEditTechSupport