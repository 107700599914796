import React from 'react'

const Input = ({ name, placeholder, type, labelName, labelClass, containerClass, inputClass, ...attributes }) => {

    const containerClassName = "d-flex align-items-center gap-2 " + (containerClass ? containerClass : "")
    const labelClassName = "fw-bold fs-13 text-nowrap " + (labelClass ? labelClass : "")
    const inputClassName = "form-control form-control-sm rounded-pill px-3 bg-info shadow-sm " + (inputClass ? inputClass : "")
    const inputType = type ? type : "text"

    return (
        <div className={containerClassName}>
            {labelName &&
                <label htmlFor={name} className={labelClassName}>{labelName}</label>
            }
            <input
                type={inputType}
                className={inputClassName}
                id={name}
                name={name}
                placeholder={placeholder ? placeholder : name}
                {...attributes}
            />
        </div>
    )
}

export default Input
