import React, { useEffect, useState } from 'react'
import { useUpdateSessionMutation } from '../../services/sessionSlice';
import SuccessModal from '../../components/modals/SuccessModal';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import Button from '../../components/form/Button';
import Rating from 'react-rating';
import Icons from '../../components/ui/Icons';
import Api from '../../constants/Api';
import FormattedInput from '../../components/form/FormattedInput';
import FormattedInputReRender from '../../components/form/FormattedInputReRender';
import VideoPlayer from '../../components/VideoPlayer';


const CreateSession = ({ id, session, refetch }) => {

    const [selectedTab, setSelectedTab] = useState("Overview");
    const [videoLink, setVideoLink] = useState(null);
    const activeTabClassName = "flex-grow-1 bg-light py-2 text-center border-bottom border-5 border-danger fw-bold";
    const inactiveTabClassName = "flex-grow-1 bg-primary py-2 text-center text-white";

    const [displays, setDisplays] = useState(true)

    const [updateSession, updateSessionRes] = useUpdateSessionMutation();

    const onSubmit = (event) => {
        event.preventDefault();
        const form = event.target

        const body = {
            overview: form['Overview'].value,
            notes: form['Notes'].value,
            queAns: form['QnA'].value,
            announcement: form['Announcement'].value,
            rating: 5,
            videoLink: videoLink
        }
        updateSession({ id: session.id, body })
            .then(() => {
                refetch()
            });
    }




    useEffect(() => {
        setDisplays(false)
        setTimeout(() => {
        setVideoLink(session?.videoLink);
            setDisplays(true)
        }, 1);
    }, [session.id]);



    const handleVideoLinkChange = (e) => {
        setVideoLink(e.target.value);
    };


    let totalRating = 0;

    session.ratings?.forEach((rating) => {
        totalRating += rating.rating;
    });

    const averageRating = totalRating / session?.ratings?.length;

    if (!displays) return <div className=""></div>

    return (
        <form onSubmit={onSubmit} className='flex-grow-1 bg-white'>

            {updateSessionRes.isSuccess && <SuccessModal label={"Updated!"} message={"Session has been updated."} closeTo={"/training/" + id} />}
            {updateSessionRes.isError && <ApiErrorModal response={updateSessionRes} />}

            
            <div className="d-flex p-3 w-100 " style={{ height: '290px' }}>
                <div className="w-50 p-2 ">
                    <p className="fs-13 fw-bold mb-3 ">Embed Video link</p>
                    <input
                        name="videoLink"
                        type="text"
                        value={videoLink}
                        onChange={handleVideoLinkChange}
                        className="bg-light border-0 rounded-3 w-100"
                        placeholder="Add video link here..."
                    />
                </div>

                <div className="w-50 d-flex px-5">
                      <VideoPlayer url={videoLink} />
                </div>
            </div>

            <div className='card'>
                <div className='d-flex justify-content-between'>

                    <div className={selectedTab === "Overview" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Overview")}>Overview</div>
                    <div className={selectedTab === "Notes" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Notes")}>Notes</div>
                    <div className={selectedTab === "QnA" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("QnA")}>QnA</div>
                    <div className={selectedTab === "Announcement" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Announcement")}>Announcement</div>
                    <div className={selectedTab === "Rating" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Rating")}>Rating</div>

                </div>



                <div className={selectedTab === "Overview" ? "d-block" : "d-none"}><FormattedInputReRender name={"Overview"} defaultValue={session?.overview} reRenderId={session.id} />  </div>
                <div className={selectedTab === "Notes" ? "d-block" : "d-none"}> <FormattedInputReRender name={"Notes"} defaultValue={session?.notes} reRenderId={session.id} /> </div>
                <div className={selectedTab === "QnA" ? "d-block" : "d-none"}> <FormattedInputReRender name={"QnA"} defaultValue={session?.queAns} reRenderId={session.id} /> </div>
                <div className={selectedTab === "Announcement" ? "d-block" : "d-none"}>   <FormattedInputReRender name={"Announcement"} defaultValue={session?.announcement} reRenderId={session.id} /> </div>
                {/* <div className={selectedTab === "Rating" ? "d-block" : "d-none"}></div> */}

                <div className={selectedTab === "Rating" ? "d-block p-4 " : "d-none "} >

                    <div className='d-flex justify-content-around gap-5' style={{ height: '250px' }}>

                        <div className='bg-white w-25 card p-3 h-50 mt-5 text-center'> {/* Adjusted flex property */}
                            <Rating
                                initialRating={averageRating}
                                fullSymbol={Icons.rating('display-6 text-warning', {})}
                                emptySymbol={Icons.ratingOutline('display-6 text-muted', {})}
                                readonly
                            />
                            <h3 className='mt-2'>Rate & Review</h3>
                        </div>

                        <div className='w-75 overflow-auto '>
                            <div className="row row-cols-1 row-cols-md-2 g-4   w-100">
                                {session.ratings.map((item, index) => (
                                    <div key={index} className='col'>
                                        <div className='card shadow p-3'>
                                            <div className="d-flex justify-content-between gap-2">
                                                <h6 className='mb-0'>{item.review}</h6>
                                                <Rating
                                                    initialRating={item.rating}
                                                    fullSymbol={Icons.rating(' fs-4 text-warning', {})}
                                                    emptySymbol={Icons.ratingOutline('fs-4 text-muted', {})}
                                                    readonly
                                                />
                                            </div>
                                            <div className='d-flex align-items-center mt-2'>{
                                                console.log("332",  item.userName)
                                            }
                                                <img src={Api.SUPERADMIN_URL + item.userImage} className='rounded rounded-circle' style={{ width: 40, height: 40 }} alt="" />
                                                <div className='ms-2'>{item.userName}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}




                            </div>
                        </div>
                    </div>





                </div>


            </div>

            <Button
                className='btn btn-primary text-white rounded-pill text-nowrap px-5 m-3'
                res={updateSessionRes}
                loadingLabel={"Updating..."}
                // onClick={() => handleUpdate()}
            >
                Update Session
            </Button>

        </form>
    )
}

export default CreateSession