import React, { useState } from 'react'
import Input from '../../components/form/Input'
import { useGetAssignmentByIdQuery, useUpdateAssignmentMutation } from '../../services/assignmentSlice'
import { useParams } from 'react-router-dom';
import SuccessModal from '../../components/modals/SuccessModal';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import Loading from '../../components/ui/Loading';
import Button from '../../components/form/Button';
import FormattedInput from '../../components/form/FormattedInput';
import Cropper from '../../components/Cropper';
import Api from '../../constants/Api';

const EditAssignment = () => {

  const { id } = useParams();
  const [image, setImage] = useState(null);

  const [updateAssignment, updateAssignmentRes] = useUpdateAssignmentMutation();
  const assignmentRes = useGetAssignmentByIdQuery(id)

  const onSubmit = (event) => {
    event.preventDefault();
    const form = event.target;

    if (form.checkValidity()) {
      const formData = new FormData();

      formData.append('assignmentName', form['assignmentName'].value);
      formData.append('subtitle', form['subtitle'].value);
      formData.append('content', form['Content'].value);
      if (image) formData.append('image', image);

      updateAssignment({ id, body: formData })
        .then((res) => {
          if (!res.error) {
            form.reset();
            form.classList.remove('was-validated');
          }
        })
        .catch((error) => {
          console.error('Error updating assignment:', error);
        });
    } else {
      form.classList.add('was-validated');
    }
  };


  if (assignmentRes.isLoading) return <Loading />
  if (assignmentRes.isError) return <ApiErrorModal response={assignmentRes} />

  return (
    <div className='py-3 px-5'>

      {updateAssignmentRes.isSuccess && <SuccessModal label={"Updated!"} message={"Assignment has been updated."} to={{ link: "/assignments/special", text: "Go to Special Assignments" }} closeTo={"/assignments"} />}
      {updateAssignmentRes.isError && <ApiErrorModal response={updateAssignmentRes} />}

      <h2 className='fw-bold text-danger mb-2'>Assignments</h2>
      <p className='text-muted'>This page offers a quick view of all your assignments, making it easy to stay organized and meet deadlines.</p>

      <div className='p-4 bg-white rounded-5 shadow my-5'>

        <form className="" onSubmit={onSubmit} noValidate>
          <Input name={'assignmentName'} label={'Assignment Name'} containerClass={'my-5'} defaultValue={assignmentRes.data?.assignmentName} required />
          <Input name={'subtitle'} label={'Subtitle'} containerClass={'my-5'} defaultValue={assignmentRes.data?.subtitle} required />
          {/* <Input name={'Assig Title'} containerClass={'my-5'} required /> */}
          <FormattedInput name={"Content"} defaultValue={assignmentRes.data.content} />

          {
            !image&& <div className="w-25 my-2">
            <img src={Api.IMAGE_URL + assignmentRes.data?.image?.id + assignmentRes.data?.image?.ext} className='text-center w-100 ' alt="" />

          </div>
          }

         <div>
         <Cropper id={4} image={image} setImage={setImage} aspectRatio={3 / 2} label={'Add cover'} containerClass={'w-25'} />
         </div>

          <Button
            className='col-2 btn btn-primary text-white rounded-pill text-nowrap my-3'
            res={updateAssignmentRes}
            loadingLabel={"Updating"}
          >
            Update
          </Button>
        </form>

      </div>

    </div>
  )
}

export default EditAssignment