import vidyaAdhyan from '../assets/images/vidyaAdhyan.png'
import vidyaArogya from '../assets/images/vidyaArogya.png'
import vidyaKaushal from '../assets/images/vidyaKaushal.png'


import sadvidyaSanskriti from '../assets/images/sadvidyaSanskriti.png'
import sadvidyaVyak from '../assets/images/sadvidyaVyak.png'
import sandvidyaSanskar from '../assets/images/sandvidyaSanskar.png'

import bharmvidyaSamarpan from '../assets/images/bharmvidyaSamarpan.png'
import bramvidyaSatsang from '../assets/images/bramvidyaSatsang.png'
import bramvidyaSeva from '../assets/images/bramvidyaSeva.png'

import chitramBhakta from '../assets/images/chitramBhakta.png'
import chitramNand from '../assets/images/chitramNand.png'
import chitramSwami from '../assets/images/chitramSwami.png'
import chitramsucharitra from '../assets/images/chitramsucharitra.png'




const SECTIONS_DATA = [
  {
    "id": 1,
    "name": "Vidhya",
    "books": [
      {
        "id": 1,
        "name": "Adhyayan",
        url: vidyaAdhyan,
        "bgColor": "#E1F5FF"
        
      },
      {
        "id": 2,
        "name": "Arogya",
        url: vidyaArogya,
        "bgColor": "#CCEED1"
        
        
      },
      {
        "id": 3,
        "name": "Kaushal",
        url: vidyaKaushal,
        "bgColor": "#F1EBBD"
      },
    ]
  },
  {
    "id": 2,
    "name": "Sadvidhya",
    "books": [
      {
        "id": 1,
        "name": "Sanskar",
        url: sandvidyaSanskar,
        "bgColor": "#E1F5FF"
      },
      {
        "id": 2,
        "name": "Sanskruti",
        url: sadvidyaSanskriti,
        "bgColor": "#CCEED1"
      },
      {
        "id": 3,
        "name": "Vyaktitva Vikas",
        url: sadvidyaVyak,
        "bgColor": "#F1EBBD"
      },
    ]
  },
  {
    "id": 3,
    "name": "Brahmvidhya",
    "books": [
      {
        "id": 1,
        "name": "Satsang",
        url: bramvidyaSatsang,
        "bgColor": "#E1F5FF"
      },
      {
        "id": 2,
        "name": "Seva",
        url: bramvidyaSeva,
        "bgColor": "#CCEED1"
      },
      {
        "id": 3,
        "name": "Samarpan",
        url: bharmvidyaSamarpan,
        "bgColor": "#F1EBBD"
      },
    ]
  },
  {
    "id": 4,
    "name": "Charitram",
    "books": [
      {
        "id": 1,
        "name": "Swaminarayan Charitam",
        url: chitramSwami,
        "bgColor": "#E1F5FF"
      },
      {
        "id": 2,
        "name": "Sant Charitam",
        url: chitramNand,
        "bgColor": "#CCEED1"
      },
      {
        "id": 3,
        "name": "Bhakta Charitam",
        url: chitramBhakta,
        "bgColor": "#F1EBBD"
      },
      {
        "id": 4,
        "name": "Sucharitam",
        url: chitramsucharitra
      },
    ]
  },
];

export default { SECTIONS_DATA }