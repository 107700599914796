import React from 'react'
import { useParams } from 'react-router'
import Vidhya from './Vidhya'
import Sadvidhya from './Sadvidhya'
import Brahmvidhya from './Brahmvidhya'
import Charitram from './Charitram'

const ViewSection = () => {

    const { section } = useParams()

    switch (section) {
        case "vidhya": return <Vidhya />
        case "sadvidhya": return <Sadvidhya />
        case "brahmvidhya": return <Brahmvidhya />
        case "charitram": return <Charitram />
    }
}

export default ViewSection