import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getInfos: builder.query({ query: () => `info`, keepUnusedDataFor: 0 }),
        getInfoById: builder.query({ query: (id) => `info?id=${id}`, keepUnusedDataFor: 0 }),
        createInfo: builder.mutation({ query: (body) => ({ url: `info`, method: 'POST', body }) }),
        updateInfo: builder.mutation({ query: ({ id, body }) => ({ url: `info?id=${id}`, method: 'PATCH', body }) }),
        deleteInfo: builder.mutation({ query: (id) => ({ url: `info?id=${id}`, method: 'DELETE' }) }),
    })
})

export const {
    useGetInfosQuery,
    useGetInfoByIdQuery,
    useCreateInfoMutation,
    useUpdateInfoMutation,
    useDeleteInfoMutation,
} = extendedApiSlice
