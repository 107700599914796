import React from 'react'
import ContainerCard from './ContainerCard'

const ResponsiveContainerCard = ({ children, titleBar }) => {
    return (
        <ContainerCard>
            {     titleBar()}

            <div className='flex-grow-1 overflow-auto'>
                {children}
            </div>

        </ContainerCard>
    )
}

export default ResponsiveContainerCard