import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDeleteChapterMutation } from '../../../services/bookSlice'
import Button from '../../form/Button'
import { getApiErrorMessage } from '../../../utility/errorMessages'
import Icons from '../../ui/Icons'

const ChapterDeleteModal = ({ id, refetch }) => {

    const modalId = "chapterDeleteModal" + id
    const navigate = useNavigate()
    const [deleteChapter, deleteChapterRes] = useDeleteChapterMutation()

    const onDelete = () => {
        deleteChapter(id).then((res) => {
            if (!res.error) {
                document.getElementById(modalId + 'CloseBtn').click()
                refetch()
            }
        })
    }

    return (
        <>
            <button type="button" className="btn" data-bs-toggle="modal" data-bs-target={"#" + modalId}>
                {Icons.delete()}
            </button>

            <div className="modal fade" id={modalId} tabindex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id={modalId + "Label"}>Chapter Delete</h1>
                            <button id={modalId + "CloseBtn"} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Do you really want to delete this chapter?
                        </div>
                        <div className="modal-footer">
                            {deleteChapterRes.isError &&
                                <div className='me-auto fw-bold text-danger'>
                                    {getApiErrorMessage(deleteChapterRes).label}: {getApiErrorMessage(deleteChapterRes).message}
                                </div>
                            }
                            <button type="button" className="btn btn-success text-white" data-bs-dismiss="modal">No</button>
                            <Button
                                className="btn btn-danger text-white"
                                type="button"
                                onClick={onDelete}
                                res={deleteChapterRes}
                                loadingLabel={"Deleting"}
                            >
                                Yes
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChapterDeleteModal