import React from 'react'
import { Link } from 'react-router-dom'
import ViewNoticeModal from '../../components/modals/ViewNoticeModal'
import { useGetNoticesQuery } from '../../services/noticeSlice';
import Loading from '../../components/ui/Loading';

const Notices = () => {
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const noticeRes = useGetNoticesQuery()


    if (noticeRes.isLoading) return <Loading />

    return (
        <div className=" py-5 px-5  h-100">
            {
                console.log("wer", noticeRes.data)
            }

            <div className='card px-5 rounded-4 overflow-hidden h-100 d-flex flex-column hide-scroll'>

                <div>
                    <div className="text-center overflow-hidden mb-3  pb-5"><span className='text-white shadow text-center bg-primary rounded-bottom-4 px-5 py-2 mb-3 fs-4'>Notices</span></div>

                    <div className='my-3 d-flex justify-content-end'>
                        <Link to={'/notice/create'} className="btn btn-primary rounded-pill px-5 ms-4 mb-3">Create</Link>
                    </div>
                </div>

                <div className='overflow-auto '>

                    {noticeRes.data.map(item =>
                        <div key={item.id}>
                            <div className='d-flex align-items-center'>
                                <div className='bg-secondary py-2 px-3 rounded-4 text-white justify-content-center me-3'>
                                    <h3>{new Date(item.date).getDate()<10 ? '0'+ new Date(item.date).getDate() : new Date(item.date).getDate()}</h3>
                                    <p className='fs-13 text-center'>{months[new Date(item.date).getMonth()]}</p>
                                </div>
                                <div>
                                    <p className='fw-bold'>{item.title} </p>
                                    <p className='fs-11' dangerouslySetInnerHTML={{__html:item.content }} ></p>
                                    <p className='fw-bold'><ViewNoticeModal id={item.id} /> | <Link to={'/notice/' + item.id} className='text-danger'>edit</Link> </p>

                                </div>
                            </div>
                            <hr className='text-light' />
                        </div>
                    )}


                </div>

            </div>

        </div>
    )
}

export default Notices
