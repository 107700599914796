import React, { useState } from 'react'
import Api from '../../constants/Api';
import Loading from './Loading'
import ApiErrorModal from '../modals/ApiErrorModal'
import Button from '../form/Button'
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { FaTrash } from "react-icons/fa6";
import { useCreateShortcutMutation, useDeleteShortcutMutation, useGetShortcutQuery, useUpdateShortcutMutation } from '../../services/shortcutSlice';
import Input from '../form/Input';
import Select from '../form/Select';

const ShortcutEditModal = ({ type, position }) => {

    const modalId = 'ShortcutEditModal'


    const [name, setName] = useState('')
    const [link, setLink] = useState('')
    const [tab, setTab] = useState('Same Tab')

    const shortcutRes = useGetShortcutQuery()
    const [createShortcut, createShortcutRes] = useCreateShortcutMutation()
    const [deleteShortcut, deleteShortcutRes] = useDeleteShortcutMutation()
    const [updateShortcut, updateShortcutRes] = useUpdateShortcutMutation()

    const addSlide = () => {
        let body = {
            name,
            link,
            tab,
        }

        createShortcut(body)
            .then((res) => {
                shortcutRes.refetch()
                setName('')
                setLink('')
                setTab('')
            })
    }

    const deleteSlide = (id) => {
        deleteShortcut(id).then((res) => {
            if (!res.error) {
                shortcutRes.refetch()
            }
        })
    }

    const updateOrder = (slide1, slide2) => {
        updateShortcut({ id: slide1.id, body: { order: slide2.order } })
            .then((res1) => {
                if (!res1.error) {
                    updateShortcut({ id: slide2.id, body: { order: slide1.order } })
                        .then((res2) => {
                            if (!res1.error) shortcutRes.refetch()
                        })
                }
            })
    }

    if (shortcutRes.isLoading) return <Loading />
    if (shortcutRes.isError) return <ApiErrorModal response={shortcutRes} />

    const images = shortcutRes.data;

    return (
        <>

            {
                position ?
                    <div className="btn btn-primary mt-auto float-end btn-sm mt-3 me-5 px-4 py-2 rounded-pill" data-bs-toggle="modal" data-bs-target={"#" + modalId}>Add Shortcuts</div>
                    :
                    <div className=' '>
                        <div className="btn btn-primary mt-auto float-end btn-sm mt-3 me-5 px-4 py-2 rounded-pill" data-bs-toggle="modal" data-bs-target={"#" + modalId}>Add Shortcuts</div>
                    </div>
            }



            <div className="modal fade" id={modalId} tabindex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            {/* <div></div> */}
                            <div className=" bg-white text-white  px-3 rounded-pill" style={{ width: 85, height: 10 }}></div>
                            <div className="modal-title px-4 py-2 bg-primary text-white rounded-bottom-4 text-center" id={modalId + "Label"} style={{ marginTop: -37 }}>Edit Shortcuts</div>
                            <div className="btn btn-danger px-3 rounded-pill" data-bs-dismiss="modal">Close</div>
                            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" >Close</button> */}
                        </div>
                        <div className="modal-body d-flex">
                            <div className="w-50 border-end">
                                <div className="container">
                                    <div className="row row-cols-1 g-2">
                                        {
                                            images.map((img, index) =>
                                                <div key={img.id} className="col">
                                                    <div className="alert d-flex align-items-center justify-content-between alert-primary p-2">
                                                        <button type='button' className='btn btn-sm rounded-circle btn-danger ms-3' onClick={() => deleteSlide(img.id)}><FaTrash /></button>

                                                        <h6 className='fw-bold'>{img.name}</h6>

                                                        <div className='d-flex flex-column align-items-start'>

                                                            {index !== 0 &&
                                                                <Button
                                                                    className='btn rounded-circle mb-2  btn-sm btn-primary ms-3'
                                                                    res={updateShortcutRes}
                                                                    onClick={() => updateOrder(images[index], images[index - 1])}
                                                                >
                                                                    {/* <IoMdArrowDropupCircle /> */}
                                                                    <TiArrowSortedUp />
                                                                </Button>
                                                            }
                                                            {index < images.length - 1 &&
                                                                <Button
                                                                    className='btn rounded-circle btn-sm btn-primary ms-3'
                                                                    res={updateShortcutRes}
                                                                    onClick={() => updateOrder(images[index], images[index + 1])}
                                                                >
                                                                    {/* <IoMdArrowDropdownCircle /> */}

                                                                    <TiArrowSortedDown />
                                                                </Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='w-50 ps-3'>

                                <Input type='text' labelName={'Shortcut Display Name'} name={'Shortcut Display Name'} containerClass={'my-3'} value={name} onChange={(e) => setName(e.target.value)} />
                                <Input type='text' labelName={'Link of Shortcut'} name={'Link of Shortcut'} containerClass={'my-3'} value={link} onChange={(e) => setLink(e.target.value)} />
                                {/* <Input type='text' labelName={'Open Link In'} name={'Open Link In'} containerClass={'my-3'} value={tab} onChange={(e) => setTab(e.target.value)} /> */}

                                {/* ({ options, name, lableName ,labelClass, containerClass, inputClass, setValue, selectedValue, ...attributes }) => { */}

                                <Select
                                    containerClass={'my-3'}
                                    inputClass={'ms-2'}
                                    name={'Open Link In'}
                                    options={['Same Tab', 'New Tab']}
                                    value={tab}
                                    onChange={(e) => setTab(e.target.value)}

                                />


                                <div className='text-end mt-3'>
                                    <Button
                                        className='btn btn-primary'
                                        res={createShortcutRes}
                                        loadingLabel={"Adding"}
                                        onClick={addSlide}
                                    >
                                        Add Shortcut
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShortcutEditModal