import React, { useState } from "react";
import EditAboutModal from "../../components/modals/EditAboutModal";
import { Link } from "react-router-dom";
import Input from "../../components/form/Input";
import Icons from "../../components/ui/Icons";
import icgsLogo from "../../assets/images/icgs_logo.png";
import pinWheel1img from "../../assets/images/pinWheel1.png";
import pinWheel2img from "../../assets/images/pinWheel2.png";
import pinWheel3img from "../../assets/images/pinWheel3.png";
import pinWheelImg from '../../assets/images/pinWheel.png'
import {
  useCreateAboutMutation,
  useGetAboutQuery,
  useUpdateAboutMutation,
} from "../../services/aboutSlice";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import Loading from "../../components/ui/Loading";
import SuccessModal from "../../components/modals/SuccessModal";

const IcgsPinwheel = () => {
  const containerClass = "col mb-4";
  const [selectedTab, setSelectedTab] = useState("Primary Details");
  const activeTabClassName = "border-start border-5 border-primary p-3 fw-bold";
  const inactiveTabClassName = "p-3 fw-bold";

  const aboutRes = useGetAboutQuery();
  const [createAbout, createAboutRes] = useCreateAboutMutation();
  const [updateAbout, updateAboutRes] = useUpdateAboutMutation();

  if (aboutRes.isLoading) return <Loading />;
  if (aboutRes.isError) return <ApiErrorModal />;

  const VidhyaFilteredData = aboutRes.data.filter(
    (item) => item.type === "Vidhya"
  );
  const sadvidhyaFilteredData = aboutRes.data.filter(
    (item) => item.type === "Sadvidhya"
  );
  const brahmvidhyaFilteredData = aboutRes.data.filter(
    (item) => item.type === "Brahmvidhya"
  );
  const charitramFilteredData = aboutRes.data.filter(
    (item) => item.type === "Charitram"
  );

  const about = aboutRes.data.find((item) => item.type === "Pinwheel");

  const aboutData = aboutRes.data;


  return (
    <>
      <div className="p-4 container-fluid h-100 hide-scroll">
        <div className="row shadow rounded-5 h-100" style={{ backgroundColor: "#EFFFFE", border: "10px solid #f3f3f2" }}>
          <div className="col-md-5">
            <div
              className=" w-100 d-flex flex-column rounded-5 align-items-center shadow overflow-hidden"
              style={{
                zIndex: 50,
                height: "100%",
                background: {pinWheelImg},
                border: "4px solid #86FFFA",
                backgroundSize: "cover",
                backgroundPosition: "center bottom",
                justifyContent: "flex-end",
              }}
            >
              <div
                className="d-block w-100 text-center p-3 py-4"
                style={{
                  background: "#F0FFFE",
                  border: "4px solid #86FFFA",
                  borderRadius: "32px",
                }}
              >
                <h2 style={{ fontWeight: 900, color: "#00A39D" }}>
                  The ICGS Pinwheel
                </h2>
              </div>
            </div>
          </div>

          <div className="col-md-7">
            <div className="d-flex flex-column align-items-center justify-content-center py-3  h-100">
              <div className="container d-flex gap-3 h-50  pb-3">
                <div className="row g-3 h-100">
                  <div className="col-md-6 ">
                    <div
                      className="card w-100 mb-3 shadow rounded-top-5 rounded-bottom-5 h-100"
                      style={{
                        borderBottomRightRadius: "20px !important",
                        borderBottomLeftRadius: "20px !important",
                      }}
                    >
                      <div
                        className="text-white fs-5 fw-bold p-2 rounded-pill d-flex justify-content-between align-items-center ps-3"
                        style={{ backgroundColor: "rgb(0, 101, 173)" }}
                      >
                        <p className="w-100 text-center">Vidhya</p>
                        <a
                          className="btn btn-outline-dark border-0 p-0 rounded-circle text-hover-primary"
                          href="/icgsPinwheel/vidhya"
                        >
                          {Icons.arrow()}

                        </a>
                      </div>
                      <div
                        className="m-4 overflow-y-scroll"
                        style={{ maxHeight: "230px" }}
                      >
                        <p className="fs-5">
                          Vidhya embodies modern education with three integral sections: Adhyayan for academic learning, Arogya for health education, and Kaushal for skill development, culminating in a comprehensive guide for contemporary education.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div
                      className="card w-100 mb-3 shadow rounded-top-5 rounded-bottom-5 h-100"
                      style={{
                        borderBottomRightRadius: "20px !important",
                        borderBottomLeftRadius: "20px !important",
                      }}
                    >
                      <div
                        className="text-white fs-5 fw-bold p-2 rounded-pill d-flex justify-content-between align-items-center ps-3"
                        style={{ backgroundColor: "rgb(229, 173, 0)" }}
                      >
                        <p className="w-100 text-center">Sadvidya</p>
                        <a
                          className="btn btn-outline-dark border-0 p-0 rounded-circle text-hover-primary"
                          href="/icgsPinwheel/sadvidhya"
                        >
                          {Icons.arrow()}

                        </a>
                      </div>
                      <div
                        className="m-4 overflow-y-scroll"
                        style={{ maxHeight: "230px" }}
                      >
                        <p className="fs-5">
                          Sadvidhya, denoting Social education, comprises three essential facets: Sanskar for etiquette, Sanskriti for tradition, and Vyaktitva Vikas for personality development, synergizing into a holistic guide for fostering social beings.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container d-flex gap-3 h-50 ">
                <div className="row h-100">
                  <div className="col-md-6">
                    <div
                      className="card w-100 mb-3 shadow rounded-top-5 rounded-bottom-5 h-100"
                      style={{
                        borderBottomRightRadius: "20px !important",
                        borderBottomLeftRadius: "20px !important",
                      }}
                    >
                      <div
                        className="text-white fs-5 fw-bold p-2 rounded-pill d-flex justify-content-between align-items-center ps-3"
                        style={{ backgroundColor: "rgb(229, 46, 183)" }}
                      >
                        <p className="w-100 text-center">Brahmvidya</p>
                        <a
                          className="btn btn-outline-dark border-0 p-0 rounded-circle text-hover-primary"
                          href="/icgsPinwheel/brahmvidhya"
                        >
                          {Icons.arrow()}
                        
                        </a>
                      </div>
                      <div
                        className="m-4 overflow-y-scroll"
                        style={{ maxHeight: "240px" }}
                      >
                        <p className="fs-5">
                          Brahmvidhya, signifying Spiritual education, encompasses three core elements: Satsang for spiritual companionship, Seva for societal service,and Samarpan for devoted community engagement, composing a unified guide for nurturing enlightened souls.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="card w-100 mb-3 shadow rounded-top-5 rounded-bottom-5 h-100"
                      style={{
                        borderBottomRightRadius: "20px !important",
                        borderBottomLeftRadius: "20px !important",
                      }}
                    >
                      <div
                        className="text-white fs-5 fw-bold p-2 rounded-pill d-flex justify-content-between align-items-center ps-3"
                        style={{ backgroundColor: "rgb(101, 155, 64)" }}
                      >
                        <p className="w-100 text-center">Charitram</p>
                        <a
                          className="btn btn-outline-dark border-0 p-0 rounded-circle text-hover-primary"
                          href="/icgsPinwheel/charitram"
                        >
                          {Icons.arrow()}
  
                        </a>
                      </div>
                      <div
                        className="m-4 overflow-y-scroll"
                        style={{ maxHeight: "240px" }}
                      >
                        <p className="fs-5">
                          Charitram, an auxiliary component of the pinwheel,embodies the practical manifestation from the lives of exemplary personalities, showcasing tangible results and real-world applications of the principles in Vidhya, Sadvidhya, and Brahmvidhya.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );


  return (
    <>
      <div class="p-5 container-fluid h-100 hide-scroll">
        <div class="row">
          <div className="col-md-5">
            <div
              className="bg-white w-100 d-flex flex-column rounded-5 align-items-center shadow overflow-hidden mb-4"
              style={{
                zIndex: 50,
                height: "84.5vh",
                background: "url(https://i.imgur.com/C2twT0C.png)",
                backgroundSize: "cover",
                backgroundPosition: "center bottom",
                justifyContent: "flex-end",
              }}
            >
              <div
                className="d-block w-100 text-center p-3 py-4"
                style={{
                  background: "#F0FFFE",
                  border: "4px solid #86FFFA",
                  borderRadius: "32px",
                }}
              >
                <h2 style={{ fontWeight: 900, color: "#00A39D" }}>
                  The ICGS Pinwheel
                </h2>
              </div>
            </div>
          </div>

          <div class="col-md-7">
            <div className="container d-flex gap-3">
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="card w-100 mb-3 shadow rounded-top-5 rounded-bottom-5"
                    style={{
                      borderBottomRightRadius: "20px !important",
                      borderBottomLeftRadius: "20px !important",
                    }}
                  >
                    <div
                      className="text-white fs-5 fw-bold p-2 rounded-pill d-flex justify-content-between align-items-center ps-3"
                      style={{ backgroundColor: "rgb(0, 101, 173)" }}
                    >
                      <p className="w-100 text-center">Vidhya</p>
                      <a
                        className="btn btn-outline-primary border-0 p-0 rounded-circle text-hover-white"
                        href="/icgsPinwheel/vidhya"
                      >
                        <img
                          src="https://i.imgur.com/4YVIECE.png"
                          style={{ maxWidth: "40px" }}
                          alt="Vidhya"
                        />
                      </a>
                    </div>
                    <div
                      className="m-4 overflow-y-scroll"
                      style={{ maxHeight: "200px" }}
                    >
                      <p>
                        Adhyayan for academic learning, Arogya for health
                        education, and Kaushal for skill development,
                        culminating in a comprehensive guide for contemporary
                        education.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="card w-100 mb-3 shadow rounded-top-5 rounded-bottom-5"
                    style={{
                      borderBottomRightRadius: "20px !important",
                      borderBottomLeftRadius: "20px !important",
                    }}
                  >
                    <div
                      className="text-white fs-5 fw-bold p-2 rounded-pill d-flex justify-content-between align-items-center ps-3"
                      style={{ backgroundColor: "rgb(229, 173, 0)" }}
                    >
                      <p className="w-100 text-center">Sadvidya</p>
                      <a
                        className="btn btn-outline-primary border-0 p-0 rounded-circle text-hover-white"
                        href="/icgsPinwheel/sadvidhya"
                      >
                        <img
                          src="https://i.imgur.com/4YVIECE.png"
                          style={{ maxWidth: "40px" }}
                          alt="Sadvidya"
                        />
                      </a>
                    </div>
                    <div
                      className="m-4 overflow-y-scroll"
                      style={{ maxHeight: "200px" }}
                    >
                      <p>
                        Sadvidhya, denoting Social education, comprises three
                        essential facets: Sanskar for etiquette, Sanskriti for
                        tradition, and Vyaktitva Vikas for personality
                        development, synergizing into a holistic guide for
                        fostering social beings.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container d-flex gap-3">
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="card w-100 mb-3 shadow rounded-top-5 rounded-bottom-5"
                    style={{
                      borderBottomRightRadius: "20px !important",
                      borderBottomLeftRadius: "20px !important",
                    }}
                  >
                    <div
                      className="text-white fs-5 fw-bold p-2 rounded-pill d-flex justify-content-between align-items-center ps-3"
                      style={{ backgroundColor: "rgb(229, 46, 183)" }}
                    >
                      <p className="w-100 text-center">Brahmvidya</p>
                      <a
                        className="btn btn-outline-primary border-0 p-0 rounded-circle text-hover-white"
                        href="/icgsPinwheel/brahmvidhya"
                      >
                        <img
                          src="https://i.imgur.com/4YVIECE.png"
                          style={{ maxWidth: "40px" }}
                          alt="Brahmvidya"
                        />
                      </a>
                    </div>
                    <div
                      className="m-4 overflow-y-scroll"
                      style={{ maxHeight: "200px" }}
                    >
                      <p>
                        Brahmvidhya, signifying Spiritual education, encompasses
                        three core elements: Satsang for spiritual
                        companionship, Seva for societal service, and Samarpan
                        for devoted community engagement, composing a unified
                        guide for nurturing enlightened souls.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="card w-100 mb-3 shadow rounded-top-5 rounded-bottom-5"
                    style={{
                      borderBottomRightRadius: "20px !important",
                      borderBottomLeftRadius: "20px !important",
                    }}
                  >
                    <div
                      className="text-white fs-5 fw-bold p-2 rounded-pill d-flex justify-content-between align-items-center ps-3"
                      style={{ backgroundColor: "rgb(101, 155, 64)" }}
                    >
                      <p className="w-100 text-center">Charitram</p>
                      <a
                        className="btn btn-outline-primary border-0 p-0 rounded-circle text-hover-white"
                        href="/icgsPinwheel/charitram"
                      >
                        <img
                          src="https://i.imgur.com/4YVIECE.png"
                          style={{ maxWidth: "40px" }}
                          alt="Charitram"
                        />
                      </a>
                    </div>
                    <div
                      className="m-4 overflow-y-scroll"
                      style={{ maxHeight: "200px" }}
                    >
                      <p>
                        Charitram, an auxiliary component of the pinwheel,
                        embodies the practical manifestation from the lives of
                        exemplary personalities, showcasing tangible results and
                        real-world applications of the educational principles
                        espoused in Vidhya, Sadvidhya, and Brahmvidhya.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  return (
    <div className="py-5 container d-flex h-100 justify-content-between align-items-center  ">
      {createAboutRes.isSuccess && (
        <SuccessModal
          label={"Created!"}
          message={"Content has been added."}
          to={{ link: "/icgsPinwheel", text: "Go to icgsPinwheel " }}
          closeTo={"/icgsPinwheel"}
        />
      )}
      {createAboutRes.isError && <ApiErrorModal response={createAboutRes} />}

      {updateAboutRes.isSuccess && (
        <SuccessModal
          label={"Updated!"}
          message={"Content has been updated."}
          to={{ link: "/icgsPinwheel", text: "Go to icgsPinwheel " }}
          closeTo={"/icgsPinwheel"}
        />
      )}
      {updateAboutRes.isError && <ApiErrorModal response={updateAboutRes} />}

      <div className="w-50 h-100 m-3 d-flex flex-column  justify-content-center ">
        <div
          className="bg-white w-100  d-flex flex-column rounded-5 align-items-center shadow overflow-hidden "
          style={{ zIndex: 50 }}
        >
          <img
            src={pinWheel2img}
            style={{ width: 300, marginTop: -20 }}
            alt=""
          />
          <img src={pinWheel1img} style={{ width: 300 }} alt="" />
          <img src={pinWheel3img} style={{ width: 300 }} alt="" />

          <Link
            to={"vidhya"}
            className="btn btn-info fs-3  fw-bold w-100 m-0 "
            style={{ color: "#86FFFA", border: "2px solid #86FFFA" }}
          >
            The ICGS Pin Wheel
          </Link>
        </div>
      </div>

      <div className="">
        <div className="container mt-5  d-flex  h-50 gap-3">
          <div className="card w-100 h-100 mb-3  rounded-top-5">
            <Link
              to={"vidhya"}
              className="btn text-white fs-5 fw-bold py-3 rounded-pill"
              style={{ backgroundColor: "#0065AD" }}
            >
              Vidhya
            </Link>
            <div
              className="overflow-hidden m-2 "
              style={{ height: 250 }}
              dangerouslySetInnerHTML={{
                __html: VidhyaFilteredData[0].content,
              }}
            />
          </div>
          <div className="card w-100 h-100 mb-3  rounded-top-5">
            <Link
              to={"sadvidhya"}
              className="btn text-white fs-5 fw-bold py-3 rounded-pill"
              style={{ backgroundColor: "#E5AD00" }}
            >
              Sadhvidya
            </Link>
            <div
              className="overflow-hidden m-2 "
              style={{ height: 250 }}
              dangerouslySetInnerHTML={{
                __html: sadvidhyaFilteredData[0].content,
              }}
            />
          </div>
        </div>

        <div className="container mb-4 d-flex  h-50 gap-2">
          <div className="card w-100 h-100 mb-3  rounded-top-5 ">
            <Link
              to={"brahmvidhya"}
              className="btn text-white fs-5 fw-bold py-3 rounded-pill"
              style={{ backgroundColor: "#E52EB7" }}
            >
              Brahmvidya
            </Link>

            <div
              className="overflow-hidden m-2 "
              style={{ height: 250 }}
              dangerouslySetInnerHTML={{
                __html: brahmvidhyaFilteredData[0].content,
              }}
            />
          </div>
          <div className="card w-100 h-100 mb-3  rounded-top-5">
            <Link
              to={"charitram"}
              className="btn text-white fs-5 fw-bold py-3 rounded-pill"
              style={{ backgroundColor: "#659B40" }}
            >
              Charitram
            </Link>

            <div
              className="overflow-hidden m-2 "
              style={{ height: 250 }}
              dangerouslySetInnerHTML={{
                __html: charitramFilteredData[0].content,
              }}
            />
          </div>
        </div>
      </div>

      {/* ============side info modal========= */}
      {/* <div className='bg-white rounded-start-5 w-25 shadow overflow-hidden h-100'>
                <div className='d-flex align-items-center justify-content-between p-3' style={{ backgroundColor: "#F5F5F5" }}>
                    <div className='text-danger fs-6 fw-bold me-3'>About ICGS Pin wheel</div>
                    <EditAboutModal id={1} type={"Pinwheel"} createAbout={createAbout} createAboutRes={createAboutRes} updateAbout={updateAbout} updateAboutRes={updateAboutRes} />
                </div>
                <div className='flex-grow-1 p-3'>
                    <div dangerouslySetInnerHTML={{ __html: about.content }} />
                </div>
            </div> */}
    </div>
  );
};

export default IcgsPinwheel;
