import React, { useState } from 'react'
import Icons from '../../components/ui/Icons'
import { Link } from 'react-router-dom'
import { useDeleteTutorialMutation, useGetTutorialsQuery, useUpdateTutorialByIdMutation } from '../../services/tutorialSlice'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import TutorialDeleteModal from '../../components/modals/deleteModals/TutorialDeleteModal'
import Input from '../../components/form/Input'
import Select from '../../components/form/Select'
import Sections from '../../constants/Sections'
import Rating from 'react-rating'

const Tutorials = () => {

    const activeTabClassName = "flex-grow-1 bg-light py-4 text-center border-bottom border-5 border-danger fw-bold";
    const inactiveTabClassName = "flex-grow-1 bg-primary py-4 text-center text-white";
    const inputClass = "bg-white mx-3";

    const response = useGetTutorialsQuery()
    const [updateTutorial, updateTutorialRes] = useUpdateTutorialByIdMutation();

    const [searchTerm, setSearchTerm] = useState('');
    const [selectTopic, setSelectTopic] = useState('');

    const [selectedSection, setSelectedSection] = useState("View All");
    const [selectedBook, setSelectedBook] = useState("View All");

    const [selectedTopicCheck, setSelectedTopicCheck] = useState(false);

    const handleUpdate = (e, id) => {
        const body = {
            publishInUserApp: e.target.checked
        }
        updateTutorial({ id, body })
            .then((res) => {
                if (!res.error) {
                    response.refetch()
                }
            })
    }





    const handleBookChange = (value) => {
        setSelectedBook(value);
    };

    const handleCheckOnChange = () => {
        setSelectedTopicCheck(!selectedTopicCheck)
    }

    const getTopicsOptions = (tutorials) => tutorials?.filter(i => i.section === selectedSection && i.books === selectedBook).map(item => item.topic)



    const topicsOptions = getTopicsOptions(response.data)

    const booksOptions = selectedSection
        ? Sections.SECTIONS_DATA.find((section) => section.name === selectedSection)?.books.map((book) => book.name) || []
        : [];


    if (response.isLoading) return <Loading />
    if (response.isError) return <ApiErrorModal response={response} />


    const calculateAverageRating = (ratings) => {
        if (!ratings || ratings.length === 0) return 0;
        const totalRating = ratings.reduce((acc, rating) => acc + rating?.rating, 0);
        return totalRating / ratings.length;
    };


    return (
        <div className=''>

            <div className='bg-white position-sticky sticky-top p-4 shadow '>
                <h2 className='fw-bold mb-2'>Manage <span className='text-danger'>Tutorials</span></h2>
                {/* <div className='d-flex justify-content-between align-items-center'> */}
                <div className='d-flex justify-content-between align-items-center'>

                    <div>
                        <div className='d-flex mx-2'>
                            <Select
                                name={'Value'}
                                inputClass={inputClass}
                                options={['View All', ...Sections.SECTIONS_DATA.map((item) => item.name)]}
                                value={selectedSection}
                                onChange={(e) => setSelectedSection(e.target.value)}
                                required
                            />


                            <Select
                                name={'Book'}
                                inputClass={inputClass}
                                // options={booksOptions}
                                options={booksOptions.length > 0 ? ['View All', ...booksOptions] : ['View All']} // Add 'View All' button
                                value={selectedBook}
                                onChange={(e) => handleBookChange(e.target.value)}
                                required
                            />

                            <Select
                                name={'Topics'}
                                options={topicsOptions.length > 0 ? ['View All', ...topicsOptions] : ['View All']} // Add 'View All' button
                                inputClass={inputClass}
                                value={selectTopic}
                                onChange={(e) => setSelectTopic(e.target.value === 'View All' ? '' : e.target.value)} // Handle 'View All' selection
                                required
                            />

                            {/* {
                            !selectedTopicCheck && topicsOptions.length > 0 &&
                            <Select name={'Topic'} options={topicsOptions} inputClass={inputClass}  value={selectTopic} onChange={(e) => setSelectTopic(e.target.value)} required />
                        }
                        {
                            (selectedTopicCheck || topicsOptions.length === 0) &&
                            <Input name={'Topic'} inputClass={inputClass} value={selectTopic} onChange={(e) => setSelectTopic(e.target.value)} />
                        } */}
                            <Input type={'text'} placeholder='Search tutorials' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>

                    </div>



                    <Link className="btn btn-danger text-white rounded-pill px-5 m-0 ms-4" to={'create'}>Create Tutorial</Link>
                </div>
                {/* </div> */}
            </div>



            <div className="px-5">


                {
                    response.isSuccess && response.data
                        // .filter((item) => {
                        //     const titleMatch = item.title.toLowerCase().includes(searchTerm.toLowerCase());

                        //     const topicMatch = !selectTopic || item.topic === selectTopic;


                        //     const sectionMatch = !selectedSection || item.section === selectedSection;

                        //     const bookMatch = !selectedBook || item.books === selectedBook;

                        //     // Return true only if both topic and section are empty or all filters match
                        //     return (selectTopic === '' && selectedSection === '') || (titleMatch && topicMatch && sectionMatch && bookMatch);
                        // })

                        // .filter((item) => {
                        //     const titleMatch = item.title.toLowerCase().includes(searchTerm.toLowerCase());
                        //     const topicMatch = !selectTopic || item.topic === selectTopic;
                        //     const sectionMatch = !selectedSection || item.section === selectedSection;
                        //     const bookMatch = !selectedBook || item.books === selectedBook;

                        //     // Return true only if both topic and section are empty or all filters match
                        //     return (selectTopic === '' && selectedSection === '' && selectedBook === '') || (titleMatch && topicMatch && sectionMatch && bookMatch);
                        // })

                        .filter((item) => {
                            const titleMatch = item.title.toLowerCase().includes(searchTerm.toLowerCase());
                            const topicMatch = !selectTopic || item.topic === selectTopic;
                            const sectionMatch = !selectedSection || item.section === selectedSection;
                            const bookMatch = !selectedBook || item.books === selectedBook;

                            // Return true if any filter is set to "View All" or all filters match
                            return (selectTopic === 'View All' || selectedSection === 'View All' || selectedBook === 'View All') ||
                                (titleMatch && topicMatch && sectionMatch && bookMatch);
                        })

                        ?.map(item => {

                            const averageRating = calculateAverageRating(item?.markAsReads?.map(mark => mark?.rating));

                            return (
                                <div className='d-flex bg-white align-items-center rounded-4 my-3  shadow' key={item.id} >

                                    <div className='p-4 border-end flex-grow-1' style={{ width: 200 }}>
                                        <h6 className='fw-bold'>{item.title}</h6>
                                        <p className='fs-13 text-muted'>{`${item.section} > ${item.books} > ${item.topic}`}</p>
                                    </div>

                                    <div className='p-4 border-end flex-grow-1' style={{ width: 200 }}>
                                        <h6 className='fw-bold'>{item.avgCompletionTime}</h6>
                                        <p className='fs-13 text-muted'>Avg. Completion Time</p>
                                    </div>

                                    <div className='p-4 border-end flex-grow-1' style={{ width: 200 }}>
                                        {/* <h6 className='fw-bold'>Last Updated</h6> */}
                                        <p className='fs-13 text-muted'>Ratings</p>
                                        <Rating
                                            initialRating={averageRating || 0}
                                            readonly
                                            fullSymbol={Icons.rating('fs-6 text-warning m-0', {})}
                                            emptySymbol={Icons.ratingOutline('fs-6 text-muted m-0', {})}
                                        />
                                    </div>

                                    <div className='p-4 border-end d-flex align-items-center'>
                                        {/* <div className="btn btn-success btn-sm px-3 rounded-pill text-white">Resolved</div> */}
                                        <div className="form-check form-switch">
                                            <input className="form-check-input fs-3" type="checkbox" role="switch" id="toggle" onChange={(e) => handleUpdate(e, item.id)} defaultChecked={item.publishInUserApp} />
                                        </div>
                                    </div>

                                    <div className='p-4 border-end flex-grow-1' style={{ width: 200 }}>
                                        <h6 className='fw-bold'>{item.suggestions.length} Suggestions</h6>
                                        {/* <p className='fs-13 text-danger fw-bold'>View Suggestions</p> */}
                                    </div>

                                    <div className='p-4 border-end flex-grow-1' style={{ width: 200 }}>
                                        {/* <p className='fs-13 text-danger fw-bold' onClick={() => handleDelete(item.id)}>Delete</p> */}
                                        <TutorialDeleteModal id={item.id} refetch={response.refetch} />
                                    </div>

                                    <div className='d-flex p-4'>
                                        <Link style={{ width: 40, height: 40 }} className='btn btn-outline-success p-0 rounded-circle text-hover-white' to={'' + item.id}>{Icons.arrow('h-100 w-100')}</Link>

                                    </div>

                                </div>
                            )
                        }


                        )
                }
            </div>

        </div>
    )
}

export default Tutorials
