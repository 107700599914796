import React from 'react'
import Input from '../../components/form/Input'
import Select from '../../components/form/Select'
import { useCreateDownloadableMutation } from '../../services/downloadablesSlice'
import SuccessModal from '../../components/modals/SuccessModal'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import Button from '../../components/form/Button'

const CreateDownloadables = ({ data, refetch }) => {

    const type = ["document", "video", "audio", "picture"]
    const containerClass = "mb-4"

    const [createDownloadable, createDownloadableRes] = useCreateDownloadableMutation();

    const onSubmit = (event) => {
        event.preventDefault();
        const form = event.target

        if (form.checkValidity()) {
            const body = {
                name: form['File Name'].value,
                type: form['File Type'].value,
                link: form['File Link'].value
            }

            createDownloadable(body)
                .then((res) => {
                    if (!res.error) {
                        form.reset()
                        form.classList.remove('was-validated')
                        refetch()
                    }
                })

        }
        else form.classList.add('was-validated')
    }

    return (
        <div className='px-4 text-center bg-white rounded-start-5 shadow h-100'>

            {createDownloadableRes.isSuccess && <SuccessModal label={"Added!"} message={"Downloadable has been added."} closeTo={"/downloadables"} />}
            {createDownloadableRes.isError && <ApiErrorModal response={createDownloadableRes} />}

            <div className='bg-primary text-white mb-4 mx-auto rounded-bottom-4 p-2 w-50'>Add Downloadable</div>

            <form className="pt-5" onSubmit={onSubmit} noValidate>
                <Input name={"File Name"} inputClass={'bg-white'} labelName={'File Name'} containerClass={containerClass} required />
                <Select name={"File Type"} options={type} inputClass={'bg-white ms-2'} containerClass={containerClass} />
                <Input name={"File Link"} inputClass={'bg-white'} labelName={'File Link'} containerClass={containerClass} required />
                <Button
                    className="btn bg-danger text-white rounded-pill px-5"
                    res={createDownloadableRes}
                    loadingLabel={"Adding..."}
                >
                    Add New Downloadable
                </Button>
            </form>

            <hr />

            <div className='px-3 d-flex flex-column align-items-center'>
                <div className='bg-white shadow mb-4 p-4 fs-7 fw-bold border-5 border-bottom border-success'>Downloadable Insights</div>

               

                <div className='d-flex mb-4 w-100'>
                    <div className='bg-white shadow me-3 p-3 fs-13 border-5 border-bottom border-danger w-50 '>
                        <div className='fw-bold fs-5'>{data.filter(item => item.type === "document").length}</div>
                        <div className='fs-6 fw-bold'>Downloadable Documents</div>
                    </div>
                    <div className='bg-white shadow p-3 fs-13  border-5 border-bottom border-warning w-50'>
                        <div className='fw-bold fs-5'>{data.filter(item => item.type === "video").length}</div>
                        <div className='fs-6 fw-bold'>Downloadable Videos</div>
                    </div>
                </div>

                <div className='d-flex mb-4 w-100'>
                    <div className='bg-white shadow me-3 p-3 fs-13 border-5 border-bottom border-primary w-50'>
                        <div className='fw-bold fs-5'>{data.filter(item => item.type === "audio").length}</div>
                        <div className='fs-6 fw-bold'>Downloadable Audios</div>
                    </div>
                    <div className='bg-white shadow p-3 fs-13  border-5 border-bottom border-secondary w-50 '>
                        <div className='fw-bold fs-5'>{data.filter(item => item.type === "picture").length}</div>
                        <div className='fs-6 fw-bold'>Downloadable Images</div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default CreateDownloadables