import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Icons from '../ui/Icons'
import Button from '../form/Button'
import { getApiErrorMessage } from '../../utility/errorMessages'
import Input from '../form/Input'
import Loading from '../ui/Loading'
import { useGetSessionByIdQuery, useUpdateSessionMutation } from '../../services/sessionSlice'

const EditSessionNameModal = ({ id, refetch }) => {

    const modalId = "editSessionNameModal" + id

    const [updateSession, updateSessionRes] = useUpdateSessionMutation()
    const sessionRes = useGetSessionByIdQuery(id)

    const onUpdate = (e) => {
        e.preventDefault()

        const body = {
            name: e.target['Name'].value
        }

        updateSession({ id, body })
            .then((res) => {
                if (!res.error) {
                    document.getElementById(modalId + 'CloseBtn').click()
                    refetch()
                }
            })
    }

    if (sessionRes.isLoading) return <Loading />
    if (sessionRes.isError) return <div>Something went wrong...</div>

    return (
        <>
            <button type="button" className="btn" data-bs-toggle="modal" data-bs-target={"#" + modalId}>
                {Icons.edit()}
            </button>

            <div className="modal fade" id={modalId} tabindex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <form className="" onSubmit={onUpdate}>
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id={modalId + "Label"}>Edit Session Name</h1>
                                <button id={modalId + "CloseBtn"} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <Input name={'Name'} defaultValue={sessionRes.data.name} />
                            </div>
                            <div className="modal-footer">
                                {updateSessionRes.isError &&
                                    <div className='me-auto fw-bold text-danger'>
                                        {getApiErrorMessage(updateSessionRes).label}: {getApiErrorMessage(updateSessionRes).message}
                                    </div>
                                }
                                <button type="button" className="btn btn-success text-white" data-bs-dismiss="modal">Cancel</button>
                                <Button
                                    className="btn btn-danger text-white"
                                    type="submit"
                                    res={updateSessionRes}
                                    loadingLabel={"Updating"}
                                >
                                    Update
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditSessionNameModal